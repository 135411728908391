import React from 'react'
import Suggestions from 'components/suggestions-list'
import intl from 'react-intl-universal'
import { Title, Description } from '../styled'
import { Container, Content, IconTitle } from './styled'
import IconPackage from 'components/icons/icn-package.icon'
import PropTypes from 'prop-types'

const PendingVolum = ({
    data,
    calcTimelineMaxHeight,
    showCancelConfirm,
    showFormOrder,
    cancelarSaldoPedidoVolume
}) => (
        <Container>
            <IconTitle>
                <IconPackage width='30px' />
                <Title>{intl.get('stocks.pendentVolumn')}</Title>
            </IconTitle>
            {data.estoqueDetalhe.ListaPedidosVolume.length ?
                data.estoqueDetalhe.ListaPedidosVolume.map((x, indexOrigem) => (
                    <React.Fragment>
                        <Description>{intl.get('grid-vision.modal.source')}: {x.Origem}</Description>
                        <Content>
                            <Suggestions
                                isOrderBulk
                                data={x.PedidosVolume}
                                itemHighlighted={data.tipo == 'V' ? data.idItemDestacado : ''}
                                itemExpanded={data.itemExpanded}
                                materialAtivo={data.estoqueDetalhe.MaterialAtivo}
                                itemsExpanded={data.itemsExpanded}
                                expandItem={calcTimelineMaxHeight}
                                cancelarSaldoPedidoVolume={(z) => cancelarSaldoPedidoVolume(z, indexOrigem)}
                                showCancelConfirm={showCancelConfirm}
                                showFormOrder={showFormOrder}
                            />
                        </Content>
                    </React.Fragment>
                ))
                :
                <div style={{ padding: '20px' }}>
                    {intl.get('grid-vision.modal.noOrdersVolum')}
                </div>
            }
        </Container>
    )

PendingVolum.propTypes = {
  data: PropTypes.shape({
    estoqueDetalhe: PropTypes.shape({
      ListaPedidosVolume: PropTypes.array,
      MaterialAtivo: PropTypes.any
    }),
    tipo: PropTypes.string,
    idItemDestacado: PropTypes.string,
    itemExpanded: PropTypes.any,
    itemsExpanded: PropTypes.array.isRequired
  }),
  calcTimelineMaxHeight: PropTypes.any,
  showCancelConfirm: PropTypes.any,
  showFormOrder: PropTypes.any,
  cancelarSaldoPedidoVolume: PropTypes.func,

}

export default PendingVolum;
