
import intl from 'react-intl-universal'
/**
 * Retorna o nome do dia em PT-BR.
 * @param {*} name Nome do dia
 */
export const dateNameToPtBr = (name) => {
  let day = '';

  switch (name) {
    case 'Mon':
    case 'seg':
    case 'lun.':
      day = intl.get('planningDaily.days.seg')
      break;
    case 'Tue':
    case 'ter':
    case 'mar.':
      day = intl.get('planningDaily.days.ter')
      break;
    case 'Wed':
    case 'qua':
    case 'mié.':
      day = intl.get('planningDaily.days.Qua')
      break;
    case 'Thu':
    case 'qui':
    case 'jue.':
      day = intl.get('planningDaily.days.qui')
      break;
    case 'Fri':
    case 'sex':
    case 'vie.':
      day = intl.get('planningDaily.days.sex')
      break;
    case 'Sat':
    case 'sáb':
    case 'sáb.':
      day = intl.get('planningDaily.days.sab')
      break;
    case 'Sun':
    case 'dom':
    case 'dom.':
      day = intl.get('planningDaily.days.dom')
      break;
    default:
      day = intl.get('weekdays.today')
      break;
  }
  return day;
}

/**
 * Formata e retorna a data baseado no separador.
 * @param {*} date Data inicial para conversão
 * @param {*} symbolFormat Separador do formato da data inicial ex "-" ou "/"
 */
export const parseInitialDate = (date, symbolFormat) => {
  const splitter = symbolFormat === '-' ? '-' : '/'

  return new Date(
    date.split(splitter)[0],
    date.split(splitter)[1] - 1,
    date.split(splitter)[2]
  )
}

/**
 * Retorna o dia de hoje.
 */
export const returnToday = () => {
  return new Date().setHours(0,0,0,0);
}

/**
 * Retornar se a data DE e maior que a data ATÉ.
 * @param {*} dataDe data inicial
 * @param {*} dataAte data final
 */
export const validBetweenDates = (dataDe, dataAte) => {
  return dataDe > dataAte;
}

