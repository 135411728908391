import PropTypes from "prop-types"
import { useEffect, useState } from "react";
import Dialog from 'material-ui/Dialog/Dialog';
import { DialogHeader, DialogContent, Badge, Divider, BottomDivider } from "./styles";
import { Button } from 'components/uikit-adapter'
import intl from 'react-intl-universal';
import './styles.css'
import capitalEmpregadoOKIcone from 'images/capital-empregado-ok.svg';
import capitalEmpregadoOportunidadeIcone from 'images/capital-empregado-oportunidade.svg';
import capitalEmpregadoRiscoIcone from 'images/capital-empregado-risco.svg'
import { formatNumber, formatValueByCountry } from "utils/format";
import { getCurrencySymbolByCountry } from "utils/locale-methods";

const CapitalEmployedDialog = ({ data, visible, onClose }) => {
  const [color, setColor] = useState(null);
  const [icone, setIcone] = useState(null);

  const formatNumeroMaterial = (value) => {
    if (value) {
      const slice = value.slice(10);
      return slice;
    }
  }

  const formatIcon = (value) => {
    switch (value) {
      case (1):
        setColor('#BF0909');
        setIcone(capitalEmpregadoRiscoIcone);
        break;
      case (2):
        setColor('#00980A');
        setIcone(capitalEmpregadoOportunidadeIcone);
        break;
      case (3):
        setColor('#383A3B');
        setIcone(capitalEmpregadoOKIcone);
        break;
    }
  }

  useEffect(() => {
    formatIcon(data.StatusCapitalEmpregado)
  }, [data])

  return (
    <Dialog
      contentStyle={{ width: "900px" }}
      open={visible}
      onRequestClose={onClose}
      autoScrollBodyContent
    >
      <DialogHeader>
        <div className="content">
          <div className="title">{data.FamiliaRotulada}</div>
          <div>
            <Badge>
              <span className="text">
                {data.StatusEstoqueDescricao}
              </span>
            </Badge>
            <Badge>
              <span className="text numeroMaterial">
                {formatNumeroMaterial(data.NumeroMaterial)}
              </span>
            </Badge>
          </div>
        </div>
      </DialogHeader>

      <Divider></Divider>

      <DialogContent>
        <div className="column-flow">
          <span className="column-header">{intl.get('commons.source')}</span>
          <span className="column-text">{data.Centro} - {data.UnidadeNegocio}</span>
        </div>
        <div className="column-row m-r">
          <div className="column-flow">
            <span className="column-header">{intl.get('commons.family')}</span>
            <span className="column-text">{data.Familia}</span>
          </div>
          <div className="column-flow m-r">
            <span className="column-header">{intl.get('commons.labeledFamily')}</span>
            <span className="column-text">{data.FamiliaRotulada}</span>
          </div>
          <div className="column-flow m-r">
            <span className="column-header">{intl.get('commons.activeMaterial')}</span>
            <span className="column-text">{data.MaterialDescricao}</span>
          </div>
        </div>
        <div className="column-row">
          <div className="column-flow m-r">
            <span className="column-header">{intl.get('management.balancePE')}</span>
            <span className="column-text">
              {formatNumber(data.SaldoComPoliticaEstoque)} {data.SaldoComPoliticaEstoqueUM}
            </span>
          </div>
          <div className="column-flow m-r">
            <span className="column-header">{intl.get('management.balanceFinal')}</span>
            <span className="column-text">{formatNumber(data.SaldoFinal)} {data.SaldoFinalUM}</span>
          </div>
          <div className="column-flow m-r">
            <span className="column-header">{intl.get('management.capitalEmplyedBalancePER')}</span>
            <div className="column-text" style={{ color: color, display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '10px' }}>
                <img alt="modal-icon" className="icone-modal" src={icone} style={{ color: color }}></img>
              </div>
              <div>
                {formatValueByCountry(data.ValorRiscosPassivosSaldoPoliticaEstoque, data.UnidadeNegocioPais)}
              </div>
            </div>
          </div>
        </div>

        <hr></hr>

        <div className="horizontal" style={{ justifyContent: 'space-between' }}>
          <div>
            <span className="main-header">{intl.get("management.releasedStock")}</span>
            <div className="horizontal">
              <div className="column-flow">
                <span className="column-header">{intl.get("management.releasedStock")}</span>
                <span className="column-text">{formatNumber(data.EstoqueLiberado)} {data.EstoqueLiberadoUM}</span>
              </div>
              <div className='separator'></div>
              <div className="column-flow">
                <span className="column-header">{intl.get("management.currencyValue")} {getCurrencySymbolByCountry(data.UnidadeNegocioPais)}</span>
                <span className="column-text">{formatValueByCountry(data.ValorEstoqueLiberado, data.UnidadeNegocioPais)}</span>
              </div>
            </div>
          </div>
          <div>
            <span className="main-header">{intl.get("management.stockBlocked")}</span>
            <div className="horizontal">
              <div className="column-flow">
                <span className="column-header">{intl.get("management.stockBlocked")}</span>
                <span className="column-text">{formatNumber(data.EstoqueBloqueado)} {data.EstoqueBloqueadoUM}</span>
              </div>
              <div className='separator'></div>
              <div className="column-flow">
                <span className="column-header">{intl.get("management.currencyValue")} {getCurrencySymbolByCountry(data.UnidadeNegocioPais)}</span>
                <span className="column-text valorEstoqueBloqueado">{formatValueByCountry(data.ValorEstoqueBloqueado, data.UnidadeNegocioPais)}</span>
              </div>
            </div>
          </div>
          <div>
            <span className="main-header">{intl.get("management.totalStock")}</span>
            <div className="horizontal">
              <div className="column-flow">
                <span className="column-header">{intl.get("management.totalStock")}</span>
                <span className="column-text">{formatNumber(data.EstoqueTotal)} {data.EstoqueTotalUM}</span>
              </div>
              <div className='separator'></div>
              <div className="column-flow">
                <span className="column-header">{intl.get("management.currencyValue")} {getCurrencySymbolByCountry(data.UnidadeNegocioPais)}</span>
                <span className="column-text">{formatValueByCountry(data.ValorEstoqueTotal, data.UnidadeNegocioPais)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="horizontal">
          <div>
            <span className="main-header">{intl.get("management.stockPolicy")}</span>
            <div className="horizontal">
              <div className="column-flow">
                <span className="column-header">{intl.get("management.min")}</span>
                <span className="column-text">{formatNumber(data.PoliticaEstoqueMinima)} {data.EstoqueLiberadoUM}</span>
              </div>
              <div className='separator'></div>
              <div className="column-flow">
                <span className="column-header">{intl.get("management.max")}</span>
                <span className="column-text">{formatNumber(data.PoliticaEstoqueMaxima)} {data.EstoqueLiberadoUM}</span>
              </div>
              <div className='separator'></div>
              <div className="column-flow">
                <span className="column-header">{intl.get("management.consumption")}</span>
                <span className="column-text">{formatNumber(data.ConsumoTotal)} {data.ConsumoTotalUM}</span>
              </div>
            </div>
          </div>
          <div className="m-l">
            <span className="main-header">{intl.get("management.risks")}</span>
            <div className="horizontal">
              <div className="column-flow">
                <span className="column-header">{intl.get("commons.amount")}</span>
                <span className="column-text">{formatNumber(data.RiscosPassivos)} {data.RiscosPassivosUM}</span>
              </div>
              <div className='separator'></div>
              <div className="column-flow">
                <span className="column-header">{intl.get("management.currencyValue")} {getCurrencySymbolByCountry(data.UnidadeNegocioPais)}</span>
                <span className="column-text">{formatValueByCountry(data.ValorRiscosPassivos, data.UnidadeNegocioPais)}</span>
              </div>
            </div>
          </div>
        </div>
        <BottomDivider></BottomDivider>
        <Button
          width="50px"
          type="primary"
          value={intl.get('geral.buttonsDefault.close')}
          className="button"
          onClick={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

CapitalEmployedDialog.propTypes = {
  data: PropTypes.shape({
    Centro: PropTypes.any,
    ConsumoTotal: PropTypes.any,
    ConsumoTotalUM: PropTypes.any,
    EstoqueBloqueado: PropTypes.any,
    EstoqueBloqueadoUM: PropTypes.any,
    EstoqueLiberado: PropTypes.any,
    EstoqueLiberadoUM: PropTypes.any,
    EstoqueTotal: PropTypes.any,
    EstoqueTotalUM: PropTypes.any,
    Familia: PropTypes.any,
    FamiliaRotulada: PropTypes.any,
    MaterialDescricao: PropTypes.any,
    NumeroMaterial: PropTypes.any,
    PoliticaEstoqueMaxima: PropTypes.any,
    PoliticaEstoqueMinima: PropTypes.any,
    RiscosPassivos: PropTypes.any,
    RiscosPassivosUM: PropTypes.any,
    SaldoComPoliticaEstoque: PropTypes.any,
    SaldoComPoliticaEstoqueUM: PropTypes.any,
    SaldoFinal: PropTypes.any,
    SaldoFinalUM: PropTypes.any,
    StatusCapitalEmpregado: PropTypes.any,
    StatusEstoqueDescricao: PropTypes.any,
    UnidadeNegocio: PropTypes.any,
    UnidadeNegocioPais: PropTypes.any,
    ValorEstoqueBloqueado: PropTypes.any,
    ValorEstoqueLiberado: PropTypes.any,
    ValorEstoqueTotal: PropTypes.any,
    ValorRiscosPassivos: PropTypes.any,
    ValorRiscosPassivosSaldoPoliticaEstoque: PropTypes.any
  }).isRequired,
  onClose: PropTypes.any.isRequired,
  visible: PropTypes.any.isRequired
}

export default CapitalEmployedDialog;
