
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './status-stock.css'
import { TextSpan } from 'components/text-label'

class StatusStock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      colorStatus: ''
    }

    this.updateStockPriority = this.updateStockPriority.bind(this)
  }

  componentDidMount() {
    const { priority } = this.props
    this.updateStockPriority(priority)
  }

  updateStockPriority(status) {
    let colorStatus = ''

    switch (status) {
      case 0:
        colorStatus = '#2da78f'
        break
      case 1:
        colorStatus = '#393939'
        break
      case 2:
        colorStatus = '#ff5e53'
        break
      case 16: //numero maior para o estoque bloqueado
        colorStatus = '#cc9900'
        break
      default:
        colorStatus = '#393939'
    }

    this.setState({ colorStatus })
  }

  render() {
    let { colorStatus } = this.state
    let { label } = this.props

    return (
      <TextSpan
        className='status-stock'
        style={{ color: colorStatus }}
      >
        {label}
      </TextSpan>
    )
  }
}

StatusStock.propTypes = {
  priority: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
}

export default StatusStock
