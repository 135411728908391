import { IBusinessUnit } from "types/business-unit";
import { GetDoorsByBusinessUnitIdCommandResponse} from "types/business-unit/get-doors-by-business-unit-id-command-response"
import { IProductionLines } from "types/business-unit/production-lines";
import Fetch from "utils/fetch"

export const getDoorsByBusinessUnitId = (id: number): Promise<GetDoorsByBusinessUnitIdCommandResponse[]> => {
  return Fetch.get(`business-units/${id}/doors`).then(({ data }) => data)
}

export const searchBusinessUnitById = (id: number): Promise<IBusinessUnit> => Fetch.get(`/unidadeNegocio/${id}`).then(({ data }) => data);

export const getProductionLineByCenter = (centro: string): Promise<IProductionLines[]> => Fetch.get(`/unidadeNegocio/obterLinhaProducaoPorCentro/${centro}`).then(({ data }) => data);
