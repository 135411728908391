import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Loading from 'components/center-loading'
import SuggestionsListDetail from 'components/manual-suggestion-order-form'
import Dialog from 'material-ui/Dialog'
import SnackBar from 'material-ui/Snackbar'
import intl from 'react-intl-universal'

import './manual-order-form.css'
import { parseFloatByLocale } from 'locales/utils'
import { generateQueryParams } from 'utils/custom-functions'
import { normalizeMaterials } from 'pages/grid-vision/components/modal/utils'

const PEDIDO_MANUAL_DEFAULT = {
  IdUnidadeNegocio: 0,
  Unidades: [],
  IdFamiliaRotulada: 0,
  FamiliasRotuladas: [],
  IdMaterial: 0,
  Materiais: [],
  IdFornecedor: 0,
  TransitTime: 0,
  Coleta: '',
  DataEntregaDe: '',
  QuantidadeLote: 1,
  Quantidade: 1,
  Total: 1,
  OrganizacaoCompras: '',
  GrupoCompradores: '',
  CodigoImposto: '',
  Observacao: '',
  ResponsavelEntrega: 1,
  RequirementCode: ''
}

class ManualOrderForm extends Component {
  constructor() {
    super();

    this.state = {
      pedidoManual: PEDIDO_MANUAL_DEFAULT,
      isFetching: false,
      showFeedback: false,
      messageFeedBack: ""
    };
  }

  componentDidMount() {
    this.loadUnidadesNegocio();
  }

  loadUnidadesNegocio = () => {
    this.startFetching()
    Fetch.get(`/unidadeNegocio/unidadesCadastradasNoGeral/`)
      .then(response => this.changeValueSugestao('Unidades', response.data))
      .finally(() => this.stopFetching())

    this.setState({
      pedidoManual: PEDIDO_MANUAL_DEFAULT
    })
  }

  loadFamiliasRotuladas = (businessUnitId) => {
    this.startFetching()
    Fetch.get(`/labeled-families:by-business-unit?${generateQueryParams({ businessUnitId: businessUnitId })}`)
      .then(response => this.changeValueSugestao('FamiliasRotuladas', response.data?.map(x => ({ Id: x.Id, Descricao: x.Description }))))
      .finally(() => this.stopFetching())

    this.setState(prevState => ({
      pedidoManual: {
        ...PEDIDO_MANUAL_DEFAULT,
        IdUnidadeNegocio: prevState.pedidoManual.IdUnidadeNegocio,
        Unidades: prevState.pedidoManual.Unidades
      }
    }))
  }

  loadMateriais = (idFamiliaRotulada) => {
    const { IdUnidadeNegocio } = this.state.pedidoManual
    this.startFetching()
    const _filters = {
      businessUnitId: IdUnidadeNegocio,
      labeledFamilyId: idFamiliaRotulada
    }
    Fetch.get(`/labeled-family-center-materials:by-business-unit-and-labeled-family?${generateQueryParams(_filters)}`)
      .then(response => this.changeValueSugestao('Materiais', normalizeMaterials(response.data)))
      .finally(() => this.stopFetching())
  };

  salvarSugestao = (model) => {
    this.startFetching()

    const Pedido = {
      IdUnidadeNegocio: model.IdUnidadeNegocio,
      IdMaterial: model.IdMaterial,
      IdFornecedor: model.IdFornecedor,
      DataColeta: model.Coleta,
      DataEntrega: model.DataEntregaDe,
      Quantidade: model.Total,
      OrganizacaoCompras: model.OrganizacaoCompras,
      GrupoCompradores: model.GrupoCompradores,
      CodigoImposto: model.CodigoImposto,
      Observacao: model.Observacao,
      ResponsavelEntrega: model.ResponsavelEntrega,
      PrioridadeXadrez: model.PrioridadeXadrez,
      RequirementCode: model.RequirementCode
    }

    Fetch.post(`/pedido/criarPedidoContingencia`, Pedido)
      .then(() => this.openFeedback("Pedido criado e enviado ao SAP com sucesso"))
      .catch((e) => this.openFeedback(e.response.data.Message))
      .finally(() => {
        this.changeforDefaultForm()
        this.stopFetching()
      });
  }

  changeforDefaultForm = () => {
    this.setState(prevState => ({
      pedidoManual: {
        ...PEDIDO_MANUAL_DEFAULT,
        Unidades: prevState.pedidoManual.Unidades
      }
    }))
  }

  changeValueSugestao = (prop, value) => {
    this.setState(prevState => ({
      pedidoManual:{
        ...prevState.pedidoManual,
        [prop]: value
      }
    }), () => this.changeValueSugestaoSpecific(prop, value))
  }

  changeValueDeadline = (coleta, entrega) => {
    this.setState(prevState => ({
      pedidoManual:{
        ...prevState.pedidoManual,
        Coleta: coleta,
        DataEntregaDe: entrega
      }
    }))
  }

  changeValueSugestaoSpecific = (prop, value) =>
  {
    switch (prop) {
      case 'IdUnidadeNegocio':
        this.loadFamiliasRotuladas(value)
        break

      case 'IdFamiliaRotulada':
        this.loadMateriais(value)
        break

      case 'IdFornecedor':
        this.changeInfoSupply(value)
        break

      case 'Quantidade':
        this.setState(prevState => ({
          pedidoManual: {
            ...prevState.pedidoManual,
            Total: this.calcTotalOrder(parseFloatByLocale(value), prevState.pedidoManual.QuantidadeLote)
          }
        }))
        break
    }
  }

  changeInfoSupply = (idFornecedor) => {
    const { pedidoManual } = this.state
    const material = this.state.pedidoManual.Materiais.find(m => m.Id === pedidoManual.IdMaterial)
    const fornecedor = material?.Fornecedores.find(f => f.Id === idFornecedor)
    this.setState(prevState => ({
      pedidoManual:{
        ...prevState.pedidoManual,
        TransitTime: fornecedor.TransitTime,
        QuantidadeLote: fornecedor.Arredondamento,
        Total: this.calcTotalOrder(pedidoManual.Quantidade, fornecedor.Arredondamento),
        PrioridadeXadrez: fornecedor.PrioridadeXadrez
      }
    }))
  };

  calcTotalOrder = (quantidadeLote, quantidade) => {
    return (quantidadeLote * quantidade)
  }

  openFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    });
  };

  closeFeedback = () => {
    this.setState({
      showFeedback: false,
    });
  };

  startFetching = () => {
    this.setState({
      isFetching: true
    });
  };

  stopFetching = () => {
    this.setState({
      isFetching: false
    });
  };

  render() {
    const { open, handleClose } = this.props;
    const { isFetching, pedidoManual, showFeedback, messageFeedBack } = this.state;

    return (
      <div>
        <Loading isLoading={isFetching} />

        <Dialog
          title={intl.get('commons.contingencyRequest')}
          contentStyle={{ width: "600px" }}
          open={open}
          onRequestClose={handleClose}
          autoScrollBodyContent
        >
          <SuggestionsListDetail
            isOrder
            item={pedidoManual}
            salvarSugestao={this.salvarSugestao}
            changeValueSugestao={this.changeValueSugestao}
            changeValueDeadline={this.changeValueDeadline}
            handleFeedback={this.openFeedback}
            handleClose={handleClose}
          />

          {showFeedback && <SnackBar
            open={showFeedback}
            message={messageFeedBack}
            autoHideDuration={3000}
            onRequestClose={this.closeFeedback}
            style={{ width: "100%", textAlign: "center" }}
          />}

        </Dialog>
      </div>
    );
  }
}

ManualOrderForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default ManualOrderForm
