import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'
import CenterLoading from 'components/center-loading'
import { Input } from '@hbsis.uikit/react'
import { Button, SelectBox } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'
import './budget-form.css'
import { formatErrorMessage } from 'utils/handle-error'

class BudgetForm extends Component {
  constructor() {
    super()

    this.state = {
      budget: {
        InternationalMaterialGroupId: {},
        MaterialGroupType: 0
      },
      managers: [],
      labeledFamilies: [],
      selectedFamilies: [],
      internationalMaterialGroups: [],
      materialGroupTypes: [],
      isSaving: false
    };
  }

  componentDidMount() {
    this.loadInternationalMaterialGroups();
    this.loadMaterialGroupTypes();

    const editMode = this.props.editMode;

    if (editMode) {
      this.searchBudgetById();
    }
  }

  loadInternationalMaterialGroups = () => {
    Fetch.get(`/international-material-groups`)
      .then((response) => {
        this.setState({ internationalMaterialGroups: response.data })
      });
  }

  loadMaterialGroupTypes = () => {
    Fetch.get(`/material-groups:material-groups-types`)
      .then((response) => {
        this.setState({ materialGroupTypes: response.data })
      });
  }

  startSaving = () => {
    this.setState({
      isSaving: true
    });
  }

  stopSaving = () => {
    this.setState({
      isSaving: false
    })
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(formatErrorMessage(exception))
  }

  searchBudgetById = () => {
    const idFamily = this.props.idEdit;

    Fetch.get(`/material-groups/${idFamily}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  changeValue = (event) => {
    const prop = event.target.name
    const value = event.target.value

    this.setState(prevState => ({
      budget: {
        ...prevState.budget,
        [prop]: value
      }
    }))
  }

  updateInternationalMaterialGroupSelectValue = (prop, value) => {
    this.setState(prevState => ({
      budget: {
        ...prevState.budget,
        InternationalMaterialGroup: {
          ...prevState.budget.InternationalMaterialGroup,
          [prop]: value
        }
      }
    }))
  }

  updateSelectValue = (prop, value) => {
    this.setState(prevState => ({
      budget: {
        ...prevState.budget,
        [prop]: value
      }
    }))
  }

  proccessData = (data) => {
    const { Id, Description, InternationalMaterialGroupId, InternationalMaterialGroupName, IsBottle, IsCan, Lines, MaterialGroupType } = data

    this.setState({
      budget: {
        Id: Id,
        Description: Description,
        IsBottle: IsBottle,
        IsCan: IsCan,
        Lines: Lines,
        MaterialGroupType: MaterialGroupType,
        InternationalMaterialGroup: {
          Id: InternationalMaterialGroupId,
          Name: InternationalMaterialGroupName
        }
      }
    })
  }

  save = () => {
    this.startSaving()

    const editMode = this.props.editMode

    let model = {
      Id: this.state.budget.Id,
      Description: this.state.budget.Description,
      IsBottle: this.state.budget.IsBottle,
      IsCan: this.state.budget.IsCan,
      MaterialGroupType: this.state.budget.MaterialGroupType,
      InternationalMaterialGroupId: this.state.budget.InternationalMaterialGroup?.Id,
      InternationalMaterialGroupName: this.state.budget.InternationalMaterialGroup?.Name,
      Lines: this.state.budget.Lines
    }

    model.Id = editMode ? this.props.idEdit : undefined
    Fetch.post(`/material-groups`, model)
      .then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
      .catch((e) => this.proccessErro(e))
      .finally(this.stopSaving)
  }

  render() {
    let { budget, isSaving, internationalMaterialGroups, materialGroupTypes } = this.state
    let { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Dialog
          title={editMode ? intl.get('master-data.walletsAndFamilys.wallets.actions.editWallet') : intl.get('master-data.walletsAndFamilys.wallets.actions.newWallet')}
          contentStyle={{ width: '600px' }}
          bodyClassName='modalBodyClass'
          open={open}
          autoScrollBodyContent
        >
          <CenterLoading isLoading={isSaving} fullHeightParent />
          <div className='line-1-columns'>
            <Input
              className='input'
              required
              name='Description'
              onChange={this.changeValue}
              value={budget.Description}
              label={intl.get('master-data.general-configuration.name')}
              disabled={!profileAdminOrMasterData}
            />
          </div>
          <div className='line-1-columns'>
            <SelectBox
              name='InternationalMaterialGroup'
              label={intl.get('master-data.walletsAndFamilys.wallets.InternationalMaterialGroupName')}
              valueKey='Id'
              labelKey='Name'
              value={budget.InternationalMaterialGroup?.Id}
              options={internationalMaterialGroups}
              onChange={value => this.updateInternationalMaterialGroupSelectValue('Id', value?.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              clearable
            />
          </div>
          <div className='line-1-columns'>
            <SelectBox
              name='MaterialGroupType'
              className="materialGroupType"
              label={intl.get('master-data.walletsAndFamilys.wallets.MaterialGroupType')}
              valueKey='Id'
              labelKey='Name'
              value={budget.MaterialGroupType}
              options={materialGroupTypes}
              onChange={value => this.updateSelectValue('MaterialGroupType', value?.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
            />
          </div>
          <div className='footer-dialog-buttons'>
            <Button
              value={intl.get('geral.buttonsDefault.cancel')}
              type="default"
              onClick={() => { this.props.handleClose() }}
              className="button"
            />
            {profileAdminOrMasterData &&
              <Button
                value={intl.get('geral.buttonsDefault.save')}
                type="primary"
                className="button"
                onClick={this.save}
              />
            }
          </div>
        </Dialog>
      </div>
    )
  }
}

BudgetForm.propTypes = {
  editMode: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  idEdit: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired
}

export default BudgetForm
