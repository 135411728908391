import intl from "react-intl-universal";
import * as S from "./styled";
import { IOption } from "types/option";

interface IProps {
  storageLocations: IOption[];
  selectedStorageLocationId: number;
  changeStorageLocation: (value: number) => Promise<void>;
}

const NavbarStorageLocation = ({ storageLocations, selectedStorageLocationId, changeStorageLocation}: IProps) => {
  const isInOverflow = () => {
    const container = document.getElementById("navbar-storage");
    if (container) return container.scrollWidth > container.clientWidth;
    return false;
  }

  const handleClickRight = () => {
    const container = document.getElementById('navbar-storage');
    if (container) container.scrollLeft += 50;
  }

  const handleClickLeft = () => {
    const container = document.getElementById('navbar-storage');
    if (container) container.scrollLeft -= 50;
  }

  return (
    <S.Navbar id="navbar-storage" overflow={isInOverflow()}>
      <S.List>
        {storageLocations.map((x) => (
          <S.Option
            key={x.Id}
            selected={selectedStorageLocationId === x.Id}
            onClick={() => changeStorageLocation(x.Id)}
          >
            {intl.get("master-data.general-configuration.storageLocationAbbreviation")} {x.Description}
          </S.Option>
        ))}
        {isInOverflow() &&
          <S.ButtonsWrapper>
            <S.NavigationButton onClick={handleClickLeft}> {"<"} </S.NavigationButton>
            <S.NavigationButton onClick={handleClickRight}> {">"} </S.NavigationButton>
          </S.ButtonsWrapper>
        }
      </S.List>
    </S.Navbar>
  );
};

export default NavbarStorageLocation;
