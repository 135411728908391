import TipoPerfilAcesso from "models/usuarios/tipo-perfil-acesso";
import SessionUser from 'utils/user-storage';

const TIPO_ALGORITMO_CAPACIDADE_ARMAZENAGEM = 2;
const TIPO_ALGORITMO_POLITICA_SILOS = 3;

export const validaReplanejamentosPedidosDX = (replanejamentoPedidos) => {
  return replanejamentoPedidos === "" || replanejamentoPedidos < 0
}

export const userCanEditStockPolicies = () => {
  const editableUserProfiles = [
    TipoPerfilAcesso.Gestor,
    TipoPerfilAcesso.MasterData,
    TipoPerfilAcesso.Administrador
  ]

  return editableUserProfiles.includes(SessionUser.get().PerfilAcesso);
}

export const isCurrentRefuelingPointPercentDisabled = (selectedTipoAlgoritmo) => {
  const algorithmTypeToDisable = [TIPO_ALGORITMO_CAPACIDADE_ARMAZENAGEM, TIPO_ALGORITMO_POLITICA_SILOS];
  return (algorithmTypeToDisable.includes(selectedTipoAlgoritmo) || !userCanEditStockPolicies())
}

export const verifyAllStorageLocationsHasLines = (storages, data) => {
  if(!storages || !data) return false

  const amountOfStorages = storages.length;
  const amountOfStoragesWithRegisters = data.filter(x => x?.LabeledFamilyCenterStorageLocationProductionLines?.length > 0).length;

  return amountOfStorages === amountOfStoragesWithRegisters;
}

export const verifyStoragesHasSomePolicyError = (storagesData) => {
  // Checks whether an element has maximum stock policy under or equal the minimum stock policy
  const hasNotValidStockPolicy = storagesData.some((element) => element.CurrentMaximumStock
  < element.CurrentMinimumStock);

  return hasNotValidStockPolicy;
}