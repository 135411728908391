import Session from 'utils/user-storage';
import { SituacaoComposicaoCarga } from 'models/composicaoCarga/situacaoComposicaoCarga';
import { isLoadCompositionFlowBrewerySide } from 'utils/validations-general-settings';
import TipoUsuario from 'models/usuarios/tipo-usuario';

export const validateShowButtonEmergencyNegotiateSupplierSide = (loadComposition) => {
  const isSupplierUser = Session.get()?.TipoUsuario === TipoUsuario.Fornecedor
  const isValidStatus = SituacaoComposicaoCarga.Aceito === loadComposition.SituacaoValor
    || SituacaoComposicaoCarga.NegociacaoEmergencial == loadComposition.SituacaoValor

    return ((isLoadCompositionFlowBrewerySide() && loadComposition.CanCreateEmergencyRenegotiation) || (loadComposition.IsInternationalSupplier && loadComposition.CanCreateEmergencyRenegotiation))
    && isSupplierUser
    && isValidStatus;
}

export const validateShowButtonEmergencyNegotiationAmbevSide = (loadComposition) => {
  const isAmbevUser = Session.get()?.TipoUsuario === TipoUsuario.Ambev;
  const isValidStatus = SituacaoComposicaoCarga.Aceito === loadComposition.SituacaoValor;

  return ((isLoadCompositionFlowBrewerySide() && loadComposition.CanCreateEmergencyRenegotiation) || (loadComposition.IsInternationalSupplier && loadComposition.CanCreateEmergencyRenegotiation))
    && isAmbevUser
    && isValidStatus;
} 