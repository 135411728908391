import { useState } from 'react';
import intl from "react-intl-universal";
import * as S from './styled';
import { ClbButton } from '@celebration/design-system-react';
import ImportExportSupplierProductionPlan from 'components/import-export-supplier-production-plan';
import { useSupplierProductionPlanContext } from 'features/supplier-production-plan/context';
import { formatErrorMessage } from 'utils/handle-error';
import { useToastContext } from 'hooks/useToastContext';

const SupplierProductionPlanUpload = () => {
  const { openToast } = useToastContext();
  const { filters, searchProductionPlanData, setLoading } = useSupplierProductionPlanContext();
  const [openUpload, setOpenUpload] = useState(false);

  const close = async () => {
    try {
      setLoading(true);
      await searchProductionPlanData(filters);
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative');
    } finally {
      setLoading(false);
      setOpenUpload(false);
    }
  }

  return(
    <S.UploadSectionWrapper>
      <ClbButton 
        textContent={intl.get('exportImportModal.butttons.upload')}
        onClbClick={() => setOpenUpload(!openUpload)}
      />
      <ImportExportSupplierProductionPlan isOpen={openUpload} close={close} />
    </S.UploadSectionWrapper>
  )
}

export default SupplierProductionPlanUpload;