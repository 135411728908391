import intl from 'react-intl-universal';
import { Button, Dialog, SelectBox } from 'components/uikit-adapter';
import {
  ModalText,
  ModalTitle,
  WrapperButtons,
  WrapperContent,
} from './styled';
import { IJustification } from 'types/justification';
import { useToastContext } from 'hooks/useToastContext';

interface ILoadBuildingJustificationModalProps {
  open: boolean;
  handleConfirm: (key?: number) => void;
  handleClose: () => void;
  justifications: Array<IJustification>;
  justification: number;
  handleChangeJustification: (field: string, value: number) => void
}

const LoadBuildingJustificationModal = ({
  open,
  handleConfirm,
  handleClose,
  justifications,
  justification,
  handleChangeJustification
}: ILoadBuildingJustificationModalProps) => {
  const { openToast } = useToastContext();

  const handleSelectChange = (value: IJustification) => {
    handleChangeJustification('TypeJustificationManualSuggestion', value.Key);
  };

  const handleConfirmButton = () => {
    if(!justification){
      openToast(intl.get('load-building.page-flow.modal-justification.youMustProvideAJustificationToProceed'), 'negative');
    } else {
      handleConfirm(justification);
      handleClose();
    } 
  }

  return (
    <Dialog
      open={open}
      contentStyle={{ width: '720px' }}
      onRequestClose={handleClose}
    >
      <WrapperContent>
        <ModalTitle>
          {intl.get('load-building.page-flow.modal-justification.title')}
        </ModalTitle>

        <ModalText>
          {intl.get('load-building.page-flow.modal-justification.message')}
        </ModalText>
        <SelectBox
          required
          name='justifications'
          className='justifications-selectbox'
          valueKey='Key'
          labelKey='Value'
          value={justification}
          options={justifications}
          onChange={(value: IJustification) => handleSelectChange(value)}
          openOnFocus
          placeholder={intl.get(
            'load-building.page-flow.modal-justification.placeholder'
          )}
          disabled={undefined}
          data-testid="selectbox-justification"
        />
      </WrapperContent>
      <WrapperButtons>
        <Button
          width='150px'
          value={intl.get('geral.modalConfirm.no')}
          onClick={handleClose}
          className='btn'
          buttonIcon={undefined}
          buttonText={undefined}
        />
        <Button
          width='150px'
          type='primary'
          value={intl.get('geral.modalConfirm.yes')}
          onClick={handleConfirmButton}
          className='btn margin-botao'
          buttonIcon={undefined}
          buttonText={undefined}
        />
      </WrapperButtons>
    </Dialog>
  );
};

export default LoadBuildingJustificationModal;
