import styled from 'styled-components'

export const Wrapper = styled.div`
${p => `
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 77px;
  width: 126px;
  border: solid 1px ${p.color};
  padding: 4px 6px;
  border-radius: 3px;

  header{
    padding: 4px;
    background: ${p.color};
    border-radius: 24px;
    text-align: center;
    font-size: 12px;
    color: white;
    font-weight: 600;
    text-wrap: nowrap;
  }

  div{
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: ${p.color};
    font-weight: 600;
  }

  footer{
    text-align: center;
    font-size: 12px;
    color: ${p.color};
    gap: 4px;
    display: flex;
    justify-content: center;
  }
`}
`
