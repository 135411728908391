import PropTypes from "prop-types";
import intl from "react-intl-universal";
import SessionUser from "utils/user-storage";
import DangerDialog from "components/danger-dialog";
import { useGlobalContext } from "hooks/useGlobalContext";
import { useEffect, useState } from "react";
import {
  isLoadCompositionFlowBrewerySide,
  isPurchaseOrderFlow,
  isScheduleAgreementFlow,
} from "utils/validations-general-settings";
import "./modal-cancel.css";

export const CompositionsCancelModal = ({
  open,
  close,
  data,
  clearData,
  refreshSearch,
}) => {
  const { startOperation } = useGlobalContext();
  const [justificativas, setJustificativas] = useState([]);

  const handleConfirm = async () => {
      let requests = data.map((item) => {
        let _body = {
          LoadCompositionId: item,
          JustificationId: 1,
        };
  
        if (isLoadCompositionFlowBrewerySide() && isScheduleAgreementFlow()) {
          return {
            API: `load-compositions:cancel-by-schedule-agreement`,
            method: "post",
            body: _body,
            errorId: item,
          };
        } else if (isLoadCompositionFlowBrewerySide() && isPurchaseOrderFlow()) {
          return {
            API: `load-compositions:cancel-by-purchase-order`,
            method: "post",
            body: _body,
            errorId: item,
          };
        } else {
          _body = { ..._body, UserId: SessionUser.get().Id };
  
          return {
            API: `load-compositions/${item}:cancel`,
            method: "post",
            body: _body,
            errorId: item,
          };
        }
      },);
  
      requests = requests.filter((item) => item);
      const text = intl.get("orders.operationText01");
  
      startOperation(requests, text, refreshSearch);
      clearData([]);
      close();
  };

  const initData = async () => {
    const data = JSON.parse(localStorage.getItem("enumerators"));
    if (data) {
      setJustificativas(data.OrderCancellationJustifications);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
  }, [refreshSearch]);

  return (
      <DangerDialog
        show={open}
        title={intl.get("transports.compoCharge.actions.cancelComposition")}
        labelButtonClose={intl.get("composition.actions.close")}
        handleConfirm={handleConfirm}
        handleClose={close}
        justificationMessages={justificativas}
        isLoading={false}
        labelButton={intl.get("commons.confirm")}
      />
  );
};

CompositionsCancelModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    filter: PropTypes.func,
    find: PropTypes.func,
    map: PropTypes.func,
    OrderCancellationJustifications: PropTypes.number,
  }).isRequired,
  open: PropTypes.any.isRequired,
  refreshSearch: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};
