import { Component } from "react";
import PropTypes from "prop-types";
import Fetch from "utils/fetch";
import moment from "moment";
import SessionUser from "utils/user-storage";
import Message from "components/message";
import { Row, Col } from "react-flexbox-grid";
import { Input, Textarea } from "@hbsis.uikit/react";
import { SelectBox, Button } from "components/uikit-adapter/index"
import {
  formatErrorMessage,
} from "utils/handle-error";
import intl from 'react-intl-universal';
import "./contraproposta.css";
import DateInput from "../../date-input";
import InputQuantidade from "../components/input-quantidade";
import { isPurchaseOrderFlow } from "utils/validations-general-settings";
import Feature from "components/feature";
import { parseFloatByLocale } from "locales/utils";
import { getTextAreaStyle } from "components/suggestions-list/suggestions-list-item/suggestions-list-item.styled";
import { fetchSuggestionsJustifications } from "services/suggestion/suggestion.service";

class ContraPropostaFornecedor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantidade: 0,
      dataEntregaPrevista: "",
      dataColetaPrevista: "",
      observacao: "",
      showMsg: false,
      textMsg: "",
      titleMsg: "",
      unidadeMedida: "",
      fornecedores: [],
      fornecedor: null,
      isLoading: false,
      statusAlteracaoFornecedor: {
        fornecedorDesabilitado: false,
        tituloFornecedorBloqueado: '',
      },
      horaEntrega: null,
      justification: null,
      justifications: []
    };
  }

  componentDidMount() {
    this.setState({
      quantidade: this.props.quantidade,
      unidadeMedida: this.props.unidadeMedida,
      dataColetaPrevista: moment(this.props.data.DataColetaPrevista).format("YYYY-MM-DD"),
      dataEntregaPrevista: moment(this.props.data.DataEntregaPrevista).format("YYYY-MM-DD"),
      observacao: "",
      fornecedor: this.props.idFornecedor,
      horaEntrega: this.props.horaEntrega,
      justification: this.props.justification
    }, () => {
      this.searchSuppliers()
      this.verificarAlteracaoFornecedor()
      this.searchJustifications()
    });
  }

  startLoading = () => { this.setState({ isLoading: true }) }

  stopLoading = () => { this.setState({ isLoading: false }) }

  searchSuppliers = () => {
    const { idPedido } = this.props;
    this.startLoading()
    Fetch.get(`suppliers:by-order?orderId=${idPedido}`)
      .then(response => this.processSupplier(response.data))
      .catch(e => this.proccessErro(e))
      .finally(() => this.stopLoading())
  }

  processSupplier = (data) => {
    this.setState({ fornecedores: data });
  }

  verificarAlteracaoFornecedor = () => {
    if (!!this.props.data.IdComposicao) {
      this.setState({
        statusAlteracaoFornecedor: {
          fornecedorDesabilitado: true,
          tituloFornecedorBloqueado: intl.get('feedbacks.messageSupplierContrapoposta')
        }
      })
    }
  }

  searchJustifications = async () => {
    const _justifications = await fetchSuggestionsJustifications();
    this.setState({ justifications: _justifications });
  }

  changeValue = (event) => {
    const prop = event.target.name
    let value = event.target.value

    this.setState({ [prop]: value });
  }

  changeValueData = (name, data) => {
    this.setState({
      [name]: data
    });
  }

  changeSelectValue = (prop, value) => {
    this.setState({
      [prop]: value
    });
  }

  save = (e) => {
    e.preventDefault();

    const user = SessionUser.get();

    const {
      idPedido,
      isEmergencial,
      refreshTimeLine,
      handleFeedback
    } = this.props;

    const {
      quantidade,
      unidadeMedida,
      dataColetaPrevista,
      dataEntregaPrevista,
      observacao,
      fornecedor,
      horaEntrega,
      justification
    } = this.state;

    const urlPost = isEmergencial
      ? "contraproposta/emergencial"
      : "contraproposta";

    const userType = user.TipoUsuario == 1 ? "comprador" : "fornecedor";

    if (!dataColetaPrevista) {
      handleFeedback(intl.get('feedbacks.mandatoryPickupDate'))
    } else if (!dataEntregaPrevista) {
      handleFeedback(intl.get('feedbacks.mandatoryDeliveryDate'))
    } else if (dataColetaPrevista > dataEntregaPrevista) {
      handleFeedback(intl.get('feedbacks.errorMandatoryPickupDate'))
    } else if (!justification) {
      handleFeedback(intl.get('suggestions.modalSuggestionInOver.justificationRequired'))
    } else {
      this.startLoading()
      Fetch.post(`/timeline/${urlPost}/${userType}`, {
        IdPedido: idPedido,
        Quantidade: parseFloatByLocale(quantidade),
        UnidadeMedida: unidadeMedida,
        DataColeta: dataColetaPrevista,
        DataEntrega: dataEntregaPrevista,
        IdUsuario: user.Id,
        Observacao: observacao,
        idFornecedor: fornecedor,
        HoraEntrega: horaEntrega,
        TipoJustificativaSugestaoManual: justification
      })
        .then(refreshTimeLine)
        .then(() => handleFeedback(intl.get('stocks.timeline.feedback.sendCounterproposal')))
        .catch(e => this.proccessErro(e))
        .finally(() => this.stopLoading())
    }
  }

  changeValueHoraEntrega = (event) => {
    this.setState({
      horaEntrega: event.target.value
    })
  }

  proccessErro = (exception) => {
    this.setState({
      showMsg: true,
      titleMsg:
        exception.response.status === 400 ? intl.get('feedbacks.atention') : intl.get('feedbacks.error'),
      textMsg:
        exception.response.status === 400
          ? formatErrorMessage(exception)
          : intl.get('feedbacks.offServer')
    });
  }

  render() {
    const {
      quantidade,
      dataColetaPrevista,
      dataEntregaPrevista,
      observacao,
      fornecedores,
      fornecedor,
      statusAlteracaoFornecedor,
      horaEntrega,
      justification,
      justifications
    } = this.state;

    return (
      <div className="negotiation">
        <div className="negotiation-line">
          <Row>
            <Col xs={6} className="components-childs">
              <DateInput
                label={intl.get('commons.colDate')}
                value={dataColetaPrevista}
                onChange={(date) => this.changeValueData('dataColetaPrevista', date)}
                minDate={moment().format('YYYY-MM-DD')}
                maxDate="9999-12-31"
                className="data-coleta-picker"
              />
            </Col>

            <Col xs={6} className="components-childs">
              <DateInput
                label={intl.get('commons.deliveryDate')}
                value={dataEntregaPrevista}
                onChange={(date) => this.changeValueData('dataEntregaPrevista', date)}
                minDate={moment().format('YYYY-MM-DD')}
                maxDate="9999-12-31"
                className="data-entrega-picker"
              />
            </Col>

            <InputQuantidade
              changeValue={this.changeValue}
              quantidade={quantidade}
            />

            <Col xs={6}>
              <Input
                label={intl.get('commons.deliveryTime')}
                name='HoraEntrega'
                htmlType='time'
                value={horaEntrega}
                onChange={event => this.changeValueHoraEntrega(event)}
                className='txtHoraEntrega input'
              />
            </Col>
          </Row>
        </div>
        <Feature validation={isPurchaseOrderFlow()}>
          <div className="negotiation-line">
            <Row>
              <Col xs={12}>
                <div title={statusAlteracaoFornecedor.tituloFornecedorBloqueado} style={{ width: '400px' }}>
                  <SelectBox
                    disabled={statusAlteracaoFornecedor.fornecedorDesabilitado}
                    required
                    name="fornecedor"
                    label={intl.get('commons.supplier')}
                    placeholder={intl.get('commons.supplier')}
                    valueKey="Id"
                    labelKey="Description"
                    value={fornecedor}
                    options={fornecedores}
                    onChange={(value) => this.changeSelectValue('fornecedor', value.Id)}
                    searchable
                    openOnFocus
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Feature>
        <div className="negotiation-line">
          <Row>
            <Col xs={12}>
              <div style={{ width: '400px' }}>
                <SelectBox
                  label={intl.get('commons.justification')}
                  required
                  name='justifications'
                  className='justifications-selectbox'
                  valueKey='Key'
                  labelKey='Value'
                  value={justification}
                  options={justifications}
                  onChange={(value) => this.changeSelectValue('justification', value.Key)}
                  openOnFocus
                  placeholder={intl.get('load-building.page-flow.modal-justification.placeholder')}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="negotiation-line">
          <Row>
            <Col xs={12}>
              <Textarea
                name="observacao"
                label={intl.get('manual-suggestion-order-form.observation')}
                value={observacao || ''}
                onChange={this.changeValue}
                style={getTextAreaStyle({ width: '400px' })}
              />
            </Col>
          </Row>
        </div>

        <div className="negotiation-line">
          <div className="event-action-offer">
            <Row>
              <Col xs={6}>
                <Button
                  disabled={this.state.isLoading}
                  value={intl.get('geral.buttonsDefault.close')}
                  onClick={this.props.handleCancel}
                  type="default"
                  className="button"
                />
              </Col>

              <Col xs={6}>
                <Button
                  disabled={this.state.isLoading}
                  value={intl.get('feedbacks.confirmContrapoposta')}
                  onClick={this.save}
                  type="primary"
                  className="button"
                />
              </Col>
            </Row>

          </div>
        </div>


        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={() => {
            this.setState({ showMsg: false });
          }}
        />
      </div>
    );
  }
}

ContraPropostaFornecedor.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  quantidade: PropTypes.number,
  data: PropTypes.object,
  unidadeMedida: PropTypes.string,
  idFornecedor: PropTypes.number,
  horaEntrega: PropTypes.string,
  idPedido: PropTypes.number.isRequired,
  isEmergencial: PropTypes.bool,
  refreshTimeLine: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  justification: PropTypes.number
};

export default ContraPropostaFornecedor;