import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    height: calc(100vh - 250px);
    overflow-x: scroll;
    overflow-y: auto;
  `

export const ContainerTable = styled.div`
  display: flex;
  flex-direction: row;
  height: max-content;
  `

export const TitleContainer = styled.div`
  height: 50px;
  width: 128px;
  background: var(--color-action-default);
  color: white;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 600;
  position: sticky;
  top: 0;
`
export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ContainerWeek = styled.div`
 border-right: 1px dashed #cacaca;
 padding: 0px 16px 12px 16px;

 
  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    padding-right: 0px;
    border-right: none;
  }
`