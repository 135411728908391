import intl from 'react-intl-universal'
import {
  ClbCheckbox,
  ClbTableTHead,
  ClbTableTHeadCell,
  ClbTableTHeadRow,
  ClbText,
} from 'libs/celebration'
import { useLoadBuildingContext } from 'features/load-building/context'
import PropTypes from 'prop-types'

export const LoadBuildingTableHeader = ({ data }) => {
  const { volumesSelected, setVolumesSelected, setVolumesSelectedData } = useLoadBuildingContext();

  const handleCheckboxChange = (e) => {
    const _previousSelectedVolumes = volumesSelected;
    const _data = data?.Data?.map(x => x.Id);
    if (e.detail.checked) {
      setVolumesSelected(prevState => [...prevState,
      ..._data.filter(x => !prevState.includes(x))
      ])
      setVolumesSelectedData(prevState => ([ ...prevState, 
        ...(data?.Data ?? []).filter(y => !_previousSelectedVolumes.includes(y.Id)) 
      ]));
    } else {
      setVolumesSelected(prevState => (prevState.filter(x => !_data.includes(x))));
      setVolumesSelectedData(prevState => (prevState.filter(y => !_data.includes(y.Id))));
    }
  }

  return (
    <ClbTableTHead>
      <ClbTableTHeadRow>
        <ClbTableTHeadCell>
          <ClbText slot="content">
            <ClbCheckbox
              id='checkbox-all'
              data-testid='checkbox-all'
              checked={data?.Data?.every(x => volumesSelected.includes(x.Id))}
              onClbChange={handleCheckboxChange}
              disabled={!data?.Data?.length}
            />
          </ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get('master-data.general-configuration.type')}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.volumeCode")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("stocks.stockDetail.internalVolumnPortal")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get('manual-suggestion-order-form.origin')}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.destiny")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.storageLocation")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.pickup")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.delivery")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.deliveryTime")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("commons.material")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell>
          <ClbText slot="content">{intl.get("stocks.qty")}</ClbText>
        </ClbTableTHeadCell>
        <ClbTableTHeadCell />
      </ClbTableTHeadRow>
    </ClbTableTHead>
  )
}

LoadBuildingTableHeader.propTypes = {
  data: PropTypes.shape({
    Data: PropTypes.array.isRequired,
  })
}