import PropTypes from 'prop-types'
import * as S from "./styled";
import TitleHeader from 'components/title-header'

const Header = ({ title, children = undefined, icon, onClickIcon, titleWidth, noBackground }) => (
  <S.HeaderContainer noBackground={noBackground}>
    {icon &&
      <S.H3 className="icone" onClick={onClickIcon} data-testid="master-data-menu-button">
        {icon}
      </S.H3>
    }
    <TitleHeader
      className="titleHeader"
      width={titleWidth}
      noBackground={noBackground}>
      {title}
    </TitleHeader>
    {children}
  </S.HeaderContainer>
)

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.any,
  onClickIcon: PropTypes.func,
  titleWidth: PropTypes.number,
  noBackground: PropTypes.bool
}

export default Header
