import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import Dialog from "material-ui/Dialog"
import PropTypes, { number } from 'prop-types'

const ModalConfirmAccept = ({ open, close, onClick, volumeOrdersIds }) => {
  const getVolumeOrdersList = () => volumeOrdersIds.join(', ');

  return(
  <Dialog
    autoScrollBodyContent
    open={open}
    contentStyle={{ width: "600px" }}
    title={intl.get('volumeOrders.confirmVolumesAcceptance')}
    onRequestClose={close}
  >
    <p>{intl.get('volumeOrders.confirmVolumesAcceptanceMesage')}</p>
    <p> {volumeOrdersIds.length} {intl.get('volumeOrders.confirmVolumesAcceptanceMesage2')} {getVolumeOrdersList()}. </p>
    <div className="footer-dialog-buttons">
      <Button
        width="150px"
        value={intl.get('geral.buttonsDefault.cancel')}
        onClick={close}
        className="button cancel"
        id="button-cancel-modal-confirm-accept"
      />
      <Button
        width="150px"
        type="primary"
        value={intl.get('geral.buttonsDefault.confirm')}
        onClick={onClick}
        className="button send"
        id="button-confirm-modal-confirm-accept"
      />
    </div>
  </Dialog>
)
}

ModalConfirmAccept.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  onClick: PropTypes.func,
  volumeOrdersIds: PropTypes.arrayOf(number)
}

export default ModalConfirmAccept
