import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import * as S from './styled'
import { ObterLinhaProducaoPorCentro } from '../../../pages/master-data/unit-management/business-unit/business-unit.service'
import { SelectBox } from 'components/uikit-adapter/index'
import { Checkbox } from "@hbsis.uikit/react"
import { getAllCenters } from '../../../services/center.service'
import DeadlineControl from "components/deadline-control";
import IconFilterWhite from 'images/icn-filter-white.svg'
import ModalDiscarChanges from '../modal-discard'
import ArrowBackIcn from 'images/arrow-back.svg'
import { fetchVolums } from '../auto-load-optimization.service'
import { formatErrors } from '../utils/validate'
import { shiftValues, initialBusinessUnit } from '../utils/models'
import { useZsagContext } from '../context/zsagContext'
import { validateData, validClickBack, validGetPrimaryMaterial } from './validate'

const FilterAutoLoadOptimization = ({ handleGetVolumes, discardChanges, haveData, processError, setLoading }) => {
  const [businessUnit, setBusinessUnit] = useState([]);
  const [linhaProducaoCentro, setLinhaProducaoCentro] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(initialBusinessUnit);
  const [selectedLinhaProducaoCentro, setSelectedLinhaProducaoCentro] = useState();
  const [selectedShift, setSelectedShift] = useState()
  const [date, setDate] = useState({
    deliveryDateFrom: '',
    deliveryDateTo: ''
  });
  const [isOpenModalDiscardChanges, setIsOpenModalDiscardChanges] = useState(false);
  const [recalculate, setRecalculate] = useState()
  const { haveUnsavedChanges } = useZsagContext()

  const changeValueDeadline = (pickUp, deliveryDate) => {
    setDate({
      deliveryDateFrom: pickUp,
      deliveryDateTo: deliveryDate
    })
  }

  const changeShift = (value) => {
    setSelectedShift(value.value)
  }

  const initalConfigs = async () => {
    await getBusinessUnit();
  }

  const runZSAG = async () => {
    const data = {
      date,
      plant: selectedBusinessUnit,
      productionVersion: selectedLinhaProducaoCentro,
      shift: selectedShift,
      recalculate: recalculate,
    }
    const validated = validateData(data);
    const materials = await validGetPrimaryMaterial(validated, selectedBusinessUnit.Id)
    validated !== "" ? processError(validated) : getDataList(data, materials);
  };

  const getDataList = async (filter, materials) => {
    try {
      setLoading(true);
      const volumesData = await fetchVolums(filter)
      handleGetVolumes(volumesData, filter, materials)
      if (volumesData.HasErrors) {
        processError(formatErrors(volumesData.Errors))
      }
    } catch (exception) {
      processError(exception)
    }
    setLoading(false);
  }

  const loadLinhaProducaoPorCentro = async () => {
    try {
      const data = await ObterLinhaProducaoPorCentro(selectedBusinessUnit.Centro);
      const normalizeData = data.map((value) => ({
        key: value.Id,
        productionLine: value.ProductionLine
      }));
      setLinhaProducaoCentro(normalizeData);
      setSelectedLinhaProducaoCentro(undefined);
    }
    catch (e) {
      processError(e)
    }
  }

  const onChangeBusinnesUnit = unidadeNegocio => {
    setSelectedBusinessUnit(unidadeNegocio);
  }

  const onChangeProductionLine = (linhaProducao) => {
    setSelectedLinhaProducaoCentro(linhaProducao);
  }

  const getBusinessUnit = async () => {
    try {
      const data = await getAllCenters().then(x => x.data);
      setBusinessUnit(data);
    }
    catch (e) {
      processError(e);
    }
  }

  const handleCloseModal = () => setIsOpenModalDiscardChanges(false);
  const handleOpenModal = () => setIsOpenModalDiscardChanges(true);

  const handleDiscardChanges = () => {
    setSelectedBusinessUnit({
      Id: 0,
      Centro: "",
      Email: "",
      Nome: ""
    })
    setSelectedShift(undefined);
    setSelectedLinhaProducaoCentro(undefined);
    setDate({
      deliveryDateFrom: '',
      deliveryDateTo: ''
    });
    discardChanges();
    handleCloseModal();
  }

  useEffect(() => {
    initalConfigs();
  }, []);

  useEffect(() => {
    selectedBusinessUnit.Id > 0 && (
      loadLinhaProducaoPorCentro()
    )
  }, [selectedBusinessUnit])

  return (
    <S.Wrapper haveData={haveData}>
      <S.Content>
        {!haveData ?
          <React.Fragment>
            <SelectBox
              width="200px"
              required
              name='IdUnidadeNegocio'
              label={intl.get('commons.plant')}
              placeholder={intl.get('commons.plant')}
              valueKey='Id'
              labelKey='Nome'
              value={selectedBusinessUnit}
              options={businessUnit}
              onChange={value => onChangeBusinnesUnit(value)}
              className='selectBoxBusinnesUnit'
              searchable
              data-testid='selectBoxBusinnesUnit'
            />
            <SelectBox
              width="240px"
              disabled={selectedBusinessUnit.Id <= 0}
              name='ProductionLine'
              label={intl.get('commons.productionLine')}
              placeholder={intl.get('commons.productionLine')}
              valueKey='key'
              labelKey='productionLine'
              value={selectedLinhaProducaoCentro}
              options={linhaProducaoCentro}
              onChange={value => onChangeProductionLine(value)}
              className='selectBoxProductionLine'
              clearable
              data-testid='selectBoxProductionLine'
            />
            <DeadlineControl
              TituloColeta={intl.get("stocks.from")}
              TituloEntrega={intl.get("stocks.until")}
              showSeparator={false}
              OnChange={(coleta, entrega) =>
                changeValueDeadline(coleta, entrega)
              }
              Coleta={date.deliveryDateFrom}
              DataEntregaDe={date.deliveryDateTo}
              CadeadoDisabled
              className='date'
              noLockOption
              TransitTime={1}
            />
            <SelectBox
              width="100px"
              required
              name='shift'
              label={intl.get('commons.shift')}
              placeholder={intl.get('commons.shift')}
              valueKey='value'
              labelKey='value'
              value={selectedShift}
              options={shiftValues}
              onChange={e => changeShift(e)}
              className='selectBoxShift'
              data-testid='selectBoxShift'
            />
            <Checkbox
              label="Recalculate"
              name="checkbox"
              data-testid='checkBoxRecalculate'
              checked={recalculate}
              onChange={() => setRecalculate(!recalculate)}
              className='checkbox'
            />
            <S.Button
              type="primary"
              value={intl.get('commons.run')}
              className="btn-save"
              onClick={runZSAG}
              icon={IconFilterWhite}
            />
          </React.Fragment>
          :
          <S.ButtonBack
            type="primary"
            value={intl.get('master-data.general.actions.back')}
            className="btn-cancel"
            onClick={() => validClickBack(haveUnsavedChanges, handleOpenModal, handleDiscardChanges)}
            icon={ArrowBackIcn}
          />
        }
      </S.Content>
      <ModalDiscarChanges
        open={isOpenModalDiscardChanges}
        onClose={handleCloseModal}
        discardChanges={handleDiscardChanges}
        message={intl.get('autoLoadOptimization.feedbacks.discardChangesConfirm')}
      />
    </S.Wrapper>
  )
}

FilterAutoLoadOptimization.propTypes = {
  discardChanges: PropTypes.func.isRequired,
  handleGetVolumes: PropTypes.func.isRequired,
  haveData: PropTypes.any.isRequired,
  processError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
}

export default FilterAutoLoadOptimization;
