import moment from "moment"
import { getCurrentStockForOrdersOrSuggestions } from "components/table-list/utils"
import { getStage } from "./components/order-volume-list-stage"
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { userFornecedor } from 'services/permissions/getPermissions'
import { StatusVolume } from "../../models/volumes/status";

export const buildCard = (item) => ({
  Tipo: { Titulo: item.NegotationTypeStatusDescription, Etapa: 0, Prioridade: 1, Alerta: item.Alert },
  Material: {
    Nome: `${formatWithoutLeadingZeros(item.MaterialNumber)} - ${item.MaterialName}`,
    Estoque: {
      Prioridade: item.StockUnitPriority,
      Suficiencia: moment(item.StockUnitSufficiency).format("L"),
      QuantidadeAtual: item.StockUnitCurrentQuantity,
      UnidadeMedida: item.MaterialUnitOfMeasure
    }
  },
  Fabrica: {
    Codigo: item.BusinessUnitPlant,
    Nome: item.BusinessUnitName
  },
  Fornecedor: item.SupplierName,
  Status: item.VolumeOrderStatusDescription,
  StatusInt: item.VolumeOrderStatus,
  IdFamiliaRotuladaCentro: item.LabeledFamilyCenterId,
  Id: item.Id,
  Negociacao: {
    DataEntregaPropostaFormatada: moment(item.VolumeOrderDeliveryDate).format("L"),
    DataColetaPropostaFormatada: moment(item.VolumeOrderCollectDate).format("L"),
    QuantidadeProposta: item.VolumeOrderQuantity
  },
  PedidoVolume: true
})

export const buildListItem = (item) => ({
  ...item,
  Stage: getStage(item.VolumeOrderStatusDescription, item.Alert),
  VolumeOrderStatusDescription: item.NegotationTypeStatusDescription,
  VolumeOrderCollectDate: item.VolumeOrderCollectDate ? moment(item.VolumeOrderCollectDate).format('l') : '-',
  VolumeOrderDeliveryDate: item.VolumeOrderCollectDate ? moment(item.VolumeOrderDeliveryDate).format('l') : '-',
  BusinessUnit: `${item.BusinessUnitPlant} - ${item.BusinessUnitName}`,
  StockUnitCurrent: getCurrentStockForOrdersOrSuggestions(
    item.StockUnitPriority,
    item.StockUnitCurrentQuantity, item.MaterialUnitOfMeasure),
  Material: `${formatWithoutLeadingZeros(item.MaterialNumber)} - ${item.MaterialName}`,
  UrlRedirect: (userFornecedor() || item.VolumeOrderStatus == StatusVolume.VolumeRecusado) ? `/ordersVisionDetail/${item.Id}/V` :
    `/stocksDetail/${item.LabeledFamilyCenterId}/${item.Id}/V`,
  Quantity: formatNumber(item.VolumeOrderQuantity)
})

export const getVolumesLabeledFamilyCenterIds = (volumeOrdersIds, volumeOrders) => {
  let _labeledFamilyCenterIds = [];

  for(const volume of volumeOrders){
    if(volumeOrdersIds.includes(volume.Id) && !_labeledFamilyCenterIds.includes(volume.LabeledFamilyCenterId)){
      _labeledFamilyCenterIds.push(volume.LabeledFamilyCenterId);
    }
  }

  return _labeledFamilyCenterIds;
}
