import PropTypes from "prop-types";
import React, { useState } from "react";
import IconStop from "components/icons/stop.icon";
import intl from "react-intl-universal";
import ComposicaoLista from "../../list";
import { CheckCircle } from "components/icons/check-circle.icon";
import { CompositionsAcceptModal } from "../modal-accept/modal-accept";
import { CompositionsCancelModal } from "../modal-cancel/modal-cancel";
import * as S from "./styled";

const Table = ({
  data,
  handleEdit,
  refreshSearch,
  page,
  take,
  totalComposicoes,
}) => {
  const [filter, setFilter] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const getData = () =>
    data.filter(
      (x) =>
        !filter ||
        x.OrdersSap.some((y) =>
          y.toLowerCase().includes(filter.toLowerCase())
        ) ||
        String(x.LoadCompositionId).includes(filter.toLowerCase())
    );

  const disableButtons = () => selectedItems?.length === 0;

  return (
    <React.Fragment>
      <S.WrapperFilter>
        <S.Input
          className="input"
          label={intl.get("filters.search")}
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          data-testid="filter-table-composicao"
        />
        <S.WrappedButtons>
          <S.Buttons
            buttonIcon={<CheckCircle />}
            className="btnAccept"
            width="166px"
            type="secondary"
            buttonText={intl.get("transports.compoCharge.actions.acceptCompositions")}
            disabled={disableButtons()}
            onClick={() => setOpenAcceptModal(!openAcceptModal)}
          />
          <S.Buttons
            buttonIcon={<IconStop />}
            className="btnCancel"
            width="166px"
            type="secondary"
            disabled={disableButtons()}
            buttonText={intl.get("transports.compoCharge.actions.cancelCompositions")}
            onClick={() => setOpenCancelModal(!openCancelModal)}
          />
          {openAcceptModal && <CompositionsAcceptModal
            open={openAcceptModal}
            close={() => setOpenAcceptModal(!openAcceptModal)}
            data={selectedItems}
            clearData={setSelectedItems}
            refreshSearch={refreshSearch}
          /> }
          {openCancelModal && <CompositionsCancelModal
            open={openCancelModal}
            close={() => setOpenCancelModal(!openCancelModal)}
            data={selectedItems}
            clearData={setSelectedItems}
            refreshSearch={refreshSearch}
          />}
        </S.WrappedButtons>
      </S.WrapperFilter>
      <div className="body-composicao-carga">
        <ComposicaoLista
          data={getData()}
          handleEdit={handleEdit}
          refreshSearch={refreshSearch}
          page={page}
          take={take}
          totalComposicoes={totalComposicoes}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </div>
    </React.Fragment>
  );
};

Table.propTypes = {
  data: PropTypes.shape({
    filter: PropTypes.func,
  }).isRequired,
  handleEdit: PropTypes.any.isRequired,
  page: PropTypes.any.isRequired,
  refreshSearch: PropTypes.any.isRequired,
  take: PropTypes.any.isRequired,
  totalComposicoes: PropTypes.any.isRequired,
};

export default Table;
