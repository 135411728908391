import styled from 'styled-components'
import { Input as InputComponent, Button} from 'components/uikit-adapter/index'

export const WrapperFilter = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`
export const WrappedButtons = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Input = styled(InputComponent)`
  width: 268px;
`

export const Buttons = styled(Button)`
  margin-left: 20px;
`
