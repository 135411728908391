import { StatusPedido } from "../../../../../models/pedidos/statusPedido";
import { StatusVolume } from "../../../../../models/volumes/status";

export const generateUrlAmbevCard = (order) =>
  order.PedidoVolume ? generateUrlStockDetail(order) : getUrlForOrder(order);

const getUrlForOrder = (order) =>
  orderStatusRedirectForOrdersVisionPage.includes(order.StatusInt) ?
    generateUrlOrdersVision(order) : generateUrlStockDetail(order);

const generateUrlOrdersVision = (order) => `/ordersVisionDetail/${order.Id}/P`;
const generateUrlOrdersVisionVolume = (order) => `/ordersVisionDetail/${order.Id}/V`;
const generateUrlStockDetail = (order) => 
  (order.PedidoVolume && order.StatusInt == StatusVolume.VolumeRecusado) ? generateUrlOrdersVisionVolume(order) :
  `/stocksDetail/${order.IdFamiliaRotuladaCentro}/${order.Id}/${order.Sugestao ? 'S' : getInitialsOrderType(order)}`;

const getInitialsOrderType = (order) => order.PedidoVolume ? 'V' : 'P'

const orderStatusRedirectForOrdersVisionPage = [
  StatusPedido.Cancelado,
  StatusPedido.Finalizado,
  StatusPedido.AguardandoEntradaFatura,
  StatusPedido.AguardandoAceitacaoPagamento,
  StatusPedido.AguardandoConfirmacaoPagamento
]
