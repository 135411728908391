import { MassArrivalFilters } from "./components/Filter"
import { MassArrivalTable } from "./components/Table"
import { Wrapper } from "./massArrivalStyled"

function MassArrival() {
  return (
    <Wrapper>
      <MassArrivalFilters />
      <MassArrivalTable />
    </Wrapper>
  )
}

export { MassArrival }