import { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { withRouter } from 'react-router-dom'
import {
  Textarea,
  Input
} from '@hbsis.uikit/react'
import { SelectBox, Button } from 'components/uikit-adapter/index'
import DeadlineControl from 'components/deadline-control'
import OptionGroup from 'components/option-group'
import intl from 'react-intl-universal';
import StyledSuggestionsListItem from './suggestions-list-item.styled';
import Session from "utils/user-storage";

import './suggestions-list-item.css'
import { validationMessage } from './utils'
import { formatNumber } from 'utils/format'
import InputNumber from 'components/input-number'
import { getTextAreaStyle } from 'components/suggestions-list/suggestions-list-item/suggestions-list-item.styled'
import IconSaveBlue from 'components/icons/icn-save-blue.icon'
import { IconTrash } from 'components/icons/icn-index.icon'
import { Incoterms } from 'models/incoterm/incoterms';
import { useRequirementCode } from 'utils/validations-general-settings'

const ORIGEM_XADREZ = { value: 1 };
const ORIGEM_UNIDADES = { value: 2 };
const TIPO_UNIDADE_FORNECEDORA = { value: 2 };

class SuggestionsListItem extends Component {
  constructor(props) {
    const Usuario = Session.get();
    super(props)

    this.state = {
      checked: false,
      optionsTypesSuppliers: {
        id: 0,
        optionOne: {
          name: intl.get('stocks.suggestionsListItem.chess'),
          value: 1,
          isChecked: true,
          hideOption: Usuario.TipoUsuario != 1
        },
        optionTwo: {
          name: intl.get('stocks.suggestionsListItem.transferUnits'),
          value: 2,
          isChecked: false,
          hideOption: Usuario.TipoUsuario != 1
        }
      },
      unidades: [],
      fornecedores: [],
      origem: 1,
      transitTime: 0,
      options: Incoterms.filter(x => x.Key !== 0),
      tipoUnidade: 0,
      origemUnidades: { value: 2 }
    }
  }

  componentDidMount() {
    const { item, isOrder } = this.props;

    if (!isOrder) {
      this.loadSuppliersListAndOrigin(item.Origem, item.IdMaterial, item);
    }
    this.setState({
      transitTime: item.TransitTime
    });
  }

  componentDidUpdate(prevProps) {
    const { item, saveMethodOk } = this.props;
    if (this.props.callSaveMethod && !prevProps.callSaveMethod) {
      saveMethodOk();
      this.salvarSugestao(item);
    }
  }

  changeMaterial = (prop, idMaterial, item) => {
    this.loadSuppliersListAndOrigin(this.state.origem, idMaterial, item)
      .then(() => {
        item.IdMaterial = idMaterial

        this.props.changeValueSugestao(prop, idMaterial, item)
        this.changeFornecedor(this.state.fornecedores[0].Id, item)
      });
  }

  changeValueDeadline = (coleta, entrega, item) => {
    this.setState({
      transitTime: item.TransitTime
    })
    this.props.changeValueDeadline(coleta, entrega, item)
  }

  selectOptionTypeSuppliers = (origem, idMaterial, item) => {
    this.loadSuppliersListAndOrigin(origem, idMaterial, item)
      .then(() => {
        this.changeFornecedor(this.state.fornecedores[0].Id, item)
      });
  }

  loadSuppliersListAndOrigin = (origem, idMaterial, item) => {
    return new Promise(resolve => {
      const { optionsTypesSuppliers } = this.state;
      const material = item.Materiais.find(m => m.Id == idMaterial);

      const _optionsTypesSuppliers = optionsTypesSuppliers
      _optionsTypesSuppliers.id = item.Id;

      let fornecedores = [];
      if (origem == ORIGEM_UNIDADES.value) {
        fornecedores = material.Fornecedores.filter(f => f.Origem == ORIGEM_UNIDADES.value)
        _optionsTypesSuppliers.optionOne.isChecked = false;
        _optionsTypesSuppliers.optionTwo.isChecked = true;
      } else {
        fornecedores = material.Fornecedores.filter(f => f.Origem == ORIGEM_XADREZ.value)
        _optionsTypesSuppliers.optionOne.isChecked = true;
        _optionsTypesSuppliers.optionTwo.isChecked = false;
      }

      this.setState({
        fornecedores,
        origem,
        optionsTypesSuppliers: _optionsTypesSuppliers
      }, () => resolve())
    })
  }

  salvarSugestao(item) {
    const { isOrder, handleFeedback, salvarSugestao } = this.props
    const { origem, origemUnidades } = this.state
    const validation = validationMessage(isOrder, item, origem, origemUnidades);

    if (validation) {
      handleFeedback(validation);
    } else {
      salvarSugestao(item)
    }
  }

  footButton = (item) => {
    const { isOrder, handleClose, excluirSugestao, noFooter } = this.props;

    if (isOrder && !noFooter) {
      return (
        <Row>
          <Col xs={12} className="table-list-footer">
            <div title='Fechar'>
              <Button
                width="150px"
                value={intl.get(`geral.buttonsDefault.close`)}
                type="secondary"
                className="msof-button"
                onClick={handleClose}
              />
            </div>
            <div title='Salvar Pedido'>
              <Button
                width="150px"
                value={intl.get(`geral.buttonsDefault.save`)}
                type="primary"
                className="msof-button"
                onClick={() => this.salvarSugestao(item)}
              />
            </div>
          </Col>
        </Row>
      )
    }
    else if (!noFooter) {
      return (
        <Row>
          <Col xs={12} className="table-list-footer">
            <div title='Excluir Sugestão' className="msof-button">
              <Button
                buttonIcon={<IconTrash color={'var(--color-contrast-white)'} />}
                width="50px"
                type="danger"
                onClick={() => excluirSugestao(item)}
              />
            </div>
            <div title='Salvar Sugestão' className="msof-button">
              <Button
                buttonIcon={<IconSaveBlue />}
                width="50px"
                type="secondary"
                onClick={() => this.salvarSugestao(item)}
              />
            </div>
          </Col>
        </Row>
      )
    }
  }

  changeFornecedor = (idFornecedor, item) => {
    const { fornecedores } = this.state;
    const fornecedor = fornecedores.find(f => f.Id == idFornecedor);
    item.IdFornecedor = idFornecedor;
    this.props.changeValueSugestao('IdFornecedor', item.IdFornecedor, item);
    this.props.changeValueSugestao('ResponsavelEntrega', fornecedor?.ResponsavelEntrega, item);

    this.setState({
      tipoUnidade: fornecedor?.TipoUnidade
    })
  }

  onKeyPress = (event, item) => {
    if (event.key == "Enter") {
      this.salvarSugestao(item)
    }
  }

  getColumnSize = () => useRequirementCode() ? 6 : 12;

  showRequirementCodeInput = () => useRequirementCode();

  render() {
    const {
      fornecedores,
      transitTime,
      optionsTypesSuppliers,
      origem,
      options,
      tipoUnidade
    } = this.state;

    const {
      item,
      isOrder,
      changeValueSugestao
    } = this.props

    return (
      <StyledSuggestionsListItem >
        <div className="table-list-item-body">

          {isOrder &&
            <Row>
              <Col xs={12}>
                <SelectBox
                  name="unidade"
                  label={intl.get(`manual-suggestion-order-form.destination`)}
                  placeholder={intl.get(`manual-suggestion-order-form.destination`)}
                  value={item.IdUnidadeNegocio}
                  options={item.Unidades}
                  onChange={value => changeValueSugestao('IdUnidadeNegocio', value.Id, item)}
                  valueKey="Id"
                  labelKey="Nome"
                  searchable
                  openOnFocus
                  className='select-destination'
                />
              </Col>
            </Row>
          }

          {isOrder &&
            <Row>
              <Col xs={12}>
                <SelectBox
                  name="FamiliasRotuladas"
                  label={intl.get(`manual-suggestion-order-form.labeled-family`)}
                  placeholder={intl.get(`manual-suggestion-order-form.labeled-family`)}
                  value={item.IdFamiliaRotulada}
                  options={item.FamiliasRotuladas}
                  onChange={value => changeValueSugestao('IdFamiliaRotulada', value.Id, item)}
                  valueKey="Id"
                  labelKey="Descricao"
                  searchable
                  openOnFocus
                  disabled={!item.IdUnidadeNegocio}
                  className='select-labeled-family'
                />
              </Col>
            </Row>
          }

          <Row>
            <Col xs={12}>
              <SelectBox
                name="material"
                label={intl.get(`manual-suggestion-order-form.material`)}
                placeholder={intl.get(`manual-suggestion-order-form.material`)}
                value={item.IdMaterial}
                options={item.Materiais}
                onChange={value => this.changeMaterial("IdMaterial", value.Id, item)}
                valueKey="Id"
                labelKey="MaterialPesquisa"
                searchable
                openOnFocus
                disabled={isOrder && !item.IdFamiliaRotulada}
                className='select-material'
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <OptionGroup
                title={intl.get(`manual-suggestion-order-form.origin`)}
                handleSelect={event => this.selectOptionTypeSuppliers(event.currentTarget.value, item.IdMaterial, item)}
                options={optionsTypesSuppliers}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SelectBox
                name="fornecedor"
                label=""
                placeholder={intl.get(`manual-suggestion-order-form.origin`)}
                value={item.IdFornecedor}
                options={fornecedores}
                onChange={value => this.changeFornecedor(value.Id, item)}
                valueKey="Id"
                labelKey="Descricao"
                searchable
                openOnFocus
                disabled={isOrder && !item.IdMaterial}
                className='select-fornecedor'
              />
            </Col>
          </Row>

          <DeadlineControl
            TransitTime={item.TransitTime}
            OnChange={(coleta, entrega) => this.changeValueDeadline(coleta, entrega, item)}
            Coleta={item.Coleta}
            DataEntregaDe={item.DataEntregaDe}
          />
          {(item.TransitTime != transitTime) &&
            <Row>
              <Col>
                <label className="transit-time-date-warn">
                  {intl.get(`manual-suggestion-order-form.messageTransitTimeChange`)}
                </label>
              </Col>
            </Row>
          }

          <Row>
            <Col xs={4}>
              <Input
                className='input'
                label={intl.get(`manual-suggestion-order-form.loteSize`)}
                name="tamLote"
                htmlType="text"
                value={formatNumber(item.QuantidadeLote)}
                disabled
              />
            </Col>
            <Col xs={4}>
              <InputNumber
                type='default'
                label={intl.get(`manual-suggestion-order-form.amount`)}
                name="quantidade"
                value={item.Quantidade}
                onChange={_value => changeValueSugestao("Quantidade", _value, item)}
                onKeyPress={event => this.onKeyPress(event, item)}
                htmlType="number"
                data-testid='input-qnt-suggestion'
              />
            </Col>
            <Col xs={4}>
              <Input
                className='input'
                label={intl.get(`manual-suggestion-order-form.total`)}
                name="total"
                value={formatNumber(item.Total)}
                disabled
              />
            </Col>
          </Row>

          <Row>
            <Col xs={this.getColumnSize()}>
              <SelectBox
                required
                label='Incoterm'
                placeholder='Incoterm'
                name='Incoterm'
                value={item.ResponsavelEntrega}
                valueKey='Key'
                labelKey='Value'
                onChange={value => changeValueSugestao('ResponsavelEntrega', value.Key)}
                openOnFocus={true}
                options={options}
                width="100%"
                disabled={!item.IdFornecedor || origem != ORIGEM_XADREZ.value || tipoUnidade == TIPO_UNIDADE_FORNECEDORA.value}
                className='select-responsavel-entrega'
              />
            </Col>
            {this.showRequirementCodeInput() && 
              <Col xs={6}>
                <Input
                  className='input'
                  label={intl.get('orders.requirementCode')}
                  name="requirementCode"
                  value={item.RequirementCode}
                  onChange={event => changeValueSugestao('RequirementCode', event.target.value)}
                  data-testid="RequirementCode"
                />
              </Col>
            }
          </Row>

          {origem != ORIGEM_XADREZ.value &&
            <div>
              <label className="label-style">
                {intl.get(`manual-suggestion-order-form.titleUnitTransferInformation`)}
              </label>
              <div className="container">
                <Row>
                  <Col xs={4}>
                    <Input
                      className='input'
                      label={intl.get(`manual-suggestion-order-form.purchasingOrganization`)}
                      name="organizacaoCompras"
                      htmlType="text"
                      value={origem == ORIGEM_UNIDADES.value ? item.OrganizacaoCompras : ""}
                      onChange={event => changeValueSugestao("OrganizacaoCompras", event.target.value, item)}
                      maxLength={4}
                    />
                  </Col>
                  <Col xs={4}>
                    <Input
                      className='input'
                      label={intl.get(`manual-suggestion-order-form.buyerGroup`)}
                      name="grupoCompradores"
                      value={origem == ORIGEM_UNIDADES.value ? item.GrupoCompradores : ""}
                      onChange={event => changeValueSugestao("GrupoCompradores", event.target.value, item)}
                      maxLength={3}
                    />
                  </Col>
                  <Col xs={4}>
                    <Input
                      className='input'
                      label={intl.get(`manual-suggestion-order-form.taxCode`)}
                      name="codigoImposto"
                      value={origem == ORIGEM_UNIDADES.value ? item.CodigoImposto : ""}
                      onChange={event => changeValueSugestao("CodigoImposto", event.target.value, item)}
                      maxLength={5} />
                  </Col>
                </Row>
              </div>
            </div>
          }
          <Row>
            <Col xs={12}>
              <Textarea
                style={getTextAreaStyle()}
                label={intl.get(`manual-suggestion-order-form.observation`)}
                name="observacao"
                value={item.Observacao || ""}
                onChange={event => changeValueSugestao("Observacao", event.target.value, item)}
              />
            </Col>
          </Row>

          <div className="table-list-footer">
            {this.footButton(item)}
          </div>
        </div>
      </StyledSuggestionsListItem >
    );
  }
}

SuggestionsListItem.propTypes = {
  unidades: PropTypes.object,
  item: PropTypes.object.isRequired,
  isOrder: PropTypes.bool.isRequired,
  noFooter: PropTypes.bool,
  salvarSugestao: PropTypes.func,
  excluirSugestao: PropTypes.func,
  handleClose: PropTypes.func,
  changeValueSugestao: PropTypes.func,
  handleFeedback: PropTypes.func,
  saveMethodOk: PropTypes.func,
  callSaveMethod: PropTypes.bool,
  changeValueDeadline: PropTypes.func
}

export default withRouter(SuggestionsListItem)

