import { Wrapper } from "./styled"
import intl from "react-intl-universal"
import { useMassArrivalContext } from "pages/mass-arrival/context/massArrivalContext"
import moment from "moment";
import { ISO_DATE_FORMAT } from "utils/format-date";
import { SelectBox, Button } from "components/uikit-adapter/index"
import DateRangerPicker from "components/date-range-picker";

function MassArrivalFilters() {
  const {
    filter,
    onChangeFilter,
    portsOptions,
    companyCodeOptions,
    clearMassArrivalsFilter,
    searchMassArrivals
  } = useMassArrivalContext();
  return (
    <Wrapper>
      <DateRangerPicker
        title={intl.get('commons.colDate')}
        dataDe={filter._minMassArrivalDate}
        dataAte={filter._maxMassArrivalDate}
        dataMin={filter._maxMassArrivalDate ? moment(filter._maxMassArrivalDate).add(-3, 'M').format(ISO_DATE_FORMAT) : undefined}
        maxDate={filter._minMassArrivalDate ? moment(filter._minMassArrivalDate).add(3, 'M').format(ISO_DATE_FORMAT) : undefined}
        handleChange={(prop: string, value: string) =>
          onChangeFilter(prop === "dataDe" ? "_minMassArrivalDate" : "_maxMassArrivalDate", value)
        }
      />
      <div style={{ width: '200px' }}>
        <SelectBox
          name='port-of-discharge'
          label={intl.get('commons.portOfDischarge')}
          placeholder={intl.get('commons.portOfDischarge')}
          valueKey='Key'
          labelKey='Label'
          value={portsOptions?.find(x => x.Key == filter.PortOfDischargeIds) ?? []}
          options={portsOptions}
          onChange={(value: { Key: string }) => onChangeFilter("PortOfDischargeIds", value?.Key)}
          clearable
        />
      </div>
      <div style={{ width: '200px' }}>
        <SelectBox
          name='company-code'
          label={intl.get('commons.companyCode')}
          placeholder={intl.get('commons.companyCode')}
          valueKey='Key'
          labelKey='Label'
          value={companyCodeOptions?.find(x => x.Key == filter.CompanyCodes) ?? []}
          options={companyCodeOptions}
          onChange={(value: { Key: string }) => onChangeFilter("CompanyCodes", value?.Key)}
          clearable
        />
      </div>
      <Button
        buttonIcon={null}
        buttonText={null}
        width="90px"
        value={intl.get('filters.cleanFilter')}
        onClick={clearMassArrivalsFilter}
        className="btn"
      />
      <Button
        buttonIcon={null}
        buttonText={null}
        width="90px"
        type='primary'
        disabled={!filter._minMassArrivalDate || !filter._maxMassArrivalDate}
        value={intl.get('filters.generalFilter')}
        onClick={searchMassArrivals}
        className="btn"
      />
    </Wrapper>
  )
}

export { MassArrivalFilters }