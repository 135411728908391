import PropTypes from "prop-types";
import intl from "react-intl-universal";
import Dialog from "material-ui/Dialog";
import { useGlobalContext } from "hooks/useGlobalContext";
import { Button } from "components/uikit-adapter/index";
import { useEffect } from "react";
import './modal-accept.css';

export const CompositionsAcceptModal = ({ open, close, data, clearData, refreshSearch }) => {
  const { startOperation } = useGlobalContext();

  const handleConfirm = async () => {
    let requests = data.map((item) => {
      let _body = {
        IdComposicaoCarga: item,
      };

      return {
        API: `composicaocarga/aceite/${item}`,
        method: "post",
        body: _body,
        errorId: item,
      };
    });

    requests = requests.filter((item) => item);
    const text = intl.get("orders.operationText01");

    startOperation(requests, text, refreshSearch);
    clearData([]);
    close();
  };

  useEffect(() => {
  }, [refreshSearch]);

  return (
      <Dialog
        title={
          <div data-testid="warning-create-compositions">
            {intl.get("commons.warning")}
          </div>
        }
        contentStyle={{ width: "560px"}}
        open={open}
        onRequestClose={close}
        actionsContainerClassName="container-action-buttons__acceptModal"
        actions={[
          <Button
            key="cancel"
            value={intl.get("commons.cancel")}
            id="btn-cancel-modal-alert-accept-compositions"
            type="default"
            onClick={close}
            className="container-action-buttons__acceptModal--buttons"
          />,
          <Button
            key="confirm"
            value={intl.get("commons.confirm")}
            id="btn-confirm-modal-alert-accept-compositions"
            type="danger"
            onClick={handleConfirm}
            className="container-action-buttons__acceptModal--buttons"
          />,
        ]}
      >
        <div className="container-message">
          <p>
            {intl.get("transports.compoCharge.actions.modalAcceptCompositions")}
          </p>
        </div>
      </Dialog>
  );
};

CompositionsAcceptModal.propTypes = {
  close: PropTypes.func.isRequired,
  data: PropTypes.shape({
    filter: PropTypes.func,
    find: PropTypes.func,
    map: PropTypes.func,
  }).isRequired,
  open: PropTypes.any.isRequired,
  refreshSearch: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};
