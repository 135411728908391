import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'material-ui/Table'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import TableRownColumnUnclickable from 'components/table-row-column-unclickable'
import DeleteIcon from 'images/icn-trash-bin.svg'
import IconButton from 'material-ui/IconButton'
import intl from 'react-intl-universal'

const BudgetList = ({ data, countData, refreshSearch, handleEdit, handleDelete, qtRegsPerPage, profileAdminOrMasterData }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
    onCellClick={handleEdit}
  >
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}
    >
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn >{intl.get('master-data.general-configuration.name')}</TableHeaderColumn>
        <TableHeaderColumn />
      </TableRow>
    </TableHeader>
    <TableBody
      displayRowCheckbox={false}
      showRowHover
    >
      {data.sort((a, b) =>
        a.Description.localeCompare(b.Description)
      ).map((v) => (
        <TableRow key={v.Id} style={{ cursor: 'pointer' }}>
          <TableRowColumn>
            {v.Description}
          </TableRowColumn>
          <TableRownColumnUnclickable style={{ positon: 'relative', textAlign: 'right' }}>
          { profileAdminOrMasterData &&
            <div title={intl.get('master-data.walletsAndFamilys.wallets.deleteModalWallet.title')}>
              <IconButton onClick={() => handleDelete(v.Id)} >
                <img alt='delete-icon' src={DeleteIcon} />
              </IconButton>
            </div>
          }
          </TableRownColumnUnclickable>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <PaginationFooter
        countPerPage={qtRegsPerPage}
        countData={countData}
        refreshSearch={refreshSearch}
      />
    </TableFooter>
  </Table>
)

BudgetList.propTypes = {
  countData: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    CoordenacaoDescricao: PropTypes.string,
    Descricao: PropTypes.string
  })).isRequired
}

export default BudgetList
