
const getConfig = () => require('../config/config.json')

export function getEnvironment() {
  const _enviroment = process.env.REACT_APP_environment ?? getConfig().environment;
  return _enviroment;
}

export function getHost() {
  const _host = process.env.REACT_APP_host ?? getConfig().host;
  return _host;
}

export function getHostNPS() {
  const _hostNPS = process.env.REACT_APP_hostNPS ?? getConfig().hostNPS;
  return _hostNPS;
}

export function getGoogleAnalyticKey() {
  const _google_analytic_key = process.env.REACT_APP_google_analytic_key ?? getConfig().google_analytic_key;
  return _google_analytic_key;
}

export function getNPSId() {
  const _systemId = process.env.REACT_APP_systemId ?? getConfig().systemId;
  return _systemId;
}

export function getRedirectUri() {
  const _redirectUri = process.env.REACT_APP_redirectUri ?? getConfig().redirectUri;
  return _redirectUri;
}

export function getLogoutRedirectUri() {
  const _postLogoutRedirectUri = process.env.REACT_APP_postLogoutRedirectUri ?? getConfig().postLogoutRedirectUri
  return _postLogoutRedirectUri;
}

export function getAuthorityLoginPage() {
  const _authorityLoginPage = process.env.REACT_APP_authorityLoginPage ?? getConfig().authorityLoginPage;
  return _authorityLoginPage;
}

export function getAuthorityForgotPasswordPage() {
  const _authorityForgotPasswordPage = process.env.REACT_APP_authorityForgotPasswordPage ?? getConfig().authorityForgotPasswordPage;
  return _authorityForgotPasswordPage;
}

export function getClientId() {
  const _clientId = process.env.REACT_APP_clientId ?? getConfig().clientId;
  return _clientId;
}

export function getAuthScopes() {
  const _authScopesEnv = process.env.REACT_APP_authScopes?.split(",");
  const _authScopes =  _authScopesEnv ?? getConfig().authScopes;
  return _authScopes;
}

export function getFullHistoryId() {
  const _fullStoryId = process.env.REACT_APP_fullStoryId ?? getConfig().fullStoryId;
  return _fullStoryId;
}

export function getVersionName() {
  const _versionName = process.env.REACT_APP_versionName ?? getConfig().versionName;
  return _versionName;
}

export function getBuildDeployDate() {
  const _buildDeployDate = process.env.REACT_APP_buildDeployDate ?? getConfig().buildDeployDate;
  return _buildDeployDate;
}

export function getApimSubscriptionKey() {
  const _apimSubscriptionKey = process.env.REACT_APP_apimSubscriptionKey ?? getConfig().apimSubscriptionKey;
  return _apimSubscriptionKey;
}

export function getShowNPS() {
  const _showNPS = process.env.REACT_APP_showNPS ?? getConfig().showNPS;
  return  _showNPS === "true";
}

export function getShowNewNPS() {
  const _showNPS = process.env.REACT_APP_showNewNPS ?? getConfig().showNewNPS;
  return  _showNPS === "true";
}

export function getNPSSystemId() {
  const _npsSystemId = process.env.REACT_APP_npsSystemId ?? getConfig().npsSystemId;
  return  _npsSystemId;
}

export function getNPSSrc() {
  const _npsSrc = process.env.REACT_APP_npsSrc ?? getConfig().npsSrc;
  return  _npsSrc;
}

export function getAuthenticationProvider() {
  const _authenticationProvider = process.env.REACT_APP_authenticationProvider ?? getConfig().authenticationProvider;
  return _authenticationProvider;
}

export function getDataDogClientToken() {
  const _dataDogClientToken = process.env.REACT_APP_dataDogClientToken ?? getConfig().dataDogClientToken;
  return _dataDogClientToken;
}

export function getDataDogApplicationId() {
  const _dataDogApplicationId = process.env.REACT_APP_dataDogApplicationId ?? getConfig().dataDogApplicationId;
  return _dataDogApplicationId;
}

export function getSupportEmail() {
  const _supportEmail = process.env.REACT_APP_supportEmail ?? getConfig().supportEmail;
  return _supportEmail;
}

export function getDatadogServiceName() {
  const _datadogServiceName = process.env.REACT_APP_datadogServiceName ?? getConfig().datadogServiceName
  return _datadogServiceName;
}

export function getAuthorityKCAmbev() {
  const _authorityKCAmbev = process.env.REACT_APP_authorityKCAmbev ?? getConfig().authorityKCAmbev;
  return _authorityKCAmbev;
}

export function getAuthorityKCThird() {
  const _authorityKCThird = process.env.REACT_APP_authorityKCThird ?? getConfig().authorityKCThird;
  return _authorityKCThird;
}

export function getClientIdKc() {
  const _clientIdKc = process.env.REACT_APP_clientIdKc ?? getConfig().clientIdKc;
  return _clientIdKc;
}