import IconStop from "components/icons/stop.icon";
import { Button } from "components/uikit-adapter";
import { useToastContext } from "hooks/useToastContext";
import { useState } from "react";
import intl from "react-intl-universal";
import { formatErrorMessage } from "utils/handle-error";
import ModalConfirmCancel from "./modal-confirm-cancel";
import { getUserId } from "utils/user";
import { getVolumesLabeledFamilyCenterIds } from "features/order-volume/utils";
import { runStockProjection } from "services/labeled-family-center.service";
import { useGlobalContext } from "hooks/useGlobalContext";
import { useOrderVolumeContext } from "features/order-volume/context";

interface IProps {
  refresh: (newFilter?: any) => Promise<void>;
  volumeOrdersIds: number[];
  volumeOrders: any;
}

const OrderVolumeCancel = ({
  refresh,
  volumeOrdersIds,
  volumeOrders,
}: IProps) => {
  const { setLoading } = useOrderVolumeContext();
  const { startOperation } = useGlobalContext();
  const { openToast } = useToastContext();
  const isDisabled = volumeOrdersIds.length == 0;
  const [showModalCancel, setShowModalCancel] = useState(false);
  const userId = getUserId();

  const handleCancelVolumes = async () => {
    try {
      setLoading(true);
      const requests = volumeOrdersIds.map(x => ({
        API: `volume-orders/${x}:cancel`,
        method: 'post',
        body: { userId }
      }));
      const text = intl.get('orders.operationText02');
      startOperation(requests, text, handleCancelVolumesCompletion);
    } finally {
      setLoading(false);
      setShowModalCancel(false);
    }
  };

  const handleCancelVolumesCompletion = async () => {
    try {
      refresh();
      const labeledFamilyCenterIds = getVolumesLabeledFamilyCenterIds(volumeOrdersIds, volumeOrders);
      for(const labeledFamilyCenterId of labeledFamilyCenterIds){
        await runStockProjection(labeledFamilyCenterId);
      }
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative');
    }
  }

  return (
    <>
      <Button
        buttonIcon={<IconStop />}
        buttonText={intl.get("volumeOrders.cancelVolumes")}
        className="btnCancel"
        width="166px"
        type="secondary"
        disabled={isDisabled}
        onClick={() => setShowModalCancel(true)}
      />
      {showModalCancel && (
        <ModalConfirmCancel
          visible={showModalCancel}
          onConfirm={handleCancelVolumes}
          onCancel={() => setShowModalCancel(false)}
        />
      )}
    </>
  );
};

export default OrderVolumeCancel;
