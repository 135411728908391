import { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import moment from 'moment';
import { Grid } from '@hbsis.uikit/react';
import { Button } from 'components/uikit-adapter/index';
import TitleSecond from 'components/title-second';
import LastUpdate from 'components/last-update';
import IconFilterGray from 'images/icn-filter-gray.svg';
import IconArrow from 'images/arrow.svg'
import intl from 'react-intl-universal';
import DateInput from 'components/date-input';
import { Row, Col } from "react-flexbox-grid";
import { compareDates } from 'utils/date';
import { searchChartData } from '../../pages/stocks-detail/stocks-detail.service';
import { formatErrorMessage } from '../../utils/handle-error';
import Loading from "components/center-loading";
import { useGlobalContext } from 'hooks/useGlobalContext';
import * as S from './styled'
import { loadFilter, mountEchartData } from './utils';
import { DATA_FORMAT_TYPES, ISO_DATE_FORMAT, getDataFormat } from 'utils/format-date';
import { Chart } from 'components/chart';
import PropTypes from 'prop-types'

const ChartStocks = forwardRef(({
  lastUpdateDate,
  idFamiliaRotuladaCentro,
  hideLastUpdate,
  hideLoading,
  sendInfosToSecondChart,
  updateChart,
  useStorageLocations,
  selectedStorageLocationId
}, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filtroGrafico, setFiltroGrafico] = useState(loadFilter());
  const { showFeedback } = useGlobalContext();
  const [echart, setEchart] = useState(null)
  const [dateChart, setDateChart] = useState(filtroGrafico.dataDe)
  const [isHourlyPlan, setIsHourlyPlan] = useState(false)

  const changeValueFilter = (name, value) => {
    const dateGreaterThanToday = compareDates(value, filtroGrafico.dataDe)
    if (name === 'dataDe' && !value || !dateGreaterThanToday) {
      return
    }

    setFiltroGrafico(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFilter = () => {
    setDateChart(filtroGrafico.dataDe)
    localStorage.setItem("filterChart", JSON.stringify(filtroGrafico));
    loadChart();
  }

  const loadChart = async () => {
    setIsLoading(true);
    try {
      let model = {
        DataDe: filtroGrafico.dataDe,
        DataAte: filtroGrafico.dataAte,
        DataEspecifica: dateChart
      }

      if(useStorageLocations && selectedStorageLocationId) model = { ...model, BusinessUnitStorageLocationId: selectedStorageLocationId };

      const _chartData = await searchChartData(idFamiliaRotuladaCentro, model)
      setIsHourlyPlan(_chartData.IsHourlyPlan)
      setEchart(mountEchartData(_chartData, dateChart))
    }
    catch (e) {
      showFeedback(formatErrorMessage(e));
    }
    setIsLoading(false);
  }

  const changeDayFilterNext = () => {
    setDateChart(moment(dateChart).add(1, 'days').format(ISO_DATE_FORMAT))
  }

  const changeDayFilterPrevious = () => {
    setDateChart(moment(dateChart).subtract(1, 'days').format(ISO_DATE_FORMAT))
  }

  useEffect(() => {
    if (sendInfosToSecondChart) {
      sendInfosToSecondChart(filtroGrafico)
    }
  }, [filtroGrafico])

  useImperativeHandle(ref, () => ({
    async loadChart() {
      await loadChart();
    }
  }));

  useEffect(() => {
    loadChart()
  }, [updateChart, dateChart, selectedStorageLocationId])

  return (
    <S.Wrapper>
      <Grid fluid>
        <Row className="chart-title-row">
          <Col xs={4}>
            <TitleSecond>{intl.get('commons.stock')}</TitleSecond>
          </Col>
          <Col xs={8}>
            {!hideLastUpdate && <LastUpdate
              title={intl.get('stocks.lastUpdate')}
              dataText={moment(lastUpdateDate, 'DD/MM HH:mm').format('[DM] LT')}
            />
            }
          </Col>
        </Row>
        <Row bottom="xs row-filter">
          <Col xs={4}>
            <DateInput
              label={intl.get('stocks.from')}
              value={filtroGrafico.dataDe}
              minDate={moment().format(ISO_DATE_FORMAT)}
              onChange={(date) => changeValueFilter('dataDe', date)}
              maxDate="9999-12-31"
            />
          </Col>
          <Col xs={4}>
            <DateInput
              label={intl.get('stocks.until')}
              value={filtroGrafico.dataAte}
              onChange={(date) => changeValueFilter('dataAte', date)}
              minDate={moment().format(ISO_DATE_FORMAT)}
              maxDate="9999-12-31"
            />
          </Col>
          <Col xs={4}>
            <Button
              value={intl.get('filters.filter')}
              type='secondary'
              onClick={handleFilter}
              icon={IconFilterGray}
            />
          </Col>
        </Row>
        {isHourlyPlan && <S.SelectDatesContent >
          <S.SelectDatesButton
            className='select-date-button-previous'
            icon={IconArrow}
            disabled={dateChart <= filtroGrafico.dataDe}
            onClick={changeDayFilterPrevious}
          />
          {getDataFormat(dateChart, DATA_FORMAT_TYPES.WITH_YEAR)}
          <S.SelectDatesButton
            className='select-date-button-next'
            icon={IconArrow}
            disabled={dateChart >= filtroGrafico.dataAte}
            onClick={changeDayFilterNext}
          />
        </S.SelectDatesContent>
        }

        <Row>
          <Col xs={12}>
            <Chart
              echart={echart}
              style={{ bottom: '30px', height: '400px' }}
              notMerge={true}
              lazyUpdate={true}
            />
            <Loading
              isLoading={isLoading && !hideLoading}
              fullHeightParent
            />
          </Col>
        </Row>
      </Grid>
    </S.Wrapper>
  )
})

ChartStocks.propTypes ={
  lastUpdateDate: PropTypes.object,
  idFamiliaRotuladaCentro: PropTypes.number,
  hideLastUpdate: PropTypes.bool,
  hideLoading: PropTypes.bool,
  sendInfosToSecondChart: PropTypes.func,
  updateChart: PropTypes.bool,
  useStorageLocations: PropTypes.bool,
  selectedStorageLocationId: PropTypes.number
}

export default ChartStocks;
