import PropTypes from "prop-types";
import { State } from "react-powerplug";
import styled from "styled-components";

import TableLabel from "./TableLabel";
import TableRow from "./TableRow";

import { COLUMN_WIDTH } from "./constants";

const StyledTable = styled.div`
  * { box-sizing: border-box; }

  .etb-row-group:first-child {
    .etb-row-labels {
      .etb-values {
        .etb-column {
          border-top: none;
        }
      }
    }
  }
  .etb-column {
    display: flex;
    align-items: center;
    flex: 1 0 ${COLUMN_WIDTH}px;
    padding: 10px 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
`;

const handleExpandItem = (items, setState) => code => {
  if (items.includes(code)) {
    setState(prevState => ({
      expandedItems: prevState.expandedItems.filter(c => c !== code)
    }));
  } else {
    setState(prevState => ({
      expandedItems: [...prevState.expandedItems, code]
    }));
  }
};

const Table = ({
  data,
  labelKey,
  labelsList,
  childrenKey,
  onClickItem,
  onSelectItem,
  showCheckbox,
  resolverValue,
  renderCustomAlert,
  showShelfLifeImage,
  showDiscontinuedImage
}) => (
  <State initial={{ expandedItems: [] }}>
    {({ state, setState }) => (
      <StyledTable>
        {data.map((familia, index) => (
          <div key={index} className="etb-row-group">
            <TableLabel label={familia[labelKey]} labelsList={labelsList} />
            {familia[childrenKey].map((item, idx) => (
              <TableRow
                key={`${familia.Id}-${item.Id}`}
                code={`${familia.Id}-${item.Id}`}
                labelKey={labelKey}
                childrenKey={childrenKey}
                label={item[labelKey]}
                item={familia[childrenKey][idx]}
                labelsList={labelsList}
                expandedItems={state.expandedItems}
                resolverValue={resolverValue}
                showCheckbox={showCheckbox}
                showShelfLifeImage={showShelfLifeImage}
                showDiscontinuedImage={showDiscontinuedImage}
                renderCustomAlert={renderCustomAlert}
                onClick={onClickItem}
                onSelectItem={onSelectItem}
                onExpandItem={handleExpandItem(state.expandedItems, setState)}
              >
                {item[childrenKey]}
              </TableRow>
            ))}
          </div>
        ))}
      </StyledTable>
    )}
  </State>
);

Table.propTypes = {
  data: PropTypes.array.isRequired,
  labelKey: PropTypes.string,
  childrenKey: PropTypes.string,
  labelsList: PropTypes.array,
  expandedItems: PropTypes.array,
  resolverValue: PropTypes.func,
  showCheckbox: PropTypes.func,
  renderCustomAlert: PropTypes.func,
  onClickItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  showShelfLifeImage: PropTypes.func,
  showDiscontinuedImage: PropTypes.func
};

export default Table;
