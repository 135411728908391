import { Component } from 'react'
import PropTypes from 'prop-types'
import Loading from 'components/center-loading'
import { Row, Col } from 'react-flexbox-grid'
import { Button, SelectBox } from 'components/uikit-adapter/index'

import {
  searchWallets,
  searchCoordinations,
  searchUsers,
  searchUserTypes,
  searchAccessProfileTypes,
  searchEmails
} from './filtro-usuario.service'

import StyledFilter from './StyledFilter'
import intl from 'react-intl-universal'
import { Switcher } from 'components/toggle'

class FiltroUsuario extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      wallets: [],
      coordinations: [],
      users: [],
      userTypes: [],
      accessProfileTypes: [],
      emails: [],
      WalletId: null,
      CoordinationId: null,
      IdUsuario: null,
      UserType: null,
      UserAccessProfile: null,
      Email: null,
      OnlyDisabledUsers: false
    }

    this.processWalletsData = this.processWalletsData.bind(this)
    this.processCoordinationsData = this.processCoordinationsData.bind(this)
    this.processUsersData = this.processUsersData.bind(this)
    this.processUserTypesData = this.processUserTypesData.bind(this)
    this.processAccessProfileTypesData = this.processAccessProfileTypesData.bind(this)
    this.processEmailsData = this.processEmailsData.bind(this)

    this.proccessErro = this.proccessErro.bind(this)
    this.startFetching = this.startFetching.bind(this)
    this.stopFetching = this.stopFetching.bind(this)

    this.changeValue = this.changeValue.bind(this)
    this.handleCloseSearch = this.handleCloseSearch.bind(this)
    this.handleCleanClick = this.handleCleanClick.bind(this)
  }

  componentDidMount() {
    this.setState({
      initialData: this.props.initialData,
      WalletId: this.props.initialData.WalletId,
      CoordinationId: this.props.initialData.CoordinationId,
      UserId: this.props.initialData.UserId,
      UserType: this.props.initialData.UserType,
      UserAccessProfile: this.props.initialData.UserAccessProfile,
      Email: this.props.initialData.Email,
      OnlyDisabledUsers: this.props.initialData.OnlyDisabledUsers
    }, () => {
      this.searchWallets()
      this.searchCoordinations()
      this.searchUsers()
      this.searchUserTypes()
      this.searchAccessProfileTypes()
      this.searchEmails()
    })
  }

  proccessErro(exception) {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: intl.get('stocks.timeline.feedback.serverErrorFamily')
    })
  }

  searchWallets = async () => {
    try {
      this.startFetching()
      const data = await searchWallets()
      this.processWalletsData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processWalletsData(data) {
    this.setState({
      wallets: data
    })
  }

  searchCoordinations = async () => {
    try {
      this.startFetching()
      const data = await searchCoordinations()
      this.processCoordinationsData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processCoordinationsData(data) {
    this.setState({
      coordinations: data.filter(Boolean)
    })
  }

  searchUsers = async () => {
    try {
      this.startFetching()
      const data = await searchUsers()
      this.processUsersData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processUsersData(data) {
    this.setState({
      users: data
    })
  }

  searchUserTypes = async () => {
    try {
      this.startFetching()
      const data = await searchUserTypes()
      this.processUserTypesData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processUserTypesData(data) {
    this.setState({
      userTypes: data
    })
  }

  searchAccessProfileTypes = async () => {
    try {
      this.startFetching()
      const data = await searchAccessProfileTypes()
      this.processAccessProfileTypesData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processAccessProfileTypesData(data) {
    this.setState({
      accessProfileTypes: data
    })
  }

  searchEmails = async () => {
    try {
      this.startFetching()
      const data = await searchEmails()
      this.processEmailsData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processEmailsData(data) {
    this.setState({
      emails: data
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  startFetching() {
    this.setState({
      isFetching: true
    })
  }

  stopFetching() {
    this.setState({
      isFetching: false
    })
  }

  handleCleanClick() {
    const params = {
      WalletId: null,
      CoordinationId: null,
      UserId: null,
      UserType: null,
      UserAccessProfile: null,
      Email: null,
      OnlyDisabledUsers: false
    }
    this.setState(params, () => this.props.handleClose(params))
  }

  handleCloseSearch() {
    const params = {
      WalletId: this.state.WalletId,
      CoordinationId: this.state.CoordinationId,
      UserId: this.state.UserId,
      UserType: this.state.UserType,
      UserAccessProfile: this.state.UserAccessProfile,
      Email: this.state.Email,
      OnlyDisabledUsers: this.state.OnlyDisabledUsers
    }
    this.props.handleClose(params)
  }

  render() {
    const {
      wallets,
      coordinations,
      users,
      userTypes,
      accessProfileTypes,
      emails,
      WalletId,
      CoordinationId,
      UserId,
      UserType,
      UserAccessProfile,
      Email,
      OnlyDisabledUsers,
      isFetching
    } = this.state

    return (
      <StyledFilter>
        <Loading isLoading={isFetching} />
        <div className="filter-container">
          <div className="sideFilterFields">

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='user'
                  label={intl.get('commons.user')}
                  placeholder={intl.get('commons.user')}
                  valueKey="Id"
                  labelKey="IdentificacaoUsuario"
                  value={UserId}
                  onChange={value => this.changeValue('UserId', value === null ? null : value.Id)}
                  options={users}
                  className='select-usuario'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='email'
                  label={intl.get('commons.email')}
                  placeholder={intl.get('commons.email')}
                  valueKey="UserId"
                  labelKey="Email"
                  value={Email}
                  onChange={value => this.changeValue('Email', value)}
                  options={emails}
                  className='select-emails'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='wallet'
                  label={intl.get('commons.wallet')}
                  placeholder={intl.get('commons.wallet')}
                  valueKey="Id"
                  labelKey="Description"
                  value={WalletId}
                  onChange={value => this.changeValue('WalletId', value === null ? null : value.Id)}
                  options={wallets}
                  className='select-carteira'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='coordination'
                  label={intl.get('master-data.general-configuration.coordination')}
                  placeholder={intl.get('master-data.general-configuration.coordination')}
                  valueKey="Id"
                  labelKey="Description"
                  value={CoordinationId}
                  onChange={value => this.changeValue('CoordinationId', value === null ? null : value.Id)}
                  options={coordinations}
                  className='select-coordenacao'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='userType'
                  label={intl.get('commons.userType')}
                  placeholder={intl.get('commons.userType')}
                  valueKey="Id"
                  labelKey="Description"
                  value={UserType}
                  onChange={value => this.changeValue('UserType', value === null ? null : value.Id)}
                  options={userTypes}
                  className='select-user-type'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='accessProfileType'
                  label={intl.get('commons.accessProfile')}
                  placeholder={intl.get('commons.accessProfile')}
                  valueKey="Id"
                  labelKey="Description"
                  value={UserAccessProfile}
                  onChange={value => this.changeValue('UserAccessProfile', value === null ? null : value.Id)}
                  options={accessProfileTypes}
                  className='select-access-profile-types'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <Switcher
                  name="OnlyDisabledUsers"
                  label={intl.get('master-data.coordinationsAndUsers.users.actions.disabled')}
                  value={OnlyDisabledUsers}
                  onChange={value => this.changeValue('OnlyDisabledUsers', value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                  trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                  className='toogle-somente-desabilitados'
                />
              </Col>
            </Row>
          </div>

          <Row className="footerFilterContainer no-margin">
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className="filter-divisor" />
                </Col>
              </Row>
              <Row className="button-container">
                <Col xs={6} lg={6} md={6}>
                  <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.search')} className="btn-close-search" />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} className="btn-clean" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledFilter>
    )
  }
}

FiltroUsuario.propTypes = {
  initialData: PropTypes.object,
  transitionTime: PropTypes.number,
  handleClose: PropTypes.func,
}

FiltroUsuario.defaultProps = {
  transitionTime: 500,
  initialData: {}
}

export default FiltroUsuario

