import { getValueOrDefault } from "utils/custom-functions"
import { getDoorName } from "utils/doors";

export const mapperOrdersList = (data) => data.ListaPedidos ?
  data.ListaPedidos.map(x => ({ ...x, Destination: `${data.Centro} - ${data.UnidadeNegocio}` })) : []

export const mapperSuggestionsList = (data, documentTypes, doors) => data.ListaSugestoes ?
  data.ListaSugestoes.map(x => ({
    ...x,
    Destination: `${data.Centro} - ${data.UnidadeNegocio}`,
    DocumentType: x.DocumentType ? x.DocumentType : getDefaultDocumentType(documentTypes, x.Materiais, x.IdMaterial, x.IdFornecedor),
    PortaWMS: getDoorName(doors, x.PortaWMS),
    Periodicidade: data.Periodicidade
  })) : []

export const getDefaultDocumentType = (documentTypes, materiais, idMaterial, idFornecedor) => {
  if(documentTypes && materiais && idFornecedor){
    const material = materiais.find((x) => x.Id === idMaterial);
    const orderTypeCode = material.Fornecedores.find((y) => y.Id === idFornecedor)?.TipoFornecimentoValue;
    return getValueOrDefault(documentTypes.find((y) => y.Default && y.OrderType === orderTypeCode), { DocumentTypeEnumValue: null }).DocumentTypeEnumValue
  }
  return null;
}

export const getDocumentTypeOptions = (documentTypes, materiais, idMaterial, idFornecedor) => {
  if(documentTypes && materiais && idFornecedor){
    const material = materiais.find((x) => x.Id === idMaterial);
    const orderTypeCode = material.Fornecedores.find((y) => y.Id === idFornecedor)?.TipoFornecimentoValue;
    return documentTypes.filter((y) => y.OrderType === orderTypeCode);
  }
  return [];
}

export const mapperStorageLocations = (data) => {
  if(!data) return [];

  return data.map(x => ({
    Id: x.BusinessUnitStorageLocationId,
    Description: x.BusinessUnitStorageLocationDescription
  }))
}

export const mapperStorageLocationsProductionLines = (data) => {
  if(!data) return [];

  const _productionLines = data.flatMap(x => 
    x.LabeledFamilyCenterStorageLocationProductionLines.map(y => ({
      Id: y.BusinessUnitProductionLineId,
      Description: y.BusinessUnitProductionLineDescription,
      StorageLocationId: x.BusinessUnitStorageLocationId
    }))
  )

  return _productionLines;
}

export const getProductionLinesOptions = (productionLines, useStorageLocations, storageLocationsProductionLines, selectedStorageLocationId) => {
  if(!useStorageLocations) return productionLines;
  else if(useStorageLocations && storageLocationsProductionLines.length == 0) return [];
  else {
    const _options = storageLocationsProductionLines.filter(x => x.StorageLocationId == selectedStorageLocationId);
    return _options.map(y => ({
      key: y.Id,
      productionLine: y.Description
    }))
  }
}

export const mountExternalWarehouseBreweryChartsFilters = (filters, useStorageLocations, selectedStorageLocationId) => {
  let _body = { ... filters };
  if(useStorageLocations && selectedStorageLocationId) _body = { ..._body, BusinessUnitStorageLocationId: selectedStorageLocationId };

  return _body;
}