import styled from 'styled-components'

export const HeaderContainer = styled.div`
  width: 100%;
  height: 55px;
  background-color: var(--color-brand-300);
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  ${props => props.noBackground && 'background-color: inherit;'};
  
  .icone {
    width: 24px;
    height: 24px;
    display: flex;
    margin-right: 10px;
    cursor: pointer;
  }
`

export const H3 = styled.h3`

`


