import React, { useEffect, useState } from "react"
import intl from "react-intl-universal"
import Header from 'components/header'
import Filter from "./components/filter"
import { errorMessage } from "./utils/format"
import noDataImage from 'images/no-data.svg'
import * as S from "./styled"
import {
  fetchAdvancedPackagingMaterialSchedule
} from './primary-material-schedule.service'
import Loading from "components/center-loading";
import ListZAPMSL from "./components/list"
import { validData, validSelectAll } from "./utils/validate"
import { Checkbox } from "@hbsis.uikit/react";
import HeaderCommands from "./components/headerCommands"
import { useZapmslContext } from "./context/zapmslContext"
import { useGlobalContext } from 'hooks/useGlobalContext';
import BtnEditLine from "./components/list/header/components/btnEditLine"

const PrimaryMaterialScheduleList = () => {
  const [dataList, setDataList] = useState()
  const { showFeedback } = useGlobalContext()
  const {
    selectedLines,
    setSelectedLines,
    loading,
    setLoading,
    setFilters,
    filters,
    zapmslData,
    setZapmslData,
    setNewLineInProgress,
    setMaterials
  } = useZapmslContext();

  const processError = e => {
    showFeedback(errorMessage(e))
  }

  const normalizeDataList = (volumesData) => (
    volumesData.map((row, index) => (
      {
        checkBox: () => CheckBoxRow(index),
        ...row,
        edit: () => normalizeEditRow(index, row)
      }
    ))
  )

  const normalizeEditRow = (index, row) => (
    row.edit ? row.edit() :
      <BtnEditLine idx={index} row={row} />
  )

  const onChangeCheckBox = (lineId, checked) => {
    if (checked) {
      setSelectedLines([...selectedLines, lineId]);
    }
    else {
      setSelectedLines([...selectedLines.filter(value => value !== lineId)]);
    }
    setZapmslData([...zapmslData]);
  }

  const CheckBoxRow = (lineId) => {
    return (
      <span className='checkbox'>
        <Checkbox
          key={lineId}
          name={`checkbox-${lineId}`}
          checked={selectedLines.some(id => id === lineId)}
          onChange={(e) => onChangeCheckBox(lineId, e.target.checked)}
          data-testid={`checkbox-id-${lineId}`}
          className='checkbox'
          style={{ cursor: "pointer" }}
        />
      </span>
    )
  };

  const discardChanges = () => {
    setZapmslData([]);
    setDataList(undefined);
    setSelectedLines([]);
    setFilters({})
    setNewLineInProgress(false)
  }

  const updateDataList = () => {
    if (validData(zapmslData)) {
      const obj = {
        data: normalizeDataList(zapmslData),
        totalCount: zapmslData.length,
        totalPages: 1
      };
      setDataList({ ...obj });
    }
  }

  useEffect(() => {
    updateDataList()
  }, [zapmslData])

  const runZAPMSL = async (filter, materials) => {
    setMaterials(materials)
    setFilters(filter)
    setLoading(true);
    try {
      const _zapmslData = await fetchAdvancedPackagingMaterialSchedule(filter);
      if (_zapmslData.length === 0) {
        showFeedback(intl.get('commons.noRecordsFound'))
      }
      setZapmslData([..._zapmslData]);
    }
    catch (e) {
      processError(e)
    }
    setLoading(false);
  }

  const selectAllLines = (e) => {
    setSelectedLines(validSelectAll(e, zapmslData));
    setZapmslData([...zapmslData]);
  }

  const reloadTable = () => {
    setZapmslData([...zapmslData]);
  }

  return (
    <React.Fragment>
      <Header
        title={intl.get("menu.subMenuGeneral.primaryMaterialScheduleList")}
        titleWidth={600}
      />
      <S.PrimaryMaterialScheduleWrapper>
        {
          !dataList ?
            <React.Fragment>
              <Filter
                error={processError}
                emitter={runZAPMSL}
              />
              <S.NoDataWrapper>
                <img alt='no-data-icon' className='no-data-image' src={noDataImage} />
                <h2>{intl.get('commons.noRecordsFound')}</h2>
              </S.NoDataWrapper>
            </React.Fragment>
            :
            <React.Fragment>
              <HeaderCommands
                discardChanges={discardChanges}
                reloadTable={reloadTable}
              />
              <ListZAPMSL
                data={dataList}
                filters={filters}
                selectAllLines={selectAllLines}
                processError={processError}
                setLoading={setLoading}
              />
            </React.Fragment>
        }
        <Loading isLoading={loading} fullHeightParent />
      </S.PrimaryMaterialScheduleWrapper>
      <button data-testid={'runZAPMSLTestProvider'} onClick={() => runZAPMSL({plant: {Nome: 'Testing'}})} style={{ opacity: 0 }} />
    </React.Fragment>
  )
}

export default PrimaryMaterialScheduleList
