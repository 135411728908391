import PropTypes from "prop-types"
import {
  ClbIcon,
  ClbTableTBody,
} from 'libs/celebration'
import intl from 'react-intl-universal'
import { Content } from './styled'
import { BodyTableLine } from './body-table-line'
import { fetchSuggestionsJustifications } from "services/suggestion/suggestion.service"
import { useEffect, useState } from "react"

export const LoadBuildingTableBody = ({ data, hasMandatoryFilters }) => {
  const [justifications, setJustifications] = useState([]);

  const loadSuggestionJustifications = async () => {
    const _justifications = await fetchSuggestionsJustifications();
    setJustifications(_justifications);
  }
  
  useEffect(() => {
    loadSuggestionJustifications();
  }, []);

  if (!hasMandatoryFilters) {
    return <Content>
      <ClbIcon icon='Search' />
      <label>{intl.get('load-building.warningFilterIsRequired')}</label>
    </Content>
  }

  if (!data?.Data?.length) {
    return <Content>
      {intl.get('commons.noRecordsFound')}
    </Content>
  }
  return (
    <ClbTableTBody>
      {data?.Data?.map(x => (
        <BodyTableLine key={x.Id} data={x} justifications={justifications} />
      ))}
    </ClbTableTBody>
  )
}

LoadBuildingTableBody.propTypes = {
  data: PropTypes.shape({
    Data: PropTypes.shape({
      length: PropTypes.any,
      map: PropTypes.func
    })
  }).isRequired,
  hasMandatoryFilters: PropTypes.any.isRequired
}