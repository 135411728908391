import { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button } from 'components/uikit-adapter';
import PropTypes from "prop-types";
import OrderListItem from "./order-list-item";
import SuggestionsListItem from "./suggestions-list-item";
import ShelfLifeListItem from "./shelf-life-list-item";
import SuggestionsListHeader from "./suggestions-list-header";
import StyledSuggestionsList from "./suggestions-list.styled";
import intl from "react-intl-universal";

import { getListCodeHeaderAbbreviated, usesJustificationValidationAcceptingSuggestion } from "utils/validations-general-settings";
import IconAddSmall from "components/icons/icn-add-small-blue.icon";
import { IconTrash } from "components/icons/icn-index.icon";

class SuggestionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsChecked: false,
      itemsShelflifeChecked: false,
      suggestionsCheckedData: [],
      shelflifeCheckedData: []
    };

    this.tableListBodyElement = null;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.itemsChecked !== undefined &&
      this.props.itemsChecked !== prevProps.itemsChecked
    ) {
      this.setState({
        itemsChecked: this.props.itemsChecked,
      });
    }

    if (
      this.props.itemsShelflifeChecked !== undefined &&
      this.props.itemsShelflifeChecked !== prevProps.itemsShelflifeChecked
    ) {
      this.setState({
        itemsShelflifeChecked: this.props.itemsShelflifeChecked,
      });
    }
  }

  checkItem = (item, value) => {
    if (value) {
      this.setState(
        (state) => ({
          suggestionsCheckedData: [...state.suggestionsCheckedData, item.Id],
        }),
        () => this.props.checkItem(this.state.suggestionsCheckedData)
      );
    } else {
      this.setState(
        (state) => ({
          suggestionsCheckedData: state.suggestionsCheckedData.filter(
            (idMaterial) => idMaterial !== item.Id
          ),
        }),
        () => this.props.checkItem(this.state.suggestionsCheckedData)
      );
    }
  };

  checkShelfLife = (item, value) => {
    if (value) {
      this.setState(
        (state) => ({
          shelflifeCheckedData: [...state.shelflifeCheckedData, item],
        }),
        () => this.props.checkShelflifeItem(this.state.shelflifeCheckedData)
      );
    } else {
      this.setState(
        (state) => ({
          shelflifeCheckedData: state.shelflifeCheckedData.filter(
            (idMaterial) => idMaterial !== item.Id
          ),
        }),
        () => this.props.checkShelflifeItem(this.state.shelflifeCheckedData)
      );
    }
  };

  checkAllItems = () => {
    const { suggestionsCheckedData } = this.state;
    let _suggestionsCheckedData = [...suggestionsCheckedData];
    if (!this.state.itemsChecked) {
      _suggestionsCheckedData = this.props.data
        .filter((m) => m.Coleta && m.DataEntregaDe && m.naoSalvo !== true)
        .map((m) => m.Id);
    } else {
      _suggestionsCheckedData = [];
    }

    this.setState(prevState => ({
        itemsChecked: !prevState.itemsChecked,
        suggestionsCheckedData: _suggestionsCheckedData,
      }),
      () => {
        this.props.checkAllItems(_suggestionsCheckedData);
      }
    );
  };

  checkAllItemsShelfLife = () => {
    const { shelflifeCheckedData } = this.state;
    let _shelflifeCheckedData = [...shelflifeCheckedData];
    if (!this.state.itemsShelflifeChecked) {
      _shelflifeCheckedData = this.props.data.map((m) => m);
    } else {
      _shelflifeCheckedData = [];
    }

    this.setState(prevState => ({
        itemsShelflifeChecked: !prevState.itemsShelflifeChecked,
        shelflifeCheckedData: _shelflifeCheckedData,
      }),
      () => {
        this.props.checkAllItems(_shelflifeCheckedData);
      }
    );
  };

  expandItem = (...params) => {
    this.props.expandItem(...params, this.tableListBodyElement);
  };

  excluirSugestoes = () => {
    const materialsChecked = [...this.state.suggestionsCheckedData];

    if (!materialsChecked.length) {
      this.props.handleFeedback(intl.get("feedbacks.oneSuggestionDelete"));
    } else {
      this.props.excluirSugestao(materialsChecked);
      this.setState({
        unCheckItems: true,
        suggestionsCheckedData: [],
      });
    }
  };

  aceitarSugestoes = () => {
    const listaSugestoes = this.props.data;
    const materialsChecked = [...this.state.suggestionsCheckedData];

    if (
      !this.validarJustificativaSugestaoManualOuAlterada(
        materialsChecked,
        listaSugestoes
      ) &&
      usesJustificationValidationAcceptingSuggestion()
    ) {
      this.props.handleFeedback(intl.get("feedbacks.mandatoryJustification"));
    }

    if (
      !this.validarSomenteSugestoesManuaisSelecionadas(
        materialsChecked,
        listaSugestoes
      )
    ) {
      this.props.handleFeedback(intl.get("feedbacks.manualSuggestion"));
    } else {
      this.setState(
        {
          unCheckItems: true,
          suggestionsCheckedData: [],
        },
        () => {
          this.setState({ unCheckItems: false }, () => {
            this.props.verificarInconsistenciasDaSugestao(materialsChecked);
            this.props.checkItem([]);
          });
        }
      );
    }
  };

  validarJustificativaSugestaoManualOuAlterada = (
    materialsChecked,
    listaSugestoes
  ) => {
    let isValid = true;
    materialsChecked.map((item, index) => {
      listaSugestoes.forEach((sugestao) => {
        if (sugestao.Id === item) {
          if (sugestao.StatusSigla === "SM" || sugestao.StatusSigla === "SA") {
            if (!sugestao.TipoJustificativaSugestaoManual) {
              isValid = false;
            }
          }
        }
      });
    });
    return isValid;
  };

  isSugestaoAutomaticaPedidoCompraChecada = (sugestao) => (sugestao.Tipo === "PC" && (sugestao.StatusSigla === "SA" || sugestao.StatusSigla === "SG"));

  isSugestaoManualPedidoCompraChecada = (sugestao) => (sugestao.Tipo === "PC" && sugestao.StatusSigla === "SM");

  validarSomenteSugestoesManuaisSelecionadas = (
    materialsChecked,
    listaSugestoes
  ) => {
    let possuiSugestaoAutomaticaPedidoCompraChecada = false;
    let possuiSugestaoManualPedidoCompraChecada = false;
    let pedidoDeTransferencia = false;
    const possuiSugestaoAutomatica = listaSugestoes.some(
      (sugestao) =>
        sugestao.StatusSigla === "SA" || sugestao.StatusSigla === "SG"
    );

    listaSugestoes.forEach((sugestao) => {
      if (materialsChecked.includes(sugestao.Id)) {
        if (sugestao.Tipo === "PT") pedidoDeTransferencia = true;

        if (this.isSugestaoAutomaticaPedidoCompraChecada(sugestao))
          possuiSugestaoAutomaticaPedidoCompraChecada = true;

        if (this.isSugestaoManualPedidoCompraChecada(sugestao))
          possuiSugestaoManualPedidoCompraChecada = true;
      }
    });

    if (pedidoDeTransferencia) {
      return true;
    }

    return !(
      possuiSugestaoAutomatica &&
      possuiSugestaoManualPedidoCompraChecada &&
      !possuiSugestaoAutomaticaPedidoCompraChecada
    )
  };

  isHighlighted = (id) => {
    const { itemHighlighted } = this.props;
    return itemHighlighted > 0 && itemHighlighted == id;
  };

  createSuggestionShelfLife = () => {
    const materialsChecked = [...this.state.shelflifeCheckedData];

    this.props.acceptSuggestionsShelfLife(materialsChecked);
    this.setState(
      {
        unCheckShelflifeItems: true,
        shelflifeCheckedData: [],
      },
      () => {
        this.setState({ unCheckShelflifeItems: false }, () => {
          this.props.checkShelflifeItem([]);
        });
      }
    );
  };

  processHeaderRenderShelfLife = (itemsShelflifeChecked) => {
    return (
      <SuggestionsListHeader
        checked={itemsShelflifeChecked}
        checkItem={this.checkAllItemsShelfLife}
        idCheckbox="header-checkbox-shelf-life"
        isOrder={false}
                >
        <Row middle="xs">
          <Col xs={2}>
            <div className="header-item">{intl.get("commons.source")}</div>
          </Col>
          <Col xs={3}>
            <div className="header-item">{intl.get("commons.lot")}</div>
          </Col>
          <Col xs={2}>
            <div className="header-item">
              {intl.get("stocks.stockDetail.qtde")}
            </div>
          </Col>
          <Col xs={2}>
            <div className="header-item">
              {intl.get("stocks.stockDetail.venc")}
            </div>
          </Col>
          <Col xs={3}>
            <div className="header-item">
              {intl.get("stocks.stockDetail.descont")}
            </div>
          </Col>
        </Row>
      </SuggestionsListHeader>
    );
  }

  processHeaderRenderNormal = (itemsChecked, isOrder, isOrderBulk, isHourlyPlan) => {
    const getColumnSize = (param) => param ? 1 : 2;
    const getColumnSizeVolume = (param) => param ? 1 : 2;

    return (
      <SuggestionsListHeader
        checked={itemsChecked}
        checkItem={this.checkAllItems}
        idCheckbox="header-checkbox"
        isOrder={isOrder || isOrderBulk}
      >
        <Row middle="xs">
          <Col xs={getColumnSize(isOrder)}>
            <div className="header-item">
              {isOrder || isOrderBulk
                ? intl.get("requestOrders.step")
                : intl.get("commons.status")}
            </div>
          </Col>
          <Col xs={getColumnSize(isOrder)}>
            <div className="header-item">
              {intl.get("master-data.general-configuration.type")}
            </div>
          </Col>
          <Col xs={getColumnSize(isHourlyPlan)}>
            <div className="header-item">{intl.get("commons.collect")}</div>
          </Col>
          <Col xs={getColumnSize(isHourlyPlan)}>
            <div className="header-item">{intl.get("commons.delivery")}</div>
          </Col>
          {isHourlyPlan && <Col xs={2}>
            <div className="header-item">{intl.get("commons.deliveryTime")}</div>
          </Col>}
          <Col xs={getColumnSizeVolume(isOrderBulk)}>
            <div className="header-item">
              {intl.get("stocks.stockDetail.qtde")}
            </div>
          </Col>
          <Col xs={getColumnSizeVolume(isOrderBulk)}>
            <div className="header-item">
              {isOrder
                ? intl.get("commons.code")
                : isOrderBulk
                  ? intl.get("stocks.stockDetail.volumnPortal")
                  : "UN"}
            </div>
          </Col>
          {isOrderBulk && <Col xs={2}>
            <div className="header-item">
              {intl.get("stocks.stockDetail.internalVolumnPortal")}
            </div>
          </Col>}
          {
            isOrder &&
            <Col xs={2}>
              <div className="header-item">
                {getListCodeHeaderAbbreviated()}
              </div>
            </Col>
          }
        </Row>
      </SuggestionsListHeader>
    );
  }

  processHeaderRender = () => {
    const { data, isOrder, isOrderBulk, isShelfLife, isHourlyPlan } = this.props;
    const { itemsChecked, itemsShelflifeChecked } = this.state;

    if (data && !!data.length && !isShelfLife) {
      return this.processHeaderRenderNormal(itemsChecked, isOrder, isOrderBulk, isHourlyPlan);
    } else if (data && !!data.length && isShelfLife) {
      return this.processHeaderRenderShelfLife(itemsShelflifeChecked);
    }
    return (
      <div />
    )
  };

  processListRender = (item, index) => {
    const {
      changeValueSugestao,
      obterListaJustificativasSugestaoManual,
      listaJustificativasSugestaoManual,
      changeValueDeadline,
      salvarSugestao,
      excluirSugestao,
      cancelarSaldoPedidoVolume,
      showCancelConfirm,
      showFormOrder,
      isOrder,
      isOrderBulk,
      isShelfLife,
      isTransfer,
      itemExpanded,
      suggestionsExpandedList,
      shelflifeExpandedList,
      handleFeedback,
      changeValueOrder,
      saveOrder,
      changeHoraEntrega,
      isNewFlow,
      documentTypes,
      doors,
      isHourlyPlan,
      isExternalSuggestion
    } = this.props;

    const {
      itemsChecked,
      unCheckItems,
      unCheckShelflifeItems,
      itemsShelflifeChecked,
    } = this.state;

    if (!isOrder && !isOrderBulk && !isShelfLife) {
      return (
        <SuggestionsListItem
          key={item.Id}
          item={item}
          hasIcon
          itemIndex={index}
          checked={itemsChecked}
          unCheckItem={unCheckItems}
          checkItem={this.checkItem}
          expandItem={this.expandItem}
          expanded={
            suggestionsExpandedList &&
            suggestionsExpandedList.findIndex((sugg) => sugg.Id === item.Id)
          }
          changeValueSugestao={changeValueSugestao}
          changeHoraEntrega={changeHoraEntrega}
          obterListaJustificativasSugestaoManual={
            obterListaJustificativasSugestaoManual
          }
          listaJustificativasSugestaoManual={listaJustificativasSugestaoManual}
          salvarSugestao={salvarSugestao}
          excluirSugestao={excluirSugestao}
          cancelarSaldoPedidoVolume={cancelarSaldoPedidoVolume}
          showCancelConfirm={showCancelConfirm}
          showFormOrder={showFormOrder}
          changeValueDeadline={changeValueDeadline}
          highlighted={this.isHighlighted(item.Id)}
          handleFeedback={handleFeedback}
          itemDimmed={item.StatusSigla === "AG" ? true : false}
          centro={this.props.centro}
          idUnidadeNegocio={this.props.idUnidadeNegocio}
          linhaProducaoCentro={this.props.linhaProducaoCentro}
          documentTypes={documentTypes}
          doors={doors}
          isHourlyPlan={isHourlyPlan}
          isExternalSuggestion={isExternalSuggestion}
        />
      );
    } else if (isOrder || isOrderBulk) {
      return (
        <OrderListItem
          key={item.Id}
          item={item}
          hasIcon
          isOrder={isOrder}
          isOrderBulk={isOrderBulk}
          isTransfer={isTransfer}
          itemIndex={index}
          checked={itemsChecked}
          unCheckItem={unCheckItems}
          checkItem={this.checkItem}
          expandItem={this.expandItem}
          expanded={
            itemExpanded &&
            (isOrder || isOrderBulk) &&
            item.Id === itemExpanded.Id
          }
          changeValueOrder={changeValueOrder}
          salvarSugestao={salvarSugestao}
          excluirSugestao={excluirSugestao}
          cancelarSaldoPedidoVolume={cancelarSaldoPedidoVolume}
          showCancelConfirm={showCancelConfirm}
          showFormOrder={showFormOrder}
          changeValueDeadline={changeValueDeadline}
          highlighted={this.isHighlighted(item.Id)}
          itemDimmed={item.StatusSigla === "AG" ? true : false}
          saveOrder={saveOrder}
          usuarioComPermissaoParaCancelarPedidos={
            this.props.usuarioComPermissaoParaCancelarPedidos
          }
          itemExpanded={false}
          isNewFlow={isNewFlow}
          isHourlyPlan={isHourlyPlan}
        />
      );
    } else {
      return (
        <ShelfLifeListItem
          key={index}
          item={item}
          hasIcon
          isShelfLife={isShelfLife}
          itemIndex={index}
          checked={itemsShelflifeChecked}
          unCheckItem={unCheckShelflifeItems}
          expandItem={this.expandItem}
          checkShelflifeItem={this.checkShelfLife}
          expanded={
            shelflifeExpandedList &&
            shelflifeExpandedList.findIndex((sugg) => sugg.Id === item.Id)
          }
        />
      );
    }
  };

  processNoData = () => {
    const { isOrder, isOrderBulk, isTransfer, isShelfLife } = this.props;

    if (isTransfer)
      return intl.get("stocks.emptyList.outgoingTransferProgress");
    else if (isOrder) return intl.get("stocks.emptyList.orderProgress");
    else if (isOrderBulk) return intl.get("stocks.emptyList.volume");
    else if (isShelfLife) return intl.get("stocks.emptyList.transfer");
    else return intl.get("stocks.emptyList.suggestion");
  };

  processFooter = () => {
    const { formValid, isOrder, isOrderBulk, isShelfLife } = this.props;

    if (!isOrder && !isOrderBulk && !isShelfLife) {
      return (
        <div className="table-list-footer">
          <div title={intl.get("stocks.stockDetail.deleteSuggestion")}>
            <Button
              width="50px"
              buttonIcon={<IconTrash color={'var(--color-contrast-white)'}/>}
              type="danger"
              onClick={this.excluirSugestoes}
            />
          </div>
          <div title={intl.get("stocks.stockDetail.newSuggestion")}>
            <Button
              width="50px"
              buttonIcon={<IconAddSmall/>}
              type="secondary"
              onClick={this.props.createSuggestionManual}
            />
          </div>
          <div title={intl.get("stocks.stockDetail.accSuggestion")}>
            <Button
              width="200px"
              value={intl.get("stocks.stockDetail.accSuggestion")}
              type="primary"
              disabled={!formValid}
              onClick={this.aceitarSugestoes}
            />
          </div>
        </div>
      );
    } else if (isShelfLife) {
      return (
        <div className="table-list-footer">
          <div title={intl.get("stocks.stockDetail.addSuggestion")}>
            <Button
              width="200px"
              value={intl.get("stocks.stockDetail.addSuggestion")}
              type="primary"
              onClick={this.createSuggestionShelfLife}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    const { data } = this.props;
    return (
      <StyledSuggestionsList>
        {this.processHeaderRender()}

        <div
          ref={(ref) => (this.tableListBodyElement = ref)}
          className="table-list-body"
        >
          {data && data.length ? (
            data.map((item, index) => this.processListRender(item, index))
          ) : (
            <div className="no-data">
              <p>{this.processNoData()}</p>
            </div>
          )}
        </div>
        {this.processFooter()}
      </StyledSuggestionsList>
    );
  }
}

SuggestionsList.propTypes = {
  data: PropTypes.array.isRequired,
  itemsChecked: PropTypes.bool,
  formValid: PropTypes.bool,
  expandItem: PropTypes.func,
  checkAllItems: PropTypes.func,
  createSuggestionManual: PropTypes.func,
  verificarInconsistenciasDaSugestao: PropTypes.func,
  salvarSugestao: PropTypes.func,
  excluirSugestao: PropTypes.func,
  showCancelConfirm: PropTypes.func,
  showFormOrder: PropTypes.func,
  changeValueSugestao: PropTypes.func,
  obterListaJustificativasSugestaoManual: PropTypes.func,
  changeValueDeadline: PropTypes.func,
  cancelarSaldoPedidoVolume: PropTypes.func,
  checkItem: PropTypes.func,
  isOrder: PropTypes.bool,
  isOrderBulk: PropTypes.bool,
  isShelfLife: PropTypes.bool,
  itemExpanded: PropTypes.object,
  suggestionsExpandedList: PropTypes.array,
  usuarioComPermissaoParaCancelarPedidos: PropTypes.func,
  checkShelflifeItem: PropTypes.func,
  changeHoraEntrega: PropTypes.func,
  isHourlyPlan: PropTypes.bool,
  itemsShelflifeChecked: PropTypes.bool,
  handleFeedback: PropTypes.func,
  itemHighlighted: PropTypes.number,
  acceptSuggestionsShelfLife: PropTypes.func,
  listaJustificativasSugestaoManual: PropTypes.any,
  isTransfer: PropTypes.bool,
  shelflifeExpandedList: PropTypes.array,
  changeValueOrder: PropTypes.func,
  saveOrder: PropTypes.func,
  isNewFlow: PropTypes.any,
  documentTypes: PropTypes.any,
  doors: PropTypes.any,
  isExternalSuggestion: PropTypes.any,
  centro: PropTypes.any,
  idUnidadeNegocio: PropTypes.string,
  linhaProducaoCentro: PropTypes.string,
};

export default SuggestionsList;
