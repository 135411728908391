import PropTypes from "prop-types"
import ModalConfiguration from 'components/modal-configuration';
import { DialogsUserSettingsOptions } from 'features/navbar/utils';
import ImportExportGarrafas from "components/import-export-garrafas";
import ReleaseVersion from "components/release-version";
import ScheduleNotificationEmailSending from "components/modal-send-email-notification";

export const DialogsUserSettings = ({ open, handleClose }) => (
  <>
    <ReleaseVersion
      handleClose={handleClose}
      open={open === DialogsUserSettingsOptions.RELEASE}
    />
    <ModalConfiguration
      open={open === DialogsUserSettingsOptions.CONFIGURATION}
      editMode={false}
      handleClose={handleClose}
    />
    <ImportExportGarrafas
      isOpen={open === DialogsUserSettingsOptions.IMPORT_EXPORT_BOTTLES}
      close={handleClose}
    />
    <ScheduleNotificationEmailSending
      isOpen={open === DialogsUserSettingsOptions.SCHEDULE_NOTIFICATION_EMAIL_SENDING}
      close={handleClose}
    />
  </>
)

DialogsUserSettings.propTypes = {
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired
}
