import styled from "styled-components"

export const WrapperTable = styled.div`
  display: flex;
  width: 100%;
  max-height: calc(100vh - 340px);
  overflow-y: scroll;
  overflow-x: hidden;
`

export const WrapperLeftTable = styled.div`
  min-width: 50%;
  height: fit-content;
`

export const TableHeader = styled.div`
  max-height: 64px;
`

export const TableHeadRow = styled.div`
  display: flex;
  
  .description-cell{
    min-width: 17.5%;
    padding: 0;
  }

  .quantity-cell{
    min-width: 15%;
    padding: 0;
  }

  .title-date-row{
    padding: 0 0 0 26%;
  }

  .date-cell{
    padding: 0;
    min-width: 85px;
    text-align: center;
    border-top: 2px solid var(--color-neutral-300);
  }
`

interface TableHeadCellProps {
  isReduced: boolean
}

export const TableHeadCell = styled.div<TableHeadCellProps>`
  height: auto;
  background-color: var(--color-neutral-200);
  min-height: ${p => p.isReduced ? '32px' : '64px' };
  align-content: center;
`

export const CellText = styled.span`
  background-color: var(--color-neutral-200);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-default);
  color: var(--color-neutral-600);
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
`

export const TableBody = styled.div``

export const TableBodyRow = styled.div`
  display: flex;
  min-height: 48px;

  .description-cell{
    min-width: 17.5%;
    max-width: 17.5%;
    padding: 0;
  }

  .quantity-cell{
    min-width: 15%;
    padding: 0;
  }

  .date-cell{
    padding: 0;
    min-width: 85px;
    text-align: center;
  }
`
interface TableBodyCellProps {
  isOdd: boolean
}

export const TableBodyCell = styled.div<TableBodyCellProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-content: center;
  background-color: ${p => p.isOdd ? 'var(--color-neutral-100)' : 'var(--color-neutral-background)' };
`

export const BodyCellText = styled.span`
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-default);
  text-align: left;
  color: var(--color-neutral-600);
  font-size: 12px;
  padding: 0px 8px 0px 12px;
`

export const WrapperRightTable = styled.div`
  min-width: 50%;
  border-left: 2px solid var(--color-neutral-300);
  height: fit-content;
  cursor: pointer;
`
