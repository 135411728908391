import ImportExport from "components/import-export";
import intl from "react-intl-universal";
import moment from 'moment'
import { downloadFile } from 'utils/custom-functions'
import { formatErrorMessage } from "utils/handle-error";
import FilterSupplierProductionPlanUpload from "./filter-supplier-production-plan-upload";
import { useSupplierProductionPlanContext } from "features/supplier-production-plan";
import { useToastContext } from "hooks/useToastContext";
import { getSupplierProductionPlanExport, getSupplierProductionPlanExportModel } from "services/supplier-production-plan.service";
import { ISupplierProductionPlanFiltersImportExport } from "./interface";
import { mountQueryObject } from "./utils";

interface IImportExportSupplierProductionPlan {
  isOpen: boolean;
  close: () => Promise<void>
}

const ImportExportSupplierProductionPlan = ({
  isOpen, close
}: IImportExportSupplierProductionPlan) => {
  const { openToast } = useToastContext();
  const { supplierGroups, suppliers, materials } = useSupplierProductionPlanContext();

  const data = {
    description: intl.get('exportImportModal.supplierProductionPlan.title'),
    title: intl.get(`exportImportModal.supplierProductionPlan.title`),
    contextModal: 'supplierProductionPlan',
    importUrl: "supplier-production-plans/import-data"
  }

  const proccessErro = (e: any) => {
    openToast(formatErrorMessage(e));
  }

  const exportData = async (filters: ISupplierProductionPlanFiltersImportExport) => {
    try {
      const _query = mountQueryObject(filters);
      const response = await getSupplierProductionPlanExport(_query);
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss');
      downloadFile(`${datafile}_${data.contextModal}.csv`, 'csv', response);
    } catch (e) {
      return proccessErro(e);
    }
  }

  const exportModel = async () => {
    try {
      const response = await getSupplierProductionPlanExportModel();
      downloadFile(`${data.contextModal}_model.csv`, 'csv', response);
    } catch (e) {
      return proccessErro(e);
    }
  }

  const component = () => <FilterSupplierProductionPlanUpload
    onClick={(filters: ISupplierProductionPlanFiltersImportExport) => exportData(filters)}
    optionsFilter={{
      supplierGroups: supplierGroups,
      suppliers: suppliers, 
      materials: materials
    }}
  />

  return (
    <>{isOpen && 
      <ImportExport
        title={data.title}
        contextModal={data.contextModal}
        open={isOpen}
        handleClose={close}
        downloadModelo={exportModel}
        importArquivo={data.importUrl}
        downloadArquivo={exportData}
        type='CSV'
        content={{
          hasFilter: true,
          component: component()
        }}
      />
    }</>
  );
};

export default ImportExportSupplierProductionPlan;
