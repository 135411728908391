import { Button } from 'components/uikit-adapter/index';
import Dialog from "material-ui/Dialog";

import StyledModalConfirmacaoExecucao from "./styledModalConfirmacaoExecucaoIntegracao";
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'

const ModalConfirmacaoExecucaoIntegracao = ({ showModalConfirmation, handleShowModal, executeAndCloseModal, descricaoIntegracao, desabilitarBotaoOk, children }) => (
  <Dialog open={showModalConfirmation} contentStyle={{ width: "600px" }} title={descricaoIntegracao}>
    <StyledModalConfirmacaoExecucao>
      <div>{children}</div>
      <div className="mensagem-modal-integracao-mensagem">{intl.get('master-data.configurations.integrations.header.message')}</div>

      <div className="divisor-modal-integracao" />

      <div className="botoes-modal-integracao">
        <Button
          width="150px"
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={handleShowModal}
          className="btn"
        />
        <Button
          width="150px"
          type="primary"
          value="OK"
          onClick={executeAndCloseModal}
          className="btn margin-botao"
          disabled={desabilitarBotaoOk}
        />
      </div>
    </StyledModalConfirmacaoExecucao>
  </Dialog>
);

ModalConfirmacaoExecucaoIntegracao.propTypes = {
  showModalConfirmation: PropTypes.bool.isRequired,
  handleShowModal: PropTypes.func.isRequired,
  executeAndCloseModal: PropTypes.func.isRequired,
  descricaoIntegracao: PropTypes.string.isRequired,
  desabilitarBotaoOk: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default ModalConfirmacaoExecucaoIntegracao;
