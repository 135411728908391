import PropTypes from "prop-types"
import { useState } from 'react'
import Functions from './functions.js'
import Dialog from '@material-ui/core/Dialog';
import * as S from './styled'
import ChartStocks from 'components/chart-stocks';

const ModalCreateSuggestions = ({ open, closeModal, isEditMode, unidadeNegocio,
  identificador, proccessError, refreshPage, refreshCardSidebar, isSelectedCard, removeSelectedLoads, isEmergencyNegotiation }) => {
  const [showChart, setShowChart] = useState(false)
  const [idFamiliaRotuladaCentro, setIdFamiliaRotuladaCentro] = useState()

  const handleChart = (id) => {
    setShowChart(false);
    setIdFamiliaRotuladaCentro(id);
    setShowChart(true);
  }

  const close = () => {
    setShowChart(false);
    closeModal();
  }

  return (
    <S.WrapperDialog>
      <Dialog
        open={open}
        onClose={close}
      >
        <S.Wrapper>
          <S.WrapperContent>
            <S.ContentSuggestion showChart={showChart}>
              <Functions
                editMode={isEditMode}
                unidadeNegocio={unidadeNegocio}
                handleChart={handleChart}
                identificador={identificador}
                proccessError={proccessError}
                handleClose={close}
                refreshPage={refreshPage}
                refreshCardSidebar={refreshCardSidebar}
                isSelectedCard={isSelectedCard}
                removeSelectedLoads={removeSelectedLoads}
                isEmergencyNegotiation={isEmergencyNegotiation}
              />
            </S.ContentSuggestion>
            {showChart &&
              <S.ContentChart>
                <S.ArrowDetail />
                <ChartStocks
                  idFamiliaRotuladaCentro={idFamiliaRotuladaCentro}
                  pedidoVolume={false}
                  estoqueVariavel={false}
                  transfSaida={false}
                  transfEntrada={false}
                  hideLastUpdate
                />
              </S.ContentChart>
            }
          </S.WrapperContent>
        </S.Wrapper>
      </Dialog>
    </S.WrapperDialog >
  )
}

ModalCreateSuggestions.propTypes = {
  closeModal: PropTypes.func.isRequired,
  identificador: PropTypes.any.isRequired,
  isEditMode: PropTypes.any.isRequired,
  isEmergencyNegotiation: PropTypes.any.isRequired,
  isSelectedCard: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  proccessError: PropTypes.any.isRequired,
  refreshCardSidebar: PropTypes.any.isRequired,
  refreshPage: PropTypes.any.isRequired,
  removeSelectedLoads: PropTypes.any.isRequired,
  unidadeNegocio: PropTypes.any.isRequired
}

export default ModalCreateSuggestions
