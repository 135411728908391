export const GeneralSettingParameter = Object.freeze({
    LoadCompositionNewFlow: 0,
    UseNewSearchTaskEndpoint: 1,
    SuggestionJustification: 2,
    CheckShipmentIsAurora: 3,
    OrderSettings: 4,
    ReceiptDocumentType: 5,
    UpdateOrderPickUpAndDeliveryDateWhenReceiveGoodReceipts: 6,
    CountriesUseIncoterm: 7,
    ValidateJustification: 8,
    ViewPriceColumnsOnLoadCompositionView: 9,
    QuantityDecimalPlaces: 10,
    UseSharedWeeklyForecastWithSupplier: 11,
    UsePrimaryMaterial: 12,
    UseAutoLoadOptimization: 13,
    UsePrimaryMaterialSchedule: 14,
    ShowInboundTransfer: 15,
    UseCreateGRN: 16,
    UseTransferOrder: 17,
    UseTMSIntegration: 18,
    HideSomeSettingsLabeledFamily: 19,
    UpdateQuantityOrderWhenReceiveInboundDelivery: 20,
    UseReturnableAssets: 21,
    UseOORRelevance: 22,
    UseContingencyRequest: 23,
    UseBottlesPlan: 24,
    TaskTypesAllowedInIntegration: 25,
    UseHourlyPlan: 26,
    UseLoadOptimizationViaPortal: 27,
    UseVolume: 28,
    NewFlowEmergencyRenegotiation: 29,
    UseRequirementCode: 31
});
