import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableFooter,
  TableRowColumn
} from 'material-ui/Table'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import TableRownColumnUnclickable from 'components/table-row-column-unclickable'
import DeleteIcon from 'images/icn-trash-bin.svg'
import IconButton from 'material-ui/IconButton'
import intl from 'react-intl-universal'

const CoordinationList = ({ data, countData, refreshSearch, handleEdit, handleDelete, qtRegsPerPage, profileAdminOrMasterData }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
    onCellClick={handleEdit}>
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      enableSelectAll={false}
      style={{ borderBottom: 'none' }}>
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn >{intl.get('commons.description')}</TableHeaderColumn>
        <TableHeaderColumn />
      </TableRow>
    </TableHeader>
    <TableBody
      displayRowCheckbox={false}
      deselectOnClickaway={false}
      showRowHover
      stripedRows={false}>{
        data.map((data) =>
          <TableRow key={data.Id} style={{ cursor: 'pointer' }}>
            <TableRowColumn >
              {data.Description}
            </TableRowColumn>
            <TableRownColumnUnclickable style={{ positon: 'relative', textAlign: 'right' }}>
            { profileAdminOrMasterData &&
              <div title={intl.get('master-data.coordinationsAndUsers.coordinations.actions.deleteCoordination')}>
                <IconButton onClick={() => handleDelete(data.Id)} >
                  <img alt='delete-icon' src={DeleteIcon} />
                </IconButton>
              </div>
            }
            </TableRownColumnUnclickable>
          </TableRow>)
      }
    </TableBody>
    <TableFooter>
      <PaginationFooter adjustForCheckbox={false}
        countPerPage={qtRegsPerPage}
        countData={countData}
        refreshSearch={refreshSearch}
            />
    </TableFooter>
  </Table>
)

CoordinationList.propTypes = {
  countData: PropTypes.number.isRequired,
  data: PropTypes.shape({
    Description: PropTypes.any,
    Id: PropTypes.any,
    map: PropTypes.func
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired
}

export default CoordinationList
