import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../utils/handle-error'
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'
import { ModalConfirmAction } from 'components/modal-confirm-action';
import Fetch from 'utils/fetch';

const ScheduleNotificationEmailSending = ({ isOpen, close }) => {
  const { showFeedback } = useGlobalContext();

  const proccessErro = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const scheduleEmailSending = async () => {
    return Fetch.post('notifications:manual-schedule-email')
    .then(close)
    .catch(e => proccessErro(e));
  }

  return (
    <ModalConfirmAction
      isOpen={isOpen}
      handleConfirm={scheduleEmailSending}
      handleClose={close}
      textMessage={intl.get(`ScheduleNotificationEmailSendingModal.description`)}
      title={intl.get(`ScheduleNotificationEmailSendingModal.title`)}
    />
  )
}

ScheduleNotificationEmailSending.propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool
}

export default ScheduleNotificationEmailSending;
