import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import session from "utils/user-storage";
import ComposicaoCargaForm from 'pages/composicao-carga/form'
import ModalReviewLoadInEmergencyNegotiation from "components/modal-review-load-in-emergency-negotiation";
import { getLoadCompositionAndNegotiations } from "services/load-composition.service";
import { formatErrorMessage } from "utils/handle-error";
import { useGlobalContext } from "hooks/useGlobalContext";
import { SituacaoComposicaoCarga } from "models/composicaoCarga/situacaoComposicaoCarga";
import { usesEmergencyNegotiation } from "utils/validations-general-settings";

const ModalLoadComposition = ({ open, compositionId, redirectRoute, handleRefresh, readingMode, close, idPedido }) => {
  const userId = session.get().Id;
  const { showFeedback } = useGlobalContext();
  const [openModalLoadComposition, setOpenModalLoadComposition] = useState(false);
  const [openModalLoadInEmergencyNegotiation, setOpenModalLoadInEmergencyNegotiation] = useState(false);
  const [composition, setComposition] = useState(null);
  const [orderId, setOrderId] = useState(() => idPedido ?? 0);

  const handleOpenModalLoadInEmergencyNegotiation = () => setOpenModalLoadInEmergencyNegotiation(true);
  const handleCloseModalLoadInEmergencyNegotiation = () => {
    setOpenModalLoadInEmergencyNegotiation(false);
    closeModal();
  }

  const handleOpenModalLoadComposition = () => setOpenModalLoadComposition(true);
  const handleCloseModalLoadComposition = () => {
    setOpenModalLoadComposition(false);
    closeModal();
  }

  const closeModal = () => {
    setComposition(null);
    setOrderId(0);
    close();
  }

  const getLoadComposition = async () => {
    try {
      const _compositionAndNegotiations = await getLoadCompositionAndNegotiations(compositionId);
      const _isEmergencyRenegotiation = usesEmergencyNegotiation() && _compositionAndNegotiations.SituacaoValor === SituacaoComposicaoCarga.NegociacaoEmergencial

      setComposition({ ..._compositionAndNegotiations, isOpen: true, hour: _compositionAndNegotiations.HoraEntrega, date: _compositionAndNegotiations.DataEntrega });

      if(_isEmergencyRenegotiation){
        handleOpenModalLoadInEmergencyNegotiation();
      } else{
        handleOpenModalLoadComposition();
      }
    } catch (error) {
      showFeedback(formatErrorMessage(error));
    }
  }

  const getFilters = () => ({
    center: { IdUnidadeNegocio: composition?.IdUnidadeNegocio, Descricao: `${composition?.Centro} - ${composition?.UnidadeNegocio}` },
    dateFrom: composition?.DataColeta,
    dateTo: composition?.DataEntrega
  })

  useEffect(() => {
    if(compositionId) getLoadComposition();
    if(open && !compositionId) handleOpenModalLoadComposition();
  }, [open, compositionId]);

  return (
    <>
      {openModalLoadComposition &&
        <ComposicaoCargaForm
          idEdit={compositionId}
          idPedido={orderId}
          idUser={userId}
          open={openModalLoadComposition}
          handleRefresh={handleRefresh}
          handleShowFeedback={showFeedback}
          handleClose={handleCloseModalLoadComposition}
          modoLeitura={readingMode}
          responsavelProximaAcao={composition?.UserTypeNextStep}
          redirectRoute={redirectRoute}
        />
      }
      {openModalLoadInEmergencyNegotiation &&
        <ModalReviewLoadInEmergencyNegotiation
          isOpen={openModalLoadInEmergencyNegotiation}
          handleClose={handleCloseModalLoadInEmergencyNegotiation}
          composicao={composition}
          filters={getFilters()}
          redirectRoute={redirectRoute}
          handleRefresh={handleRefresh}
        />
      }
    </>
  )
}

ModalLoadComposition.propTypes = {
  open: PropTypes.bool.isRequired,
  compositionId: PropTypes.number,
  redirectRoute: PropTypes.func,
  handleRefresh: PropTypes.func,
  readingMode: PropTypes.bool,
  close: PropTypes.func,
  idPedido: PropTypes.number
}

export default ModalLoadComposition;