import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { Col, Row } from "react-flexbox-grid";
import { isCurrentRefuelingPointPercentDisabled, userCanEditStockPolicies } from "../../validations";
import InputNumber from "components/input-number";
import InsumosInput from "components/insumos-input";
import { Input } from "components/uikit-adapter/index";
import { formatNumber } from "utils/format";
import { validatesArrayHasValue } from "utils/custom-functions";

const INTL_GERAL = "master-data.general-configuration";
const TIPO_ALGORITMO_POLITICA_SILOS = 3;

const InventoryConfigurationByStorageLocation = ({
  inventoryStorageLocations,
  selectedTipoAlgoritmo,
  updateStorageLocationsInventory,
}) => (
  <>
    {validatesArrayHasValue(inventoryStorageLocations) &&
      inventoryStorageLocations.map((x) => (
        <>
          <label className="label-style">
            {`${intl.get(INTL_GERAL + ".currentInventory")} - ${intl.get("master-data.general-configuration.storageLocationAbbreviation")} ${x.Description}`}
          </label>
          <div className="container__general-form">
            <Row>
              <Col xs={6} lg={6} md={6}>
                <InputNumber
                  type="default"
                  required
                  label={intl.get(`${INTL_GERAL}.minimumStock`)}
                  name="CurrentMinimumStock"
                  value={x.CurrentMinimumStock}
                  onChange={(_value) => updateStorageLocationsInventory(x.StorageLocationId, "CurrentMinimumStock", _value)}
                  disabled={!userCanEditStockPolicies()}
                  data-testid='input-current-minimum-stock'
                />
              </Col>
              <Col xs={6} lg={6} md={6}>
                <InputNumber
                  type="default"
                  required
                  label={intl.get(`${INTL_GERAL}.maximumStock`)}
                  name="CurrentMaximumStock"
                  value={x.CurrentMaximumStock}
                  onChange={(_value) => updateStorageLocationsInventory(x.StorageLocationId, "CurrentMaximumStock", _value)}
                  disabled={selectedTipoAlgoritmo == TIPO_ALGORITMO_POLITICA_SILOS || !userCanEditStockPolicies()}
                  data-testid='input-current-maximum-stock'
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <label className="label-style label-style-title">
                  {intl.get(`${INTL_GERAL}.refuelingPoint`)}
                </label>
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <InsumosInput
                  disabled={isCurrentRefuelingPointPercentDisabled(selectedTipoAlgoritmo)}
                  required
                  type="number"
                  maxLength="3"
                  value={x.CurrentRefuelingPointPercent}
                  name="CurrentRefuelingPointPercent"
                  onChange={(event) => updateStorageLocationsInventory(x.StorageLocationId, "CurrentRefuelingPointPercent", event.currentTarget.value)}
                  data-testid='input-current-refueling-point-percent'
                />
              </Col>
              <Col xs={8}>
                <Input
                  disabled
                  label=""
                  name="CurrentRefuelingPoint"
                  value={formatNumber(x.CurrentRefuelingPoint)}
                  className="input"
                />
              </Col>
            </Row>
          </div>
        </>
      ))}
  </>
);

InventoryConfigurationByStorageLocation.propTypes = {
  inventoryStorageLocations: PropTypes.object.isRequired,
  selectedTipoAlgoritmo: PropTypes.number.isRequired,
  updateStorageLocationsInventory: PropTypes.func.isRequired
};

export default InventoryConfigurationByStorageLocation;
