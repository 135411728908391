import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

const StyledComposicaoList = styled(StyledGrid)`  

  width: 100%;
  padding: 0px 8px 0 8px;

  .campoPesquisar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .containerListaComposicoes {
    height: calc(100vh - 195px);
    overflow-y: auto;
  }
  
  .pagination {
    justify-content: flex-end;
  }

  .align-itens {
    display: flex;
    align-items: center;
  }

  input {
    &.input-checkbox {
      outline: none;
      -webkit-appearance: none;
      border: 1px solid #d2d6da;
      background: white;
      display: block;
      padding: 7px;
      cursor: pointer;

      -webkit-user-select: none;/* Chrome all / Safari all */
      -moz-user-select: none;/* Firefox all */
      -ms-user-select: none;/* IE 10+ */
      user-select: none;

      &:checked {
        background: #78cdef;
      }
    }
  }

  .no-results {
    font-size: 14px;
  }
  
  .col-button-loadMore {
    display: flex;
    justify-content: center;
    vertical-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .pt-10 {
    padding-top: 10px;
  }
  
  .pb-10 {
    padding-bottom: 10px;
  }

  .hintList:hover .hintListComponent {
    display: block;
  }

  .format-input-space {
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default StyledComposicaoList
