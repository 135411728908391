import { useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import HintList from 'components/hint-list'
import MapToHTML from 'components/map-to-html'
import moment from 'moment'
import intl from 'react-intl-universal'
import Pagination from 'components/pagination'
import StyledComposicaoList from './styledComposicaoList'
import SessionUser from 'utils/user-storage'
import { formatNumber } from 'utils/format'
import { getObservationLabel, getObservationValue } from 'pages/load-composition/utils/observation'
import { Checkbox } from '@hbsis.uikit/react'
import { SituacaoComposicaoCarga } from 'models/composicaoCarga/situacaoComposicaoCarga'

const ComposicaoLista = ({ data, handleEdit, refreshSearch, page, take, totalComposicoes, selectedItems, setSelectedItems }) => {
  const [allChecked, setAllChecked] = useState(false);
  const validSituations = [
      SituacaoComposicaoCarga.EmNegociacao,
      SituacaoComposicaoCarga.AguardandoRevisaoFornecedor
    ];

  const user = SessionUser.get();
  const isSupplier = user.TipoUsuario === 2; 

  const checkAllItems = () => {
    if (allChecked) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data
        .filter(item => validSituations.includes(item.SituationValue) && item.IsUserResponsable && 
+        (isSupplier || (!isSupplier && item.SituationValue != SituacaoComposicaoCarga.AguardandoRevisaoFornecedor)))
        .map(item => item.LoadCompositionId));
    }
    setAllChecked(!allChecked);
  }

  const checkItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter(item => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  }

  const disableCheckbox = (situation, isUserResponsable) => {
    return !validSituations.includes(situation) || !isUserResponsable || (!isSupplier && situation === SituacaoComposicaoCarga.AguardandoRevisaoFornecedor);
  }

  return (<StyledComposicaoList>
    <Row className="rowListHeader align-itens">
      <Col xs={1} md={1} lg={1} className="align-center align-itens">
      <Checkbox
          name={`checkbox-header`}
          text=" "
          checked={allChecked}
          onChange={checkAllItems}
          data-testid={'checkbox1'}
        />
        {intl.get('commons.composition')}
      </Col>
      <Col xs={2} md={2} lg={2} className="align-center">{intl.get('transports.compoCharge.requestBuySAP')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.colDate')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.deliveryDate')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('transports.compoCharge.qtdRequestPortal')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.qtdTotal')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.source')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.center')}</Col>
      <Col xs={2} md={2} lg={2} className="align-center">{getObservationLabel()}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.situation')}</Col>
    </Row>
    <Row className="containerListaComposicoes">
      <Col xs={12} md={12} lg={12} className="listNotasFiscais pt-0 pb-0">
        {
          (!data || data.length === 0) &&
          <Row className="rowList">
            <Col xs={12} md={12} lg={12} className="align-center">
              <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
            </Col>
          </Row>
        }
        {
          data && !!data.length && data.map((composicao, i) => (
            <Row
              key={i}
              className="rowList"
              data-testid={`load-composition-line-${composicao.LoadCompositionId}`}
              onClick={() => handleEdit(composicao)}
              style={{ cursor: 'pointer', borderBottom: '1px dashed #98c1dc', backgroundColor: `${composicao.IsResponsibleNextAction ? 'var(--color-opacity-hover)' : 'var(--color-contrast-white)'}` }}
            >
              <Col xs={1} md={1} lg={1} className="align-center align-itens" data-testid={`load-composition-id-${composicao.LoadCompositionId}`} onClick={e => e.stopPropagation()} >
                <Checkbox
                    name={`checkbox-${composicao.LoadCompositionId}`}
                    text=" "
                    checked={selectedItems.includes(composicao.LoadCompositionId)}
                    onChange={() => checkItem(composicao.LoadCompositionId)}
                    disabled={disableCheckbox(composicao.SituationValue, composicao.IsUserResponsable)}
                  />
                  {composicao.LoadCompositionId}
                </Col>
              <Col xs={2} md={2} lg={2} className="align-center hintList" data-testid={`load-composition-pc-sap-${composicao.LoadCompositionId}`} onClick={(ev) => ev.stopPropagation()}>
                <HintList itens={composicao.OrdersSap} label={`${composicao.OrdersSap.length} ${intl.get("menu.menuGeneral.orders")} `} copyItens={true}>
                  <MapToHTML itens={composicao.OrdersSap} />
                </HintList>
              </Col>
              <Col xs={1} md={1} lg={1} className="align-center" data-testid={`load-composition-pickup-date-${composicao.LoadCompositionId}`}>{composicao.CollectDate ? moment(composicao.CollectDate).format('L') : '-'}</Col>
              <Col xs={1} md={1} lg={1} className="align-center" data-testid={`load-composition-delivery-date-${composicao.LoadCompositionId}`}>{moment(composicao.DeliveryDate).format('L')}</Col>
              <Col xs={1} md={1} lg={1} className="align-center" data-testid={`load-composition-order-quantity-${composicao.LoadCompositionId}`}>{composicao.OrdersQuantity}</Col>
              <Col xs={1} md={1} lg={1} className="align-center" data-testid={`load-composition-total-quantity-${composicao.LoadCompositionId}`}>
                {composicao.TotalAmount ? formatNumber(composicao.TotalAmount) : '-'}
              </Col>
              <Col xs={1} md={1} lg={1} style={{ width: '10%' }} title={composicao.Supplier} className="format-input-space" data-testid={`load-composition-supplier-${composicao.LoadCompositionId}`}  >{composicao.Supplier}</Col>
              <Col xs={1} md={1} lg={1} title={`${composicao.Center} - ${composicao.BusinessUnit}`} className="align-center" data-testid={`load-composition-business-unit-${composicao.LoadCompositionId}`}  >{`${composicao.Center} - ${composicao.BusinessUnit}`}</Col>
              <Col xs={2} md={2} lg={2} title={getObservationValue(composicao.BreweryNote, composicao.Observation)} className="format-input-space" data-testid={`load-composition-observation-brewere-${composicao.LoadCompositionId}`}  >{getObservationValue(composicao.BreweryNote, composicao.Observation)}</Col>
              <Col xs={1} md={1} lg={1} className="align-center" data-testid={`load-composition-status-${composicao.LoadCompositionId}`}  >{composicao.Situation}</Col>
            </Row>
          ))
        }
      </Col>
    </Row>
    <Row className="pagination">
      <Pagination
        page={page}
        pageSize={take}
        amount={totalComposicoes}
        refreshSearch={refreshSearch}
      />
    </Row>
  </StyledComposicaoList>
  )
}

ComposicaoLista.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    DeliveryDate: PropTypes.string.isRequired,
    OrdersQuantity: PropTypes.number.isRequired,
    Supplier: PropTypes.string.isRequired,
    Center: PropTypes.string.isRequired,
    OrdersSap: PropTypes.arrayOf(PropTypes.string),
    TotalAmount: PropTypes.number,
    CollectDate: PropTypes.string,
    BusinessUnit: PropTypes.string.isRequired,
    Observation: PropTypes.string.isRequired
  })).isRequired,
  handleEdit: PropTypes.func.isRequired,
  refreshSearch: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  totalComposicoes: PropTypes.number.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedItems: PropTypes.func.isRequired
}

export default ComposicaoLista