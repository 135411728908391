import { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import { Row, Col } from 'react-flexbox-grid'
import { SelectBox, Button } from "components/uikit-adapter"
import intl from 'react-intl-universal'

import StyledFilterFornecedor from './StyledFilterFornecedor'
import { formatErrorMessage } from 'utils/handle-error';

class FiltroFornecedor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            UserId: '',
            SupplierId: '',
            users: [],
            suppliers: [],
            showFilter: props.showFilter,
            hidden: true,
            isFetching: false
        }

        this.startFetching = this.startFetching.bind(this)
        this.stopFetching = this.stopFetching.bind(this)

        this.searchSuppliers = this.searchSuppliers.bind(this)
        this.searchUsers = this.searchUsers.bind(this)

        this.processSuppliersData = this.processSuppliersData.bind(this)
        this.processUsersData = this.processUsersData.bind(this)

        this.handleCloseSearch = this.handleCloseSearch.bind(this)
        this.handleCleanClick = this.handleCleanClick.bind(this)

        this.changeValue = this.changeValue.bind(this)
    }

    componentDidMount() {
      this.setState(
        {
          initialData: this.props.initialData,
          SupplierId: this.props.initialData.SupplierId,
          UserId: this.props.initialData.UserId,
        }, 
        () => {
        this.searchUsers();
        this.searchSuppliers();
        }
      );
    }

    stopFetching() {
        this.setState({
            isFetching: false
        })
    }

    startFetching() {
        this.setState({
            isFetching: true
        })
    }

    changeValue = (name, value) => {
        this.setState({
            [name]: value,
        })
    }

    proccessErro(exception) {
        this.setState({
            showMsg: true,
            titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
            textMsg: (exception.response && exception.response.status === 400)
                ? formatErrorMessage(exception)
                : intl.get('stocks.timeline.feedback.serverErrorOrder'),
            handleClose: () => {
                this.setState({ showMsg: false })
            }
        })
    }

    handleCleanClick = () => {
        const params = {
            SupplierId: null,
            UserId: null,
        }
        this.setState(params, () => this.props.handleClose(params))
    }

    searchUsers() {
        this.startFetching()

        Fetch.get(`/supplier-users:for-filters`)
            .then((response) => this.processUsersData(response.data))
            .finally(this.stopFetching)
            .catch((e) => this.proccessErro(e))
    }

    searchSuppliers() {
        this.startFetching()

        Fetch.get(`/suppliers:for-filters`)
            .then((response) => this.processSuppliersData(response.data))
            .finally(this.stopFetching)
            .catch((e) => this.proccessErro(e))
    }

    processSuppliersData(data) {
        this.setState({
            suppliers: data
        })
    }

    processUsersData(data) {
        this.setState({
            users: data
        })
    }

    handleCloseSearch() {
        const params = {
            SupplierId: this.state.SupplierId,
            UserId: this.state.UserId,
        }
        this.props.handleClose(params)
    }

    render() {
        const { suppliers, users } = this.state
        return (
            <StyledFilterFornecedor>
                <div className="filter-container">
                    <div className="sideFilterFields">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <SelectBox
                                    required
                                    name='user'
                                    label={intl.get('master-data.suppliers.ableSupplier.actions.supplierGroupName')}
                                    placeholder={intl.get('master-data.suppliers.ableSupplier.actions.supplierGroupName')}
                                    valueKey='Id'
                                    labelKey='Description'
                                    value={this.state.UserId}
                                    options={users}
                                    onChange={value => this.changeValue('UserId', value === null ? null : value.Id)}
                                    searchable
                                    openOnFocus
                                    clearable
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <SelectBox
                                    required
                                    name='supplier'
                                    label={intl.get('commons.supplier')}
                                    placeholder={intl.get('commons.supplier')}
                                    valueKey='Id'
                                    labelKey='Description'
                                    value={this.state.SupplierId}
                                    options={suppliers}
                                    onChange={value => this.changeValue('SupplierId', value === null ? null : value.Id)}
                                    searchable
                                    openOnFocus
                                    clearable
                                />
                            </Col>
                        </Row>
                    </div>

                    <Row className="footerFilterContainer no-margin">
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} lg={12} md={12}>
                                    <hr className="filter-divisor" />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} lg={6} md={6}>
                                    <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.search')} />
                                </Col>
                                <Col xs={6} lg={6} md={6}>
                                    <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </StyledFilterFornecedor>
        )
    }
}

FiltroFornecedor.propTypes = {
    showFilter: PropTypes.bool,
    initialData: PropTypes.shape({
        SupplierId: PropTypes.string,
        UserId: PropTypes.string
    }),
    transitionTime: PropTypes.number,
    handleClose: PropTypes.func
}

FiltroFornecedor.defaultProps = {
    showFilter: false,
    transitionTime: 500,
    initialData: {}
}

export default FiltroFornecedor
