import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import DangerDialog from '../danger-dialog';
import * as S from './styled'
import Session from 'utils/user-storage';
import { cancelarComposicao } from './btn-cancel.service';
import { getGeneralSettingValue } from '../../services/general-settings/general-settings';
import { formatErrorMessage } from '../../utils/handle-error';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting';
import PropTypes from 'prop-types'

const initialCancelmentLoadsProps = {
  url: '',
  buttonLabel: ''
}

const ButtonCancelLoadComposition = ({ idComposicao, handleCallback, handleFeedback, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [justificativas, setJustificativas] = useState([]);
  const [cancelLoadCompositionFlow, setCancelLoadCompositionFlow] = useState(initialCancelmentLoadsProps);

  const isLoadCompositionNewFlow = () => getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow);
  const isScheduleAgreementFlow = () => getGeneralSettingValue(GeneralSettingParameter.OrderSettings);

  const cancelCompositionFlow = () => {
    if (isLoadCompositionNewFlow() && isScheduleAgreementFlow()) {
      setCancelLoadCompositionFlow({
        url: `/load-compositions:cancel-by-schedule-agreement`,
        buttonLabel: intl.get("composition.actions.cancelCompositionNewFlow")
      })
    }
    else if (isLoadCompositionNewFlow() && !isScheduleAgreementFlow()) {
      setCancelLoadCompositionFlow({
        url: `load-compositions:cancel-by-purchase-order`,
        buttonLabel: intl.get("composition.actions.cancelCompositionPurchaseOrderFlow")
      })
    }
    else {
      setCancelLoadCompositionFlow({
        url: `/load-compositions/${idComposicao}:cancel`,
        buttonLabel: intl.get("composition.actions.cancelComposition")
      })
    }
  }

  const handleCancel = async (idJustificativa) => {
    setIsLoading(true);
    try {
      const user = Session.get()
      const _urlCancel = cancelLoadCompositionFlow.url;
      const cancelamento = { LoadCompositionId: idComposicao, JustificationId: idJustificativa, UserId: user.Id };

      await cancelarComposicao(_urlCancel, cancelamento);
      handleFeedback(intl.get("loadComposition.cancelModal.successfully"))
      handleCallback && handleCallback();
    }
    catch (e) {
      handleFeedback(formatErrorMessage(e));
    }
    setIsLoading(false);
  }

  const initData = async () => {
    cancelCompositionFlow()
    const data = JSON.parse(localStorage.getItem('enumerators'));
    if (data) {
      setJustificativas(data.OrderCancellationJustifications);
    }
  }

  useEffect(() => {
    initData()
  }, [])

  return (
    <React.Fragment>
      <S.ButtonCancel
        onClick={() => setOpenModal(true)}
        type="danger"
        className="button-delete"
        id="button"
        data-testid='button'
        value={cancelLoadCompositionFlow.buttonLabel}
        disabled={disabled}
      />
      <DangerDialog
        show={openModal}
        title={intl.get('transports.compoCharge.actions.cancelComposition')}
        labelButtonClose={intl.get('composition.actions.close')}
        handleConfirm={handleCancel}
        handleClose={() => setOpenModal(false)}
        justificationMessages={justificativas}
        isLoading={isLoading}
        labelButton={intl.get("commons.confirm")}
      />
    </React.Fragment>
  )
}

ButtonCancelLoadComposition.propTypes = {
  idComposicao: PropTypes.number,
  handleCallback: PropTypes.func,
  handleFeedback: PropTypes.func,
  disabled: PropTypes.bool
}

export default ButtonCancelLoadComposition;
