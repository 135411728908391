import { MassArrivalProvider } from "./context/massArrivalContext"
import { MassArrival } from "./massArrival"

function MassArrivalPage() {
  return (
    <MassArrivalProvider>
      <MassArrival />
    </MassArrivalProvider>
  )
}

export { MassArrivalPage }