import Fetch from "utils/fetch"
import { IFilterOptions, IMassArrivalFilter, MassArrivalResponse } from "./types"
import { generateQueryParams } from "utils/custom-functions"

export const getMassArrivals = (filter: IMassArrivalFilter): Promise<MassArrivalResponse[]> => {
  return Fetch.get(`international-mass-arrivals:grouped-by-week?${generateQueryParams(filter)}`).then(({ data }) => data)
}

export const getCompanyCodeOptions = (): Promise<IFilterOptions[]> => {
  return Fetch.get('international-mass-arrivals:company-code-options').then(({ data }) => data)
}

export const getPortsOptions = (): Promise<IFilterOptions[]> => {
  return Fetch.get('international-ports:for-options').then(({ data }) => data)
}
