import React from 'react'
import Suggestions from 'components/suggestions-list'
import intl from 'react-intl-universal'
import { Title, Description, IconTitle } from '../styled'
import { Container, Content } from './styled'
import IconPackage from 'components/icons/icn-package.icon'
import PropTypes from 'prop-types'

const Orders = ({
    data,
    calcTimelineMaxHeight,
    showCancelConfirm,
    showFormOrder,
    changeValueOrder,
    saveOrder,
    usuarioComPermissaoParaCancelarPedidos
}) => (
        <Container>
            <IconTitle>
                <IconPackage width='30px' />
                <Title>{intl.get('menu.menuGeneral.orders')}</Title>
            </IconTitle>
            {data.estoqueDetalhe.ListaPedidos.length ?
                data.estoqueDetalhe.ListaPedidos.map((x, indexOrigem) => (
                    <React.Fragment>
                        <Description>{intl.get('grid-vision.modal.source')}: {x.Origem}</Description>
                        <Content>
                            <Suggestions
                                isOrder
                                data={x.Pedidos}
                                itemHighlighted={data.tipo == 'P' ? data.idItemDestacado : ''}
                                itemExpanded={data.itemExpanded}
                                materialAtivo={data.estoqueDetalhe.MaterialAtivo}
                                itemsExpanded={data.itemsExpanded}
                                expandItem={calcTimelineMaxHeight}
                                showCancelConfirm={(item) => showCancelConfirm(item, indexOrigem)}
                                showFormOrder={showFormOrder}
                                changeValueOrder={(name, value, item) => changeValueOrder(name, value, item, indexOrigem)}
                                saveOrder={saveOrder}
                                usuarioComPermissaoParaCancelarPedidos={usuarioComPermissaoParaCancelarPedidos}
                            />
                        </Content>
                    </React.Fragment>
                )) :
                <div style={{ padding: '20px' }}>
                    {intl.get('grid-vision.feedbacks.noOrders')}
                </div>
            }
        </Container>
    )

Orders.propTypes = {
  data: PropTypes.shape({
    estoqueDetalhe: PropTypes.shape({
      ListaPedidos: PropTypes.array,
      MaterialAtivo: PropTypes.any
    }),
    tipo: PropTypes.string,
    idItemDestacado: PropTypes.string,
    itemExpanded: PropTypes.any, 
    itemsExpanded: PropTypes.array.isRequired
  }),
  calcTimelineMaxHeight: PropTypes.any,
  showCancelConfirm: PropTypes.func,
  showFormOrder: PropTypes.any,
  changeValueOrder: PropTypes.func,
  saveOrder: PropTypes.any,
  usuarioComPermissaoParaCancelarPedidos: PropTypes.bool
}

export default Orders;