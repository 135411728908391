import React, { Component } from 'react'
import ReactTags from 'react-tag-autocomplete'
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'
import CenterLoading from 'components/center-loading'
import { Row, Col } from "react-flexbox-grid"
import {
  Button,
  Input,
  SelectBox,
  Toggle,
  Dialog
} from 'components/uikit-adapter/index'
import { formatErrorMessage } from 'utils/handle-error'
import {
  searchBusinessUnitById,
  searchStatusTypes,
  searchTiposUnidade,
  saveUnit,
  getCountries
} from "./business-unit-form.service";
import { ObterLinhaProducaoPorCentro, SalvarLinhasDeProducao } from "../business-unit.service"
import { obtemInformacoesLinhaDeProducao, formataModelLinhasDeProducao, getDoorsReactTagFormat, getStorageLocationsReactTagFormat } from "./utils"
import * as S from "./styled"
import './business-unit-form.css'
import InputMultiEmail from 'components/Adapters/input-multi-email'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";
import { loadCompositionBrewerySideActive } from 'services/general-settings/get-general-settings'
import { usesTmsIntegration } from 'utils/validations-general-settings'

class BusinessUnitForm extends Component {
  constructor() {
    super()
    this.state = {
      unities: {
        RecebeEmail: true,
        PilotoNcmi: false,
        StorageLocations: [],
        LinhasDeProducao: [],
        Docas: [],
        Pais: null,
        TipoUnidadeEnumValue: 1
      },
      statusTypes: [],
      tiposUnidade: [],
      isSaving: false,
      supplierSAPMessage: intl.get('master-data.general-configuration.supplierSAP'),
      countries: []
    }
  }

  componentDidMount() {
    if (this.props.idEdit > 0) {
      this.searchBusinessUnitById()
    }
    this.searchStatusTypes()
    this.searchTiposUnidade()
    this.getAllCountries()
  }

  startSaving = () => {
    this.setState({
      isSaving: true
    })
  }

  stopSaving = () => {
    this.setState({
      isSaving: false
    })
  }

  searchBusinessUnitById = async () => {
    try {
      const dadosUnidadeNegocio = await searchBusinessUnitById(this.props.idEdit)
      const linhaProducao = await ObterLinhaProducaoPorCentro(dadosUnidadeNegocio.Centro)
      const LinhasDeProducao = obtemInformacoesLinhaDeProducao(linhaProducao)
      const Docas = getDoorsReactTagFormat(dadosUnidadeNegocio.Docas)
      const StorageLocations = getStorageLocationsReactTagFormat(dadosUnidadeNegocio.StorageLocations)

      this.proccessData({
        ...dadosUnidadeNegocio,
        LinhasDeProducao,
        Pais: dadosUnidadeNegocio.PaisEnumValue,
        Docas,
        StorageLocations
      })
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchStatusTypes = async () => {
    try {
      const data = await searchStatusTypes()
      this.proccessDataStatusTypes(data)
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  getAllCountries = async () => {
    try {
      const data = await getCountries();

      this.setState({
        countries: data
      })
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  proccessDataStatusTypes = (data) => {
    this.setState({
      statusTypes: data
    })
  }

  searchTiposUnidade = async () => {
    try {
      const data = await searchTiposUnidade()
      this.proccessDataTiposUnidade(data)
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  saveUnitForm = async (unit) => {
    try {
      this.startSaving();
      await saveUnit(unit);
      if (unit.LinhasDeProducao) {
        await SalvarLinhasDeProducao(formataModelLinhasDeProducao(unit), unit.Centro)
      }

      this.props.handleRefresh();
      this.props.handleFeedback(intl.get('feedbacks.savedRegister'), "positive");
    }
    catch (e) {
      this.proccessErro(e)
    }
    finally {
      this.stopSaving();
    }
  }

  proccessDataTiposUnidade = (data) => {
    this.setState({
      tiposUnidade: data
    });
  }

  proccessData = (data) => {
    const {
      Id,
      Empresa,
      Centro,
      CentroECC,
      CentroS4H,
      Nome,
      FornecedorSap,
      Status,
      StatusEnumValue,
      TipoUnidadeEnumValue,
      Email, 
      RecebeEmail,
      CodigoTms,
      PilotoNcmi,
      LinhasDeProducao,
      Pais,
      Docas,
      Geo,
      CNPJ,
      StorageLocations
    } = data

    this.setState({
      unities: {
        Id,
        Empresa,
        Centro,
        CentroECC,
        CentroS4H,
        Nome,
        FornecedorSap: FornecedorSap,
        Status,
        StatusEnumValue: StatusEnumValue ?? 1,
        TipoUnidadeEnumValue,
        Email,
        RecebeEmail,
        CodigoTms,
        PilotoNcmi,
        LinhasDeProducao,
        Pais,
        Docas,
        Geo,
        CNPJ,
        StorageLocations: StorageLocations ?? []
      }
    })
  }

  changeValueEmail = (value) => {
    this.setState(prevState => ({
      unities: {
        ...prevState.unities,
        Email: value
      }
    }))
  }

  proccessErro = (exception) => {
    try {
      this.props.handleFeedback(formatErrorMessage(exception));
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.savedRegister'), "positive");
    }
  }

  changeValue = (event) => {
    const prop = event.currentTarget.name
    const value = event.currentTarget.value

    this.setState(prevState => ({
      unities: {
        ...prevState.unities,
        [prop]: value
      }
    }))
  }

  updateSelectValue = (prop, value) => {
    this.setState(prevState => ({
      unities: {
        ...prevState.unities,
        [prop]: value
      }
    }))
  }

  formatAsStringListToSave = (data) => data.map(d => d.name);

  formatAsStorageLocationsListToSave = (data) => data?.map(s => ({ Id: s.id, StorageLocation: s.name }))

  save = () => {
    const model = this.state.unities
    const unit = {
      Id: model.Id ? model.Id : 0,
      Nome: model.Nome,
      Centro: model.Centro,
      CentroECC: model.CentroECC,
      CentroS4H: model.CentroS4H,
      Empresa: model.Empresa,
      FornecedorSap: model.FornecedorSap,
      Status: model.Status,
      StatusEnumValue: model.StatusEnumValue,
      TipoUnidadeEnumValue: model.TipoUnidadeEnumValue,
      RecebeEmail: model.RecebeEmail,
      Email: model.Email,
      CodigoTms: model.CodigoTms,
      PilotoNcmi: model.PilotoNcmi,
      LinhasDeProducao: model.LinhasDeProducao,
      PaisEnumValue: model.Pais,
      Docas: this.formatAsStringListToSave(model.Docas),
      Geo: model.Geo,
      CNPJ: model.CNPJ,
      StorageLocations: this.formatAsStorageLocationsListToSave(model.StorageLocations)
    };

    if (!unit.Empresa) { this.props.handleFeedback(`${intl.get('feedbacks.messageUserBusiness1')} ${intl.get('master-data.general-configuration.company')} ${intl.get('feedbacks.messageUserBusiness2')}`) }
    else if (!unit.Centro) { this.props.handleFeedback(`${intl.get('feedbacks.messageUserBusiness1')} ${intl.get('master-data.general-configuration.center')} ${intl.get('feedbacks.messageUserBusiness2')}`) }
    else if (!unit.StatusEnumValue) {
      this.props.handleFeedback(
        `${intl.get('feedbacks.messageUserBusiness1')}
         ${intl.get('commons.status')}
         ${intl.get('feedbacks.messageUserBusiness2')}`
      )
    }
    else if (!unit.TipoUnidadeEnumValue) {
      this.props.handleFeedback(
        `${intl.get('feedbacks.messageUserBusiness1')}
        ${intl.get('master-data.general-configuration.unityType')}
        ${intl.get('feedbacks.messageUserBusiness2')}
      `)
    }
    else if (!unit.PaisEnumValue) { this.props.handleFeedback(intl.get('feedbacks.selectACountry')) }
    else if (!unit.Nome) { this.props.handleFeedback(`${intl.get('feedbacks.messageUserBusiness1')} ${intl.get('master-data.general-configuration.name')} ${intl.get('feedbacks.messageUserBusiness2')}`) }
    else if (!unit.FornecedorSap) {
      this.props.handleFeedback(`${intl.get('feedbacks.messageUserBusiness1')} ${this.state.supplierSAPMessage} ${intl.get('feedbacks.messageUserBusiness2')}`)
    }
    else if (!unit.CodigoTms && usesTmsIntegration()) { this.props.handleFeedback(`${intl.get('feedbacks.messageUserBusiness1')} ${intl.get('master-data.general-configuration.codeTMS')} ${intl.get('feedbacks.messageUserBusiness2')}`) }
    else {
      this.saveUnitForm(unit);
    }
  }

  changeRecebeEmail = (checked) => {
    this.setState(prevState => ({
      unities: {
        ...prevState.unities,
        RecebeEmail: checked
      }
    }))
  }

  onDelete = (i) => {
    const _linhasDeProducao = this.state.unities.LinhasDeProducao;
    const isMasterData = profileAdminOrMasterData()
    if (isMasterData) {
      const tags = _linhasDeProducao.slice(0)
      tags.splice(i, 1)
      this.setState(prevState => ({
        unities: {
          ...prevState.unities,
          LinhasDeProducao: tags
        }
      }))
    }
    return ""
  }

  onAdd = (tag) => {
    const _productionLines = this.state.unities.LinhasDeProducao;
    const _productionLinesNames = _productionLines.map(x => x.name);

    if(!_productionLinesNames.includes(tag.name)){
      const tags = [].concat(_productionLines, tag);
      this.setState(prevState => ({
        unities: {
          ...prevState.unities,
          LinhasDeProducao: tags
        }
      }))
    }
  };

  onDeleteDoca = (i) => {
    const _docas = this.state.unities.Docas;
    const isMasterData = profileAdminOrMasterData()
    if (isMasterData) {
      const tags = _docas.slice(0)
      tags.splice(i, 1)
      this.setState(prevState => ({
        unities: {
          ...prevState.unities,
          Docas: tags
        }
      }))
    }
    return ""
  }

  onAddDoca = (tag) => {
    const _docas = this.state.unities.Docas;
    const newTag = { ...tag, name: tag.name.trim()}
    const names = this.state.unities.Docas.map((doca) => doca.name);
    if (!names.includes(newTag.name)) {
      const tags = [].concat(_docas, newTag)
      this.setState(prevState => ({
        unities: {
          ...prevState.unities,
          Docas: tags
        }
      }))
    }
  }

  onDeleteStorageLocation = (i) => {
    const _storageLocations = this.state.unities.StorageLocations;
    const isMasterData = profileAdminOrMasterData()
    if (isMasterData) {
      const tags = _storageLocations.slice(0)
      tags.splice(i, 1)
      this.setState(prevState => ({
        unities: {
          ...prevState.unities,
          StorageLocations: tags
        }
      }))
    }
    return ""
  }

  onAddStorageLocation = (tag) => {
    const _storageLocations = this.state.unities.StorageLocations;
    const _storageLocationsNames = _storageLocations.map(x => x.name);

    if(!_storageLocationsNames.includes(tag.name)){
      const tags = [].concat(_storageLocations, tag)
      this.setState(prevState => ({
        unities: {
          ...prevState.unities,
          StorageLocations: tags
        }
      }))
    }
  }

  render() {
    const { statusTypes, unities, tiposUnidade, isSaving } = this.state
    const { open, editMode } = this.props;
    return (
      <div>
        <CenterLoading isLoading={isSaving} />
        <Dialog
          title={editMode ? intl.get('master-data.managementUnity.unitys.actions.editManagementUnity') : intl.get('master-data.managementUnity.unitys.actions.newManagementUnity')}
          contentStyle={{ width: '600px' }}
          open={open}
          autoScrollBodyContent
        >
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Input
                  disabled={editMode}
                  name='Empresa'
                  label={intl.get('master-data.general-configuration.company')}
                  value={unities.Empresa}
                  onChange={this.changeValue}
                  className="input"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Input
                  disabled={editMode}
                  name='Centro'
                  label={intl.get('master-data.general-configuration.center')}
                  value={unities.Centro}
                  onChange={this.changeValue}
                  className="input"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <S.Tags>
                  <p className="tag-label">{intl.get("commons.storageLocation")}</p>
                  <ReactTags
                    tags={unities.StorageLocations}
                    onDelete={this.onDeleteStorageLocation.bind(this)}
                    onAddition={this.onAddStorageLocation.bind(this)}
                    allowNew={profileAdminOrMasterData()}
                    placeholderText={profileAdminOrMasterData() ? intl.get("commons.addStorageLocation") : ""}
                    delimiters={['Enter', 'Tab', ' ']}
                    inputAttributes={{ maxLength: 30 }}
                  />
                </S.Tags>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <S.Tags>
                  <p className="tag-label">{intl.get("commons.productionLine")}</p>
                  <ReactTags
                    tags={unities.LinhasDeProducao}
                    onDelete={this.onDelete.bind(this)}
                    onAddition={this.onAdd.bind(this)}
                    allowNew={profileAdminOrMasterData()}
                    placeholderText={profileAdminOrMasterData() ? intl.get("commons.addProductionLine") : ""}
                    delimiters={['Enter', 'Tab', ' ']}
                    inputAttributes={{ maxLength: 30 }}
                  />
                </S.Tags>
              </Col>
            </Row>
            {
              loadCompositionBrewerySideActive() &&
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <S.Tags>
                    <p className="tag-label">{intl.get("commons.doors")}</p>
                    <ReactTags
                      tags={unities.Docas}
                      onDelete={this.onDeleteDoca.bind(this)}
                      onAddition={this.onAddDoca.bind(this)}
                      allowNew={profileAdminOrMasterData()}
                      placeholderText={profileAdminOrMasterData() ? intl.get("commons.addDoor") : ""}
                      delimiters={['Enter', 'Tab', ' ']}
                      inputAttributes={{ maxLength: 30 }}
                    />
                  </S.Tags>
                </Col>
              </Row>
            }
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  required
                  label='Status'
                  placeholder='Status'
                  name='Status'
                  value={unities.StatusEnumValue}
                  valueKey='Key'
                  labelKey='Value'
                  onChange={value => this.updateSelectValue('StatusEnumValue', value.Key)}
                  options={statusTypes}
                  width="100%"
                  disabled={!profileAdminOrMasterData()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  required
                  label={intl.get('master-data.general-configuration.unityType')}
                  placeholder={intl.get('master-data.general-configuration.unityType')}
                  name='TipoUnidade'
                  value={unities.TipoUnidadeEnumValue}
                  valueKey='Id'
                  labelKey='Description'
                  onChange={value => this.updateSelectValue('TipoUnidadeEnumValue', value.Id)}
                  options={tiposUnidade}
                  width="100%"
                  disabled={!profileAdminOrMasterData()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  required
                  label={intl.get('commons.country')}
                  placeholder={intl.get('commons.country')}
                  name='Pais'
                  value={unities.Pais}
                  valueKey='Id'
                  labelKey='Descricao'
                  onChange={value => this.updateSelectValue('Pais', value.Id)}
                  options={this.state.countries}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Input
                  required
                  name='Nome'
                  onChange={this.changeValue}
                  value={unities.Nome}
                  label={intl.get('master-data.general-configuration.name')}
                  disabled={!profileAdminOrMasterData()}
                  className="input"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Input
                  name='FornecedorSap'
                  onChange={this.changeValue}
                  value={unities.FornecedorSap}
                  label={this.state.supplierSAPMessage}
                  disabled={!profileAdminOrMasterData()}
                  maxLength={20}
                  className="input"
                />
              </Col>
            </Row>
              <React.Fragment>
              {usesTmsIntegration() &&
                <Row>
                  <Col xs={12} lg={12} md={12}>
                    <Input
                      name='CodigoTms'
                      onChange={this.changeValue}
                      value={unities.CodigoTms}
                      label={intl.get('master-data.general-configuration.codeTMS')}
                      disabled={!profileAdminOrMasterData()}
                      maxLength={20}
                      className="input"
                    />
                  </Col>
                </Row>
              }
                <Row>
                  <Col xs={12} lg={12} md={12}>
                    <InputMultiEmail
                      required
                      labelText='Email'
                      emails={unities.Email}
                      onChange={this.changeValueEmail}
                      disabled={!profileAdminOrMasterData()}
                    />
                  </Col>
                </Row>
                <label className="label-style">{intl.get('master-data.menus.configuration')}</label>
                <div className="container">
                  <Row>
                    <Col xs={8} lg={8} md={8}>
                      <Toggle
                        name="RecebeEmail"
                        label={intl.get('master-data.managementUnity.unitys.actions.receiveAutomaticMail')}
                        value={unities.RecebeEmail}
                        onChange={value => this.changeRecebeEmail(value.checked)}
                        thumbSwitchedStyle={{ backgroundColor: 'var(--color-action-default)' }}
                        trackSwitchedStyle={{ backgroundColor: 'var(--color-neutral-200)' }}
                        className={'toggle-recebe-email'}
                        disabled={!profileAdminOrMasterData()}
                      />
                    </Col>
                  </Row>
                </div>
              </React.Fragment>
            <div className='footer-dialog-buttons__edit-source'>
              <div className='line-buttons'>
                <Button
                  type="default"
                  value={intl.get('geral.buttonsDefault.cancel')}
                  onClick={() => { this.props.handleClose() }}
                  className="buttons__edit-source"
                />
                {profileAdminOrMasterData() &&
                  <Button
                    type="primary"
                    value={intl.get('geral.buttonsDefault.save')}
                    className="buttons__edit-source btn__save"
                    onClick={this.save}
                  />
                }
              </div>
            </div>
        </Dialog>
      </div >
    )
  }
}

BusinessUnitForm.propTypes = {
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  idEdit: PropTypes.number,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired
}

export default BusinessUnitForm
