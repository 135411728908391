import PropTypes from 'prop-types';
import { Col, Row } from 'react-flexbox-grid';
import InputNumber from "components/input-number";
import InsumosInput from "components/insumos-input";
import { Input } from "components/uikit-adapter/index";
import { formatNumber } from "utils/format";
import intl from 'react-intl-universal';
import { isCurrentRefuelingPointPercentDisabled } from '../../validations';

const TIPO_ALGORITMO_POLITICA_SILOS = 3;
const INTL_GERAL = 'master-data.general-configuration';

const InventoryConfiguration = ({ labeledFamily, userCanEditStockPolicies, selectedTipoAlgoritmo, campoPercentual, changeValueDecimal, changeDecimalValue }) => (
  <>
    <label className="label-style"> {intl.get(`${INTL_GERAL}.currentInventory`)} </label>
    <div className="container__general-form">
      <Row>
        <Col xs={6} lg={6} md={6}>
          <InputNumber
            type="default"
            required
            label={intl.get(`${INTL_GERAL}.minimumStock`)}
            name="EstoqueMinimoAtual"
            value={labeledFamily.EstoqueMinimoAtual}
            onChange={(_value) => changeValueDecimal("EstoqueMinimoAtual", _value)}
            disabled={!userCanEditStockPolicies()}
            data-testid='input-estoque-minimo-atual'
          />
        </Col>
        <Col xs={6} lg={6} md={6}>
          <InputNumber
            type="default"
            required
            label={intl.get(`${INTL_GERAL}.maximumStock`)}
            name="EstoqueMaximoAtual"
            value={labeledFamily.EstoqueMaximoAtual}
            onChange={(_value) => changeValueDecimal("EstoqueMaximoAtual", _value)}
            disabled={
              selectedTipoAlgoritmo == TIPO_ALGORITMO_POLITICA_SILOS || !userCanEditStockPolicies()
            }
            data-testid='input-estoque-maximo-atual'
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <label className="label-style label-style-title">
            {intl.get(`${INTL_GERAL}.refuelingPoint`)}
          </label>
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <InsumosInput
            disabled={isCurrentRefuelingPointPercentDisabled(selectedTipoAlgoritmo)}
            required
            type="number"
            maxLength="3"
            value={campoPercentual}
            name="PontoReabastecimentoAtualPerc"
            onChange={(event) => changeDecimalValue(event)}
            data-testid='input-ponto-reabastecimento-atual-perc'
          />
        </Col>
        <Col xs={8}>
          <Input
            disabled
            label=""
            name="PontoReabastecimentoAtual"
            value={formatNumber(labeledFamily.PontoReabastecimentoAtual)}
            className="input"
          />
        </Col>
      </Row>
    </div>
  </>
);

InventoryConfiguration.propTypes = {
  labeledFamily: PropTypes.shape({
    EstoqueMinimoAtual: PropTypes.number,
    EstoqueMaximoAtual: PropTypes.number,
    PontoReabastecimentoAtual: PropTypes.number,
  }).isRequired,
  userCanEditStockPolicies: PropTypes.func.isRequired,
  selectedTipoAlgoritmo: PropTypes.string,
  campoPercentual: PropTypes.number,
  changeValueDecimal: PropTypes.func.isRequired,
  changeDecimalValue: PropTypes.func.isRequired
};

export default InventoryConfiguration;
