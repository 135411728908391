import PropTypes from "prop-types"
import intl from 'react-intl-universal'
import { useOrderVolumeContext } from '../../context'
import { accept } from '../../services/order-volume.service'
import { useToastContext } from 'hooks/useToastContext'
import { formatErrorMessage } from 'utils/handle-error'
import { runStockProjection } from 'services/labeled-family-center.service'
import ModalConfirmAccept from "./modal-confirm-accept"
import { useState } from "react"
import { Button } from "components/uikit-adapter"
import { CheckCircle } from 'components/icons/check-circle.icon'
import { getVolumesLabeledFamilyCenterIds } from "features/order-volume/utils"

const OrderVolumeAccept = ({ refresh, volumeOrdersIds, volumeOrders }) => {
  const [showAlert, setShowAlert] = useState(false);
  const { setCheckbox, setLoading } = useOrderVolumeContext();
  const { openToast } = useToastContext();

  const acceptOrder = async () => {
    setLoading(true)
    try {
      await accept(volumeOrdersIds);
      const labeledFamilyCenterIds = getVolumesLabeledFamilyCenterIds(volumeOrdersIds, volumeOrders);
      for(const labeledFamilyCenterId of labeledFamilyCenterIds){
        await runStockProjection(labeledFamilyCenterId);
      }
      setCheckbox([])
      refresh();
      openToast(intl.get('stocks.timeline.feedback.volumeSuccessfullyAccepted'));
    } catch (error) {
      openToast(formatErrorMessage(error), 'negative')
    }
    setLoading(false)
  }

  const handleAccept = () => {
    setShowAlert(false)
    acceptOrder()
  }

  return (
    <>
      <Button
        buttonIcon={<CheckCircle />}          
        buttonText={intl.get("volumeOrders.acceptVolumes")}
        className="btnAcept"
        width='166px'
        type="secondary"
        disabled={volumeOrdersIds.length === 0}
        onClick={() => setShowAlert(true)}
      />
      {showAlert &&
        <ModalConfirmAccept
          onClick={handleAccept}
          open={showAlert}
          close={() => setShowAlert(false)}
          volumeOrdersIds={volumeOrdersIds}
        />
      }
    </>
  )
}

OrderVolumeAccept.propTypes = {
  refresh: PropTypes.func.isRequired,
  volumeOrders: PropTypes.any.isRequired,
  volumeOrdersIds: PropTypes.shape({
    includes: PropTypes.func,
    length: PropTypes.number
  }).isRequired
}

export default OrderVolumeAccept
