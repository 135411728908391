import { SelectBox } from "components/uikit-adapter";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import intl from "react-intl-universal";
import { getProductionLineByCenter } from "services/business-unit.service";
import { IBusinessUnit } from "types/business-unit";
import { objectIsNotEmpty, validatesArrayHasValue } from "utils/custom-functions";
import { formatValuesToSelectbox } from "utils/format-data";

interface IProps {
  useStorageLocations: boolean;
  businessUnitData: IBusinessUnit;
  updateStorageLocations: (key: string, value: any) => void;
  storageLocationsProductionLines: { [key: string]: any }
}

interface ISelectboxOption {
  Id: number,
  Name: string
}

const StorageLocationAndLineConfiguration = ({ useStorageLocations, businessUnitData, updateStorageLocations, storageLocationsProductionLines }: IProps) => {
  const [productionLines, setProductionLines] = useState<Array<ISelectboxOption> | null>(null);
  const [optionsProductionLines, setOptionsProductionLines] = useState<Array<ISelectboxOption> | null>(null);
  const [columnSize, setColumnSize] = useState(4); 

  const calcColumnSize = (size: number) => {
    if(size == 1) setColumnSize(12);
    else if (size == 2) setColumnSize(6);
  }

  const fetchProductionLines = async (center: string) => {
    calcColumnSize(businessUnitData?.StorageLocations?.length);
    const _data = await getProductionLineByCenter(center);
    const _formatedData = formatValuesToSelectbox(_data);
    setProductionLines(_formatedData);
    mountOptions(_formatedData);
  }

  const mountOptions = (_productionLines = productionLines) => {
    let _selectedOptions: any[] = [];

    if(objectIsNotEmpty(storageLocationsProductionLines)){
      for(const key in storageLocationsProductionLines) {
        const _items = storageLocationsProductionLines[key]?.map((x: ISelectboxOption) => x.Name) ?? [];
        _selectedOptions = [..._selectedOptions, ..._items];
      }

      const _options = _productionLines?.filter(x => !_selectedOptions.includes(x.Name)) ?? null;

      setOptionsProductionLines(_options);
    } else {
      setOptionsProductionLines(_productionLines);
    }
  }

  useEffect(() => {
    if (validatesArrayHasValue(productionLines)) mountOptions();
  }, [storageLocationsProductionLines]);

  useEffect(() => {
    if(objectIsNotEmpty(businessUnitData) && businessUnitData?.StorageLocations?.length > 0) fetchProductionLines(businessUnitData?.Centro);
  }, [useStorageLocations, businessUnitData]);

  return (
    <>
      {objectIsNotEmpty(businessUnitData) && validatesArrayHasValue(businessUnitData?.StorageLocations) && (
        <div className="sl-selectbox-section">
          {/*@ts-ignore*/}
          <Row>
            {businessUnitData?.StorageLocations.map((x) => (
              //@ts-ignore
              <Col key={x.Id} xs={columnSize}>
                <SelectBox
                  required
                  name={x.Description}
                  className="periodicidade"
                  label={`${intl.get('master-data.general-configuration.storageLocationAbbreviation')} ${x.Description}`}
                  valueKey="Id"
                  labelKey="Name"
                  value={storageLocationsProductionLines?.[x.Id]}
                  options={optionsProductionLines}
                  onChange={(value: any) => updateStorageLocations(`${x.Id}`, value)}
                  openOnFocus
                  placeholder={undefined}
                  disabled={undefined}
                  //@ts-ignore
                  multi
                />
              </Col>
            ))}
          </Row>
        </div>
      )}

      {objectIsNotEmpty(businessUnitData) && !validatesArrayHasValue(businessUnitData?.StorageLocations) && (
        <div className="message-sl-configuration">
          {intl.get('master-data.general-configuration.storageLocationWarningMessage')}
        </div>
      )}

      {!objectIsNotEmpty(businessUnitData) && (
        <div className="message-sl-configuration">
          {intl.get('feedbacks.messageUserForm8')}
        </div>
      )}
    </>
  );
};

export default StorageLocationAndLineConfiguration;
