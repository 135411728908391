import PropTypes from "prop-types"
import { useState } from 'react'
import { Button, Dialog } from "components/uikit-adapter/index";
import intl from 'react-intl-universal';
import OrdersVolum from 'components/orders-pending-volumn';
import SuggestionsListDetail from 'components/manual-suggestion-order-form'
import { Container, ContainerActions, Header, Title, DateSpan } from './styled'
import moment from 'moment'
import {
  getBusinessUnits,
  getLabeledFamilies,
  saveOrderSuggestion
} from '../../grid-vision.service'
import SnackBar from 'material-ui/Snackbar'
import Session from "utils/user-storage"
import Loading from 'components/center-loading'
import { formatErrorMessage } from 'utils/handle-error';
import IconDeliveryTruckWithBackground from 'components/icons/icn-delivery-truck-with-background.icon';
import { getMaterialsByBusinessUnitAndLabeledFamily } from 'services/labeled-family-center-materials.service';
import { normalizeMaterials } from './utils';

const MANUAL_ORDER_DEFAULT = {
  IdUnidadeNegocio: 0,
  Unidades: [],
  IdFamiliaRotulada: 0,
  FamiliasRotuladas: [],
  IdMaterial: 0,
  Materiais: [],
  IdFornecedor: 0,
  TransitTime: 0,
  Coleta: '',
  DataEntregaDe: '',
  QuantidadeLote: 1,
  Quantidade: 1,
  Total: 1,
  OrganizacaoCompras: '',
  GrupoCompradores: '',
  CodigoImposto: '',
  Observacao: '',
  ResponsavelEntrega: 1,
  RequirementCode: ''
}

const Modal = (props) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [messageFeedBack, setMessageFeedBack] = useState("");
  const [showVolumeOrders, setShowVolumeOrders] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [callSaveMethod, setCallSaveMethod] = useState(false);
  const [manualOrders, setManualOrders] = useState(MANUAL_ORDER_DEFAULT);

  const handleClose = () => {
    const { getGrid } = props;

    const history = props.history;
    history.push('/gridVision')
    setShowVolumeOrders(true)

    changeforDefaultForm()
    getGrid()
  }

  const Usuario = Session.get();

  const includeOrder = () => {
    setShowVolumeOrders(false)
    loadBusinessUnits();
  }

  const loadBusinessUnits = async () => {
    const data = await getBusinessUnits();
    changeValueSuggestion('Unidades', data)
  }

  const loadLabeledFamilies = async (businessUnitId) => {
    const labeledFamilies = await getLabeledFamilies(businessUnitId);
    const _labeledFamilies = labeledFamilies?.map((item) => ({ Id: item.Id, Descricao: item.Description }));
    changeValueSuggestion('FamiliasRotuladas', _labeledFamilies)
  }

  const loadMaterials = async (idFamiliaRotulada) => {
    const filters = {
      businessUnitId: manualOrders.IdUnidadeNegocio,
      labeledFamilyId: idFamiliaRotulada
    };
    const data = await getMaterialsByBusinessUnitAndLabeledFamily(filters);
    const _materials = normalizeMaterials(data);
    changeValueSuggestion('Materiais', _materials)
  };

  const saveSuggestion = async (model) => {
    const pedido = {
      IdUnidadeNegocio: model.IdUnidadeNegocio,
      IdMaterial: model.IdMaterial,
      IdFornecedor: model.IdFornecedor,
      DataColeta: model.Coleta,
      DataEntrega: model.DataEntregaDe,
      Quantidade: model.Total,
      OrganizacaoCompras: model.OrganizacaoCompras,
      GrupoCompradores: model.GrupoCompradores,
      CodigoImposto: model.CodigoImposto,
      Observacao: model.Observacao,
      ResponsavelEntrega: model.ResponsavelEntrega,
      PrioridadeXadrez: model.PrioridadeXadrez,
      RequirementCode: model.RequirementCode
    }

    try {
      showFetching()
      await saveOrderSuggestion(pedido);
      openMessageFeedback(intl.get('grid-vision.feedbacks.save'))
    } catch (err) {
      openMessageFeedback(formatErrorMessage(err))
    } finally {
      handleClose()
      stopFetching()
      closeMessageFeedback()
    }
  }

  const changeforDefaultForm = () => {
    setManualOrders({
      ...MANUAL_ORDER_DEFAULT,
      Unidades: manualOrders.Unidades
    });
  }

  const changeValueSuggestion = (prop, value) => {
    setManualOrders(prevState => ({
      ...prevState,
      [prop]: value
    }));
    changeValueSuggestionSpecific(prop, value)
  }

  const changeValueDeadline = (coleta, entrega) => {
    setManualOrders(prevState => ({
      ...prevState,
      Coleta: coleta,
      DataEntregaDe: entrega
    }));
  }

  const changeValueSuggestionSpecific = (prop, value) => {
    switch (prop) {
      case 'IdUnidadeNegocio':
        loadLabeledFamilies(value)
        break

      case 'IdFamiliaRotulada':
        loadMaterials(value)
        break

      case 'IdFornecedor':
        changeInfoSupply(value)
        break

      case 'Quantidade':
        setManualOrders(prevState => ({
          ...prevState,
          Total: calcTotalOrder(value, manualOrders.QuantidadeLote)
        }));
        break
    }
  }

  const changeInfoSupply = (idFornecedor) => {
    const material = manualOrders.Materiais.find(m => m.Id === manualOrders.IdMaterial)
    const fornecedor = material.Fornecedores.find(f => f.Id === idFornecedor)
    setManualOrders({
      ...manualOrders,
      TransitTime: fornecedor.TransitTime,
      QuantidadeLote: fornecedor.Arredondamento,
      Total: calcTotalOrder(manualOrders.Quantidade, fornecedor.Arredondamento),
      PrioridadeXadrez: fornecedor.PrioridadeXadrez
    }
    );
  };

  const calcTotalOrder = (quantidadeLote, quantidade) => {
    return (quantidadeLote * quantidade)
  }

  const openMessageFeedback = (message) => {
    setMessageFeedBack(message);
    setOpenFeedback(true);
  };

  const closeMessageFeedback = () => {
    setOpenFeedback(false);
  };

  const showFetching = () => {
    setIsFetching(true);
  };

  const stopFetching = () => {
    setIsFetching(false);
  };

  return (
    <Dialog
      title={
        <Header>
          <IconDeliveryTruckWithBackground />
          <Title>{intl.get('grid-vision.modal.title')}</Title>
          <DateSpan>{moment(props.match.params.date).format("L")}</DateSpan>
        </Header>
      }
      open={props.match.params.familyId}
      autoScrollBodyContent
      actions={
        <ContainerActions data-testid='btn-orders-volum'>
          <Button
            value={intl.get("geral.buttonsDefault.close")}
            onClick={handleClose}
            type="secondary"
            className="buttons"
          />
          {showVolumeOrders ?
            ((Usuario.TipoUsuario === 1 || Usuario.PermiteCriarPedido) && <Button
              value={intl.get('grid-vision.modal.InclusionOfOrders')}
              onClick={includeOrder}
              type="primary"
              className="buttons"
            />)
            :
            <Button
              value={intl.get("geral.buttonsDefault.save")}
              onClick={() => setCallSaveMethod(true)}
              type="primary"
              className="buttons"
            />
          }
        </ContainerActions>
      }
    >
      <Container>
        <Loading
          isLoading={isFetching}
        />
        {showVolumeOrders ?
          <OrdersVolum {...props} />
          :
          <SuggestionsListDetail
            noFooter
            isOrder
            item={manualOrders}
            salvarSugestao={saveSuggestion}
            changeValueSugestao={changeValueSuggestion}
            changeValueDeadline={changeValueDeadline}
            handleFeedback={openMessageFeedback}
            callSaveMethod={callSaveMethod}
            saveMethodOk={() => setCallSaveMethod(false)}
          />
        }
        <SnackBar
          id="snackbar"
          open={openFeedback}
          message={messageFeedBack}
          autoHideDuration={3000}
          onRequestClose={closeMessageFeedback}
        />
      </Container>

    </Dialog>
  )
}

Modal.propTypes = {
  getGrid: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.any,
      familyId: PropTypes.any
    })
  }).isRequired
}

export default Modal;
