import { getNPSSystemId, getNPSSrc, getNPSHost, getShowNewNPS } from "utils/config-environment";
import TipoPerfilAcesso from "models/usuarios/tipo-perfil-acesso";
import Session from "utils/user-storage";
import { useEffect } from "react";

function useNewNpsScript(idToken) {

  const NPS_ID_TOKEN_KEY = 'NPS_ID_TOKEN';
  const NPS_USER_ROLES_KEY = 'NPS_USER_ROLES';
  const NPS_STORAGE = 'local';
  const VITE_NPS_SYSTEM_ID = getNPSSystemId();
  const VITE_NPS_SRC = getNPSSrc();
  const VITE_NPS_SRC_HOST = VITE_NPS_SRC + "/nps";
  
  useEffect(() => {
    if (!VITE_NPS_SYSTEM_ID || !idToken || !getShowNewNPS())
      return;
    
    //SET USER INFOS IN LOCALSTORAGE TO BE USED IN NPS MODAL
    localStorage.setItem(NPS_ID_TOKEN_KEY, idToken);
    let session = Session.get();
    if(session){
      let profile = Object.keys(TipoPerfilAcesso).find(key => TipoPerfilAcesso[key] == session.PerfilAcesso);
      localStorage.setItem(NPS_USER_ROLES_KEY, profile);
    }
    const script = document.createElement('script');
    
    script.setAttribute('src', VITE_NPS_SRC)
    script.setAttribute('host', VITE_NPS_SRC_HOST)
    script.setAttribute('system', VITE_NPS_SYSTEM_ID)
    script.setAttribute('auth', NPS_ID_TOKEN_KEY)
    script.setAttribute('storage', NPS_STORAGE)
    script.setAttribute('group', NPS_USER_ROLES_KEY)

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

  }, [idToken]);

  return null
}

export { useNewNpsScript }