import * as permissions from "services/permissions/getPermissions";
import intl from 'react-intl-universal'
import { Switcher } from "components/toggle";
import { DialogsUserSettingsOptions } from "features/navbar/utils";
import { isUseFilterDefault } from "utils/user";
import { handleChangeMyItemsOnly, logout } from "../components/navbar-user-actions/navbar-user-actions.service";

export const dataNavbarUserSettings = (openWiki, handleOpenDialog) => [
  {
    name: "emailNotification",
    label: intl.get("commons.EmailNotification"),    
    handleClick: () => handleOpenDialog(DialogsUserSettingsOptions.SCHEDULE_NOTIFICATION_EMAIL_SENDING),
    visible: permissions.userAmbev(),
  },
  {
    name: "wiki",
    label: intl.get("commons.wiki"),
    handleClick: openWiki,
    visible: true,
  },
  {
    name: "uploadBottles",
    label: intl.get("bottles.uploadTitle"),
    handleClick: () => handleOpenDialog(DialogsUserSettingsOptions.IMPORT_EXPORT_BOTTLES),
    visible: permissions.userFornecedor(),
  },
  {
    name: "versions",
    label: intl.get("master-data.menus.submenus.versions"),
    handleClick: () => handleOpenDialog(DialogsUserSettingsOptions.RELEASE),
    visible: permissions.userAmbev(),
  },
  {
    name: "configuration",
    label: intl.get("master-data.menus.configuration"),
    handleClick: () => handleOpenDialog(DialogsUserSettingsOptions.CONFIGURATION),
    visible: !permissions.userAmbev() && permissions.usaCarteira(),
  },
  {
    name: "myItems",
    label: <Switcher
      name="meusItens"
      label={intl.get("commons.myItems")}
      value={isUseFilterDefault()}
      onChange={handleChangeMyItemsOnly}
    />,
    handleClick: null,
    visible: permissions.userAmbev() && !permissions.perfilPcpUnidadeLogado(),
  },
  {
    name: "logout",
    label: intl.get("commons.logout"),
    visible: true,
    handleClick: logout
  }
]
