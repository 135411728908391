import { Button, SelectBox } from "components/uikit-adapter";
import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { getUserId, isUserSupplier } from "utils/user";
import * as S from "./styled";
import PropTypes from 'prop-types'
import { filtersDefaultSupplierProductionPlanUpload } from "./utils";

const FilterSupplierProductionPlanUpload = ({ onClick, optionsFilter }) => {
  const [dataFilters, setDataFilters] = useState(filtersDefaultSupplierProductionPlanUpload);

  const handleChange = (label, value) => {
    setDataFilters(prevState => ({
      ...prevState,
      [label]: value
    }))
  }

  useEffect(() => {
    if(isUserSupplier()) {
      const _userId = getUserId();
      const _supplierUser = optionsFilter.supplierGroups.filter(supplierGroup => supplierGroup.Id == _userId);       
      setDataFilters(prevState => ({
        ...prevState,
        SupplierGroupsIds: [ ..._supplierUser ]
      }));
    }
  }, [])

  return(
    <S.FiltersWrapper>
      <SelectBox
        name="SupplierGroupsIds"
        key="SupplierGroupsIds"
        label={intl.get("commons.supplierGroup")}
        placeholder={intl.get("commons.supplierGroup")}
        valueKey="Id"
        labelKey="Description"
        multi
        searchable
        clearable
        disabled={isUserSupplier()}
        value={dataFilters.SupplierGroupsIds}
        onChange={(value) => handleChange("SupplierGroupsIds", value)}
        options={optionsFilter.supplierGroups}
      />
      <SelectBox
        name="Suppliers"
        key="Suppliers"
        label={intl.get("bottles.suppliers")}
        placeholder={intl.get("bottles.suppliers")}
        valueKey="Id"
        labelKey="Description"
        multi
        searchable
        clearable
        value={dataFilters.Suppliers}
        onChange={(value) => handleChange("Suppliers", value)}
        options={optionsFilter.suppliers}
      />
      <SelectBox
        name="Materials"
        key="Materials"
        label={intl.get("commons.material")}
        placeholder={intl.get("commons.material")}
        valueKey="Id"
        labelKey="Description"
        multi
        searchable
        clearable
        value={dataFilters.Materials}
        onChange={(value) => handleChange("Materials", value)}
        options={optionsFilter.materials}
      />
      <Button
        type="secondary"
        className="button-download"
        onClick={() => onClick(dataFilters)}
        value={intl.get(`exportImportModal.butttons.download`)}
      />
    </S.FiltersWrapper>
  );
}

export default FilterSupplierProductionPlanUpload;

FilterSupplierProductionPlanUpload.propTypes = {
  onClick: PropTypes.func.isRequired,
  optionsFilter: PropTypes.shape({
    supplierGroups: PropTypes.array,
    suppliers: PropTypes.array,
    materials: PropTypes.array
  })
}