import intl from "react-intl-universal"
import Paper from 'material-ui/Paper'
import { Link } from 'react-router-dom'
import StatusCard from './../../../body-card/status-card'
import Divisor from './../../../body-card/card-divisor'
import Alert from './../../../body-card/card-alert'
import * as S from "../styled"
import './supplier.css'
import { formatNumber } from "utils/format";
import LinkLoadCompositionForm from 'components/link-load-composition-form'
import LinkCode from "components/link-code-span";
import { generateUrl } from './utils'
import { TextSpan } from 'components/text-label'
import PropTypes from 'prop-types'

const ShopSuggestionMesterSupplierCard = ({ dataCard: order }) => (
  <S.Wrapper>
    <Link to={generateUrl(order)}>
      <Paper zDepth={1} className='card-shop-mester-supplier' id={order.Id}>
        {order.Tipo.Alerta && <Alert />}
        <div className='card-click-area'>
          <div className='header-card'>
            <StatusCard
              tipo={order.Tipo}
              material={order.Material.Nome}
            />
          </div>

          <Divisor />

          <div className='row-card'>
            <div className='text-card'>
              <TextSpan>{intl.get("commons.code")}</TextSpan>
              <TextSpan data-testid={`order-id-${order.Id}`}>{`${order.Id}`}</TextSpan>
            </div>
            <div className='text-card'>
              <TextSpan>{intl.get("commons.cardFactory")}</TextSpan>
              <TextSpan>{`${order.Fabrica.Codigo} - ${order.Fabrica.Nome}`}</TextSpan>
            </div>
          </div>

          <Divisor />

          <div className='row-card'>
            {
              order.PedidoVolume &&
              <div className='text-card'>
                <TextSpan>{intl.get("commons.colDate")}</TextSpan>
                <TextSpan>{order.Negociacao.DataColetaPropostaFormatada}</TextSpan>
              </div>
            }
            <div className='text-card'>
              <TextSpan>{intl.get("commons.deliveryDate")}</TextSpan>
              <TextSpan>{order.Negociacao.DataEntregaPropostaFormatada}</TextSpan>
            </div>
            <div className='text-card'>
              <TextSpan>{intl.get("commons.amount")}</TextSpan>
              <TextSpan>{formatNumber(order.Negociacao.QuantidadeProposta)}</TextSpan>
            </div>
            {
              order.IdComposicao &&
              <Link to={'#'}>
                <div className='text-card' style={{ width: 'min-content' }}>
                  <TextSpan>{intl.get("commons.loadComp")}</TextSpan>
                  <LinkLoadCompositionForm
                    idComposicao={order.IdComposicao}
                    modoLeitura={order.IsReadModeLoadComposition}
                  >
                    <LinkCode>
                      {order.IdComposicao}
                    </LinkCode>
                  </LinkLoadCompositionForm>
                </div>
              </Link>
            }
          </div>
          <Divisor />
          <div className='row-card'>
            <S.StatusWrapper className="text-card">
              <TextSpan>{intl.get("commons.status")}</TextSpan>
              <TextSpan
                title={order.Status}
                className="status__column"
              >
                {order.Status}
              </TextSpan>
            </S.StatusWrapper>
            {
              !order.PedidoVolume && order.ObservacaoFornecedor &&
              <div className='text-card'>
                <TextSpan>{intl.get("commons.supplierNotes")}</TextSpan>
                <TextSpan className="card-label-format-notes">{order.ObservacaoFornecedor}</TextSpan>
              </div>
            }
          </div>
        </div>
      </Paper>
    </Link>
  </S.Wrapper >
)

ShopSuggestionMesterSupplierCard.propTypes = {
  dataCard: PropTypes.shape({
    Id: PropTypes.number,
    Tipo: PropTypes.shape({
      Alerta: PropTypes.bool
    }),
    Material: PropTypes.shape({
      Nome: PropTypes.string
    }),
    Fabrica: PropTypes.shape({
      Nome: PropTypes.string,
      Codigo: PropTypes.string
    }),
    PedidoVolume: PropTypes.object,
    Negociacao: PropTypes.shape({
      NumeroPedido: PropTypes.number,
      QuantidadeProposta: PropTypes.number,
      UnidadeMedidaProposta: PropTypes.string,
      DataColetaPropostaFormatada: PropTypes.string,
      DataEntregaPropostaFormatada: PropTypes.string
    }),
    IdComposicao: PropTypes.number,
    IsReadModeLoadComposition: PropTypes.bool,
    Status: PropTypes.number,
    ObservacaoFornecedor: PropTypes.string,
  }),
  
}

export default ShopSuggestionMesterSupplierCard
