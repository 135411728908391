import PropTypes from "prop-types"
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'components/uikit-adapter/index'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import TableRownColumnUnclickable from 'components/table-row-column-unclickable'
import DeleteIcon from 'images/icn-trash-bin.svg'
import IconButton from 'material-ui/IconButton'
import intl from 'react-intl-universal'

const UnidadeArredondamentoList = ({ data, countData, refreshSearch, handleEdit, handleDelete, qtRegsPerPage, profileAdminOrMasterData }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%', overflow: 'hidden' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: 'var(--color-contrast-white)' }}
    onCellClick={handleEdit}>

    <TableHeader displaySelectAll={false} adjustForCheckbox={false} style={{ borderBottom: 'none' }}>
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn style={{ width: '35%', paddingRight: '1px' }}>{intl.get('commons.description')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '50%', padding: '1px' }}>{intl.get('master-data.general-configuration.um')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '10%' }}>Status</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '5%' }}></TableHeaderColumn>
      </TableRow>
    </TableHeader>

    <TableBody displayRowCheckbox={false} showRowHover>
      {data.map((v, i) => (
        <TableRow key={i} style={{ cursor: 'pointer' }}>
          <TableRowColumn style={{ width: '35%', paddingRight: '1px' }}>
            {v.Descricao}
          </TableRowColumn>
          <TableRowColumn style={{ width: '50%', padding: '1px' }}>
            {v.UnidadeMedida}
          </TableRowColumn>
          <TableRowColumn style={{ width: '10%' }}>
            {v.Status}
          </TableRowColumn>
          <TableRownColumnUnclickable style={{ positon: 'relative', textAlign: 'right', width: '5%' }}>
            {profileAdminOrMasterData &&
              <div title={intl.get('master-data.configurations.roundedUnitys.actions.deleteRoundedUnity')}>
                <IconButton onClick={() => handleDelete(v.Id)} >
                  <img alt="delete-icon" src={DeleteIcon} />
                </IconButton>
              </div>
            }
          </TableRownColumnUnclickable>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <PaginationFooter
        countPerPage={qtRegsPerPage}
        countData={countData}
        refreshSearch={refreshSearch}
      />
    </TableFooter>
  </Table>
)

UnidadeArredondamentoList.propTypes = {
  countData: PropTypes.any.isRequired,
  data: PropTypes.shape({
    map: PropTypes.func
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.any.isRequired,
  profileAdminOrMasterData: PropTypes.any.isRequired,
  qtRegsPerPage: PropTypes.any.isRequired,
  refreshSearch: PropTypes.any.isRequired
}

export default UnidadeArredondamentoList
