import styled from "styled-components";

export const Navbar = styled.nav<{ overflow: boolean }>`
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  overflow: ${props => props.overflow ? 'hidden' : 'none' };
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #fcfcfc;
  border-bottom: solid 1px var(--color-neutral-200);
`;

export const Option = styled.li<{ selected: boolean }>`
  padding: 0px 12px;
  font-size: 14px;
  align-content: center;
  text-align: center;
  height: 42px;
  cursor: pointer;
  font-weight: ${props => props.selected ? '600' : '400' };
  border-bottom: ${props => props.selected ? '4px solid var(--color-brand-400)' : '4px solid transparent' };
  min-width: fit-content;
`;

export const ButtonsWrapper = styled.li`
  position: sticky; 
  right: 0px;
  display: flex;
  height: 46px;;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  background: #fff;
  gap: 2px;
  min-width: fit-content;
  border-radius: 6px 0px 0px 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 4px, rgba(0, 0, 0, 0.12) 0px 1px 2px;
`

export const NavigationButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 4px;
  
  :hover{
    background-color: var(--color-action-hover);
    cursor: pointer;
  }
`
