import styled from 'styled-components'

export const Container = styled.div`
  min-height: calc(100vh - 225px);
`
export const Actions = styled.div`
  display:flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  .div-filters {
    width: auto;
    margin-right: 20px;

    .btn-tipo-visualizacao {
      padding-right: 0;
    }
  }

  .btn-import-export-order {
    width: 45px;
  }

  .btnCancel {
    margin-right: 5px;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Totalizer = styled.div`
  margin-left: 20px;
  margin-top: 10px;
  font-size: 13px;
`
