import { IProductionLines } from "types/business-unit/production-lines";

export const formatValuesToSelectbox = (values: Array<IProductionLines> | null) => {
  if(!values) return [];

  const _data = values?.map((value) => ({
    Id: value.Id,
    Name: value.ProductionLine
  }));

  return _data;
}