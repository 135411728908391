export const formataLinhasDeProducao = (linhasDeProducao) => {
  return linhasDeProducao?.map(l => ({ name: l.ProductionLine, id: l.Id }))
}

export const formataLinhasDeProducaoParaSalvar = (linhasDeProducao) => {
  return linhasDeProducao.map(l => l.name)
}

export const obtemInformacoesLinhaDeProducao = (dadosLinhaProducao) => {
  return dadosLinhaProducao && dadosLinhaProducao.length > 0 ? formataLinhasDeProducao(dadosLinhaProducao) : [];
}

export const formataModelLinhasDeProducao = (unit) => {
  return unit.LinhasDeProducao.map(line => ({
    Id: line?.id,
    UnidadeNegocioId: unit.Id,
    Centro: unit.Centro,
    LinhaProducao: line.name
  }));
}

export const getDoorsReactTagFormat = (data) => {
  if (data && data.length > 0 ){
    return data.map((door) => ({ name: door }))
  }
  return []
}

export const getStorageLocationsReactTagFormat = (data) => {
  if (data && data.length > 0 ){
    return data.map((x) => ({ id: x.Id, name: x.StorageLocation }))
  }
  return []
}