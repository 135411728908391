import { MassArrivalResponseData } from "pages/mass-arrival/types"
import { Wrapper } from "./cardStyled"
import { MassArrivalStatus } from "pages/mass-arrival/types/status";
import intl from "react-intl-universal";

function MassArrivalCard({ ActualOrderQuantity, LimitOrderQuantity, CompanyCode, PortOfDischarge, Status }: Readonly<MassArrivalResponseData>) {
  let cardStyle;

  switch (Status) {
    case (MassArrivalStatus.WithoutProgramming):
      cardStyle = { color: 'var(--color-neutral-300)', text: intl.get('commons.withoutProgram') }
      break;
    case (MassArrivalStatus.Critical):
      cardStyle = { color: 'var(--color-feedback-danger-400)', text: intl.get('commons.critical') }
      break;
    case (MassArrivalStatus.NearCapacity):
      cardStyle = { color: 'var(--color-feedback-warning-300)', text: intl.get('commons.nearCapacity') }
      break;
    case (MassArrivalStatus.Ok):
    default:
      cardStyle = { color: 'var(--color-feedback-positive-400)', text: intl.get('commons.ok') }
      break;
  }

  return (
    <Wrapper color={cardStyle.color}>
      <header>{cardStyle.text}</header>
      <div>{`${ActualOrderQuantity}/${LimitOrderQuantity}`}</div>
      <footer>
        <span style={{ fontWeight: 600 }}>{PortOfDischarge}</span>
        <span>{CompanyCode}</span>
      </footer>
    </Wrapper>
  )
}

export { MassArrivalCard }