import PropTypes from "prop-types"
import intl from "react-intl-universal";
import { Wrapper, Title, WrapperInputNumber, WrapperAvailableDeliverySlots } from "./load-settings-styled";
import { ClbSelect } from "@celebration/design-system-react";
import { Switcher } from 'components/toggle';
import { useLoadBuildingContext } from "features/load-building/context";
import { useEffect, useState } from "react";
import { getDoorsOptionsClb, isDoorsDisabled } from "utils/doors";
import InputNumber from "components/input-number";

export const LoadBuildingFlowLoadSettings = ({ loadSettingsData, setLoadSettingsData }) => {
  const { filter, businessUnitsRegisters, openFlowBuild } = useLoadBuildingContext();
  const [doors, setDoors] = useState([]);

  const loadDoors = () => {
    const _businessUnit = businessUnitsRegisters.filter(x => x.IdUnidadeNegocio == filter.BusinessUnitId)[0];
    const _doorOptions = getDoorsOptionsClb(_businessUnit?.Docas);
    
    if(!loadSettingsData.Door) handleUpdateData('Door', _doorOptions[0].value);    
    setDoors(_doorOptions);
  }

  const handleUpdateData = (field, value) => {
    setLoadSettingsData(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  useEffect(() => {
    if(openFlowBuild) loadDoors();
  }, [openFlowBuild])

  return (
    <Wrapper>
      <Title> {intl.get("loadBuildTransportInput.title")} </Title>
      <WrapperInputNumber>
        <p>{intl.get("loadBuildTransportInput.truckSizeLabel")}</p>
        <InputNumber
          name="truckSize"
          value={loadSettingsData.TruckSize}
          type='default'
          allowDecimals={false}
          onChange={_value => handleUpdateData("TruckSize", _value)}
          data-testid='load-settings-input-truck-size'
          required
        />
        <span>{intl.get("loadBuildTransportInput.truckSizeHelperText")}</span>
      </WrapperInputNumber>
      <ClbSelect
        label={intl.get('commons.door')}
        helperText={intl.get("loadBuildTransportInput.doorHelperText")}
        showHelperText
        value={loadSettingsData.Door}
        options={doors}
        onClbChange={(e) => handleUpdateData("Door", e.detail.value)}
        data-testid='load-settings-select-door'
        disabled={isDoorsDisabled(doors, loadSettingsData.Door)}
      />
      <WrapperAvailableDeliverySlots title={intl.get("loadBuildTransportInput.availableDeliverySlotsHelperText")}>
        <Switcher
          label={intl.get("loadBuildTransportInput.availableDeliverySlotsLabel")}
          name="available-delivery-slots"
          value={loadSettingsData.AvailableDeliverySlots}
          onChange={(value) => handleUpdateData('AvailableDeliverySlots', value.checked)}
          data-testid="toggle-available-delivery-slots"
          />     
      </WrapperAvailableDeliverySlots>
    </Wrapper>
  )
};

LoadBuildingFlowLoadSettings.propTypes = {
  loadSettingsData: PropTypes.shape({
    AvailableDeliverySlots: PropTypes.any,
    Door: PropTypes.any,
    TruckSize: PropTypes.any
  }).isRequired,
  setLoadSettingsData: PropTypes.func.isRequired
}
