import styled from "styled-components";

export const WrapperContent = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  padding: 12px;
  height: 240px;
`
export const ModalTitle = styled.p`
  font-weight: var(--font-weight-semibold);
  font-size: 18px;
  line-height: 20px;
  color: var(--color-action-default);
  margin: 8px 0px 12px 0px;
`
export const ModalText = styled.p`
  font-size: 16px;
  color: var(--color-contrast-brand);
  margin: 12px 0px;
  padding: 4px 0px 4px 0px;
`
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 24px;
`