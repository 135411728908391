import { createContext, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { initialFilterValues } from "./utils";
import { IFilterOptions, IMassArrivalFilter, MassArrivalContextType, MassArrivalProviderProps, MassArrivalResponse } from "../types";
import { getCompanyCodeOptions, getMassArrivals, getPortsOptions } from "../mass-arrival.service";

const MassArrivalContext = createContext<MassArrivalContextType | undefined>(undefined);

const MassArrivalProvider = ({ children }: MassArrivalProviderProps) => {
  const [filter, setFilter] = useState<IMassArrivalFilter>(initialFilterValues);
  const [massArrivals, setMassArrivals] = useState<Array<MassArrivalResponse> | null>(null);
  const [portsOptions, setPortsOptions] = useState<IFilterOptions[]>([]);
  const [companyCodeOptions, setCompanyCodeOptions] = useState<IFilterOptions[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchMassArrivals = useCallback(async () => {
    setIsLoading(true);
    try {
      const _data = await getMassArrivals(filter);
      setMassArrivals(_data);
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }, [filter])

  const clearMassArrivalsFilter = async () => {
    setFilter(initialFilterValues);
    setMassArrivals(null);
  }

  const onChangeFilter = <K extends keyof IMassArrivalFilter>(field: K, value: IMassArrivalFilter[K]) => {
    setFilter(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const loadOptions = async () => {
    try {
      const [portsOptions, companyCodeOptions] = await Promise.all([
        getPortsOptions(),
        getCompanyCodeOptions(),
      ]);
      setPortsOptions(portsOptions);
      setCompanyCodeOptions(companyCodeOptions);
    }
    catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    loadOptions();
  }, []);

  const contextValue = useMemo(() => ({
    filter,
    onChangeFilter,
    searchMassArrivals,
    massArrivals,
    clearMassArrivalsFilter,
    companyCodeOptions,
    portsOptions,
    isLoading
  }), [companyCodeOptions, filter, isLoading, massArrivals, portsOptions, searchMassArrivals])

  return (
    <MassArrivalContext.Provider
      value={contextValue}
    >
      {children}
    </MassArrivalContext.Provider>
  );
};

export const useMassArrivalContext = () => {
  const _context = useContext(MassArrivalContext);
  if (_context === undefined) {
    throw new Error("useMassArrivalContext can only be used in a MassArrivalProvider tree");
  }
  return _context;
}

export { MassArrivalProvider, MassArrivalContext };