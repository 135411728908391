import React, { Component } from 'react'
import Paper from 'material-ui/Paper'
import { Link } from 'react-router-dom'
import StatusCard from './../../../body-card/status-card'
import Divisor from './../../../body-card/card-divisor'
import StatusStock from 'components/status-stock'
import Alert from './../../../body-card/card-alert'
import intl from 'react-intl-universal'
import LinkLoadCompositionForm from 'components/link-load-composition-form'
import LinkCode from "components/link-code-span";
import { generateUrlAmbevCard } from './utils'
import { formatNumber } from 'utils/format'
import * as S from '../styled'
import { TextSpan } from 'components/text-label'
import PropTypes from 'prop-types'

class ShopSuggestionMesterAmbevCard extends Component {
  render() {
    let task = this.props.dataCard;

    return (
      <S.Wrapper>
        <Link to={generateUrlAmbevCard(task)}>
          <Paper zDepth={1} className='card-shop-mester' id={task.Id} style={{ height: `250px` }}>
            {task.Tipo.Alerta && <Alert />}
            <div className='card-click-area'>
              <div className='header-card'>
                <StatusCard
                  tipo={task.Tipo}
                  material={task.Material.Nome}
                />
              </div>
              <Divisor />
              <div className='row-card'>
                {!task.Sugestao &&
                  <div className='text-card'>
                    <TextSpan>{intl.get('commons.code')}</TextSpan>
                    <TextSpan>{task.Id}</TextSpan>
                  </div>
                }

                <div className='text-card'>
                  <TextSpan>{intl.get('commons.cardFactory')}</TextSpan>
                  <TextSpan style={{ maxWidth: '100px' }}>
                    {`${task.Fabrica.Codigo} - ${task.Fabrica.Nome}`}
                  </TextSpan>
                </div>

                <div className='text-card'>
                  <TextSpan>{intl.get('requestOrders.accStock')}</TextSpan>
                  <StatusStock
                    priority={task.Material.Estoque.Prioridade}
                    label={!!task.Material.Estoque.QuantidadeAtual ?
                      `${formatNumber(task.Material.Estoque.QuantidadeAtual)}${" "}${task.Material.Estoque.UnidadeMedida}`
                      : '-'}
                  />
                </div>
              </div>
              <Divisor />
              <div className='row-card'>
                <div className='text-card text-card-status'>
                  <TextSpan>{intl.get('commons.status')}</TextSpan>
                  <TextSpan>{task.Status}</TextSpan>
                </div>
                {
                  (task.Tipo.Etapa === 0 && task.PedidoVolume) &&
                  <div className='text-card'>
                    <TextSpan>{intl.get('commons.colDate')}</TextSpan>
                    <TextSpan>{task.Negociacao.DataColetaPropostaFormatada}</TextSpan>
                  </div>
                }
                {
                  task.Tipo.Etapa === 1 &&
                  <div className='text-card'>
                    <TextSpan>{intl.get('requestOrders.dateOut')}</TextSpan>
                    <StatusStock
                      priority={task.Material.Estoque.Prioridade}
                      label={task.Material.Estoque.Suficiencia}
                    />
                  </div>
                }
                {
                  task.IdComposicao &&
                  <Link to={'#'}>
                    <div className='text-card'>
                      <TextSpan>{intl.get("commons.loadComp")}</TextSpan>
                      <LinkLoadCompositionForm
                        idComposicao={task.IdComposicao}
                        modoLeitura={task.IsReadModeLoadComposition}
                      >
                        <LinkCode>
                          {task.IdComposicao}
                        </LinkCode>
                      </LinkLoadCompositionForm>
                    </div>
                  </Link>
                }
              </div>
              <div>
                <Divisor />
                <div className='row-card'>
                  <div className='text-card'>
                    <TextSpan>{intl.get('commons.cardSource')}</TextSpan>
                    <TextSpan>{task.Fornecedor}</TextSpan>
                  </div>
                  {
                    task.Tipo.Etapa === 0 &&
                    <div className='text-card'>
                      <TextSpan>{intl.get('commons.deliveryDate')}</TextSpan>
                      <TextSpan>{task.Negociacao?.DataEntregaPropostaFormatada}</TextSpan>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Paper>
        </Link>
      </S.Wrapper>
    )
  }
}

ShopSuggestionMesterAmbevCard.propTypes = {
  dataCard: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Tipo: PropTypes.shape({
      Alerta: PropTypes.bool,
      Etapa: PropTypes.number.isRequired
    }),
    Material: PropTypes.shape({
      Nome: PropTypes.string.isRequired,
      Estoque: PropTypes.shape({
        Prioridade: PropTypes.string,
        QuantidadeAtual: PropTypes.number,
        UnidadeMedida: PropTypes.string,
        Suficiencia: PropTypes.string
      })
    }),
    Sugestao: PropTypes.bool,
    Fabrica: PropTypes.shape({
      Nome: PropTypes.string.isRequired,
      Codigo: PropTypes.string.isRequired
    }),
    Status: PropTypes.string.isRequired,
    PedidoVolume: PropTypes.bool,
    Negociacao: PropTypes.shape({
      DataColetaPropostaFormatada: PropTypes.string,
      DataEntregaPropostaFormatada: PropTypes.string
    }),
    IdComposicao: PropTypes.number,
    IsReadModeLoadComposition: PropTypes.bool,
    Fornecedor: PropTypes.string.isRequired
  })
};

export default ShopSuggestionMesterAmbevCard
