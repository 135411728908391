import { Button, Dialog } from "components/uikit-adapter";
import React from "react";
import intl from "react-intl-universal";

interface ModalConfirmCancelProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ModalConfirmCancel: React.FC<ModalConfirmCancelProps> = ({
  visible,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      autoScrollBodyContent
      title={intl.get('volumeOrders.confirmVolumesCancellation')}
      contentStyle={{ width: "600px" }}
      open={visible}
      onRequestClose={onCancel}
    >
      <p>{intl.get('volumeOrders.confirmVolumesCancellationMesage')}</p>
      <div className="footer-dialog-buttons">
        <Button
          id="button-close"
          type="default"
          value={intl.get("geral.buttonsDefault.cancel")}
          onClick={onCancel}
          className="button cancel" 
          buttonIcon={undefined} 
          buttonText={undefined}       
        />
        <Button
          id="button-renegociar-composicao-carga"
          type="primary"
          value={intl.get("geral.buttonsDefault.confirm")}
          onClick={onConfirm}
          className="button send"
          buttonIcon={undefined} 
          buttonText={undefined}
        />
      </div>
    </Dialog>
  );
};

export default ModalConfirmCancel;
