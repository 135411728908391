import { useMassArrivalContext } from "pages/mass-arrival/context/massArrivalContext"
import { MassArrivalCard } from "./Card";
import { ContainerCard, ContainerTable, ContainerWeek, TitleContainer, Wrapper } from "./styled";
import intl from "react-intl-universal";
import Loading from "components/center-loading";

function MassArrivalTable() {
  const { massArrivals, isLoading } = useMassArrivalContext();

  if (isLoading) {
    return <Loading
      isLoading
      fullHeightParent
    />
  }

  if (massArrivals == null) {
    return <div className='no-records-message'>{intl.get("supplierStock.message")}</div>
  }

  if (massArrivals?.length == 0) {
    return <div className='no-records-message'>{intl.get('commons.noRecordsFound')}</div>
  }

  return (
    <Wrapper>
      <ContainerTable>
        {
          massArrivals.map(x => (
            <ContainerWeek key={x.Week}>
              <TitleContainer>
                {intl.get('commons.week')} {x.Week}
              </TitleContainer>
              <ContainerCard>
                {x.Data?.map(x => (
                  <MassArrivalCard {...x} />
                ))}
              </ContainerCard>
            </ContainerWeek>
          ))
        }
      </ContainerTable>
    </Wrapper>
  )
}

export { MassArrivalTable }